import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Link, Container } from '@material-ui/core';
import { sm_access, sm_employees, sm_timeClock, sm_tip } from '../img';

const borderDebug = false
  ? {
      yellow: { border: '2px solid yellow' },
      blue: { border: '2px solid blue' },
      pink: { border: '2px solid pink' },
      red: { border: '2px solid red' },
      green: { border: '2px solid green' },
      brown: { border: '4px solid brown' },
      grey: { border: '2px solid grey' },
    }
  : {};

function BoxItem({ item }) {
  function firstSentenceBold(text) {
    const [firstSentence, ...rest] = text.split('.');
    return (
      <span>
        <span className="text-bold"> {firstSentence}.</span>
        {rest.join('.')}
      </span>
    );
  }
  return (
    <Box
      className="flex flex-col"
      pr={{ sm: '1.5rem', md: '3.5rem', lg: '8rem' }}
      maxWidth={{ xs: '300px', sm: '100%' }}
      style={{ border: borderDebug.blue }}>
      <Box
        height={{ xs: '2.5rem', sm: '3rem' }}
        className="flex flex-row justify-start"
        mb={{ xs: '20px', sm: '15px' }}
        width="3.5rem">
        <img className="h-full" src={item.icon} alt="vp_cloud" style={borderDebug.grey} />
      </Box>

      <Typography variant="body2" align="left" className="m-b-2">
        {firstSentenceBold(item.text)}
      </Typography>
    </Box>
  );
}

function Title() {
  const { t: translate } = useTranslation();

  return (
    <Box id="Staff Management">
      <Typography variant="h3" align="center" className="m-b-8 m-t-8 fw-300">
        {translate('SERVICES.MORE_SERVICES')}
      </Typography>
      <Box maxWidth="800px">
        <Typography variant="h4" align="left" className="m-b-2 fw-300">
          {translate('SERVICES.STAFF_MANAGEMENT')}
        </Typography>
        <Typography variant="body1" align="left" className="m-b-2">
          {translate('STAFF_MANAGEMENT.KEEP_TRACK')}
        </Typography>
      </Box>
    </Box>
  );
}

function OtherServicesTab() {
  const { t: translate } = useTranslation();

  const featuresList = [
    {
      icon: sm_access,
      text: translate('STAFF_MANAGEMENT.MANAGED_ACCESS'),
    },
    {
      icon: sm_timeClock,
      text: translate('STAFF_MANAGEMENT.TIME_CLOCK_REPORT'),
    },
    {
      icon: sm_employees,
      text: translate('STAFF_MANAGEMENT.EMPLOYEES_ACCOUNTS'),
    },
    {
      icon: sm_tip,
      text: translate('STAFF_MANAGEMENT.TRACK_EPLOYEES_TIP'),
    },
  ];

  function changeTab(tabId, serviceId) {
    document.getElementById(tabId).click();
    setTimeout(() => {
      const serv = document.getElementById(serviceId);
      window.scrollTo(0, serv.offsetParent.offsetTop + serv.offsetTop - 80);
    }, 50);
  }

  return (
    <div>
      <Grid container direction="row" alignContent="flex-start">
        <Grid item xs={12} className="p-t-5 p-b-5" style={borderDebug.blue}>
          <Title />
        </Grid>

        <Container maxWidth="md">
          <Grid container direction="row" alignContent="flex-start" justify="space-between" className="p-b-16">
            {featuresList.map((feature, i) => {
              return (
                <Grid item xs={12} sm={5} className="p-t-5 p-b-5" style={borderDebug.green} key={i}>
                  <BoxItem item={feature} />
                </Grid>
              );
            })}
          </Grid>
        </Container>
        <Grid item xs={12} className="p-t-5 p-b-5" style={borderDebug.green}>
          <Typography variant="h4" align="center" className="m-b-2 fw-300">
            {translate('SERVICES.YOU_MAY_ALSO')}
          </Typography>
        </Grid>

        <Grid item xs={12} className="p-t-5 p-b-5 flex flex-col" style={borderDebug.green}>
          <Typography variant="body1" align="left" className="m-b-2 fw-500">
            <Link onClick={() => changeTab('Retail', 'Cashier')}>{translate('RETAIL.CASHIER')}</Link>
          </Typography>
          <Typography variant="body1" align="left" className="m-b-2 fw-500">
            <Link onClick={() => changeTab('Valet Parking', 'Valet Parking')}>
              {translate('SERVICES.VALET_PARKING')}
            </Link>
          </Typography>
          <Typography variant="body1" align="left" className="m-b-2 fw-500">
            <Link onClick={() => changeTab('Restaurant', 'Tips')}>{translate('RETAIL.TIPS')}</Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default OtherServicesTab;
