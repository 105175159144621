import { conatinerFluid } from "../../../../../../common/styles/default.styles";
import cloudsstars from './img/cloudsstars.svg'
export default theme => ({
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...conatinerFluid,
    "@media (min-width: 768px)": {
      maxWidth: "720px"
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px"
    }

  },
  diagonalBorder: {
    transform: "skewY(-3deg)",
    boxShadow: '0px 0px 15px #ccc',
    background: `url(${cloudsstars}) no-repeat #4C5CC3`,
    backgroundPosition: '25rem 3rem, 0',
    backgroundSize: 'contain',
    padding: '3rem',
    color: 'white',
    [theme.breakpoints.only("xs")]: {
      background: "#4C5CC3",
      transform: "skewY(-15deg)",
    },
    [theme.breakpoints.only("sm")]: {
      backgroundPosition: '-15rem 3rem, 0',
    }


  },
  diagonalBorderContainer: {
    transform: "skewY(3deg)",
    [theme.breakpoints.only("xs")]: {
      transform: "skewY(15deg)",
    }
  },


  maskedElement: {
    position: 'absolute',
    top: '0rem',
    right: '0',
    width: '40rem',
    marginTop: '-10rem',
    maskImage: ' linear-gradient(to top, transparent 20%, #4C5CC3 70%)',
    // '-webkit-mask-image': 'linear-gradient(to top, transparent 20%, #4C5CC3 70%)',
    // 'maxWidth': 'inherit',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      marginTop: '-3rem',
      maskImage: ' linear-gradient(to top, transparent 0%, #4C5CC3 0%)',
      '-webkit-mask-image': 'linear-gradient(to top, transparent 60%, #4C5CC3 70%)',
    },
    [theme.breakpoints.up("lg")]: {
      right: '-8rem',
      width: '43rem',
      marginTop: '-13rem',
    },
    "@media (max-width:991px) and (min-width:960px)": {
      right: "-6rem"
    },
  }
})