import React from "react";
import { useLocation } from "react-router-dom";

export default function LocationWatcher() {
  let location = useLocation();
  React.useEffect(() => {
    const { hostname } = window.location;
    if (hostname === "localhost" && location.pathname.startsWith("/app/")) {
      window.location = "http://localhost/" + location.hash;
    } 
  }, [location]);

  return <div></div>;
}
 