import * as Actions from "./forgotPass.actions";

const initialState = {
  checkVCodeSuccess: false,
  updatePassSuccess: false,
  shownForm: "selectMethod",
  userId: null
};

function forgotPass(state = initialState, action) {
  switch (action.type) {

    case Actions.CHECK_VCODE_SUCCESS: {
      return {
        ...state,
        checkVCodeSuccess: true
      };
    }

    case Actions.UPDATE_PASS_SUCCESS: {
      return {
        ...state,
        updatePassSuccess: true
      };
    }

    case Actions.SET_SHOWN_FORM: {
      return {
        ...state,
        ...action.payload
      };
    }

    case Actions.FORGOTPASS_RESTORE: {
      console.log("FORGOTPASS_RESTORE");
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export default forgotPass;
