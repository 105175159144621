import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { TextField, Box, MenuItem, Select, InputAdornment } from '@material-ui/core';
import amex from '../PaymentGateway/img/amex.svg';
import mastercard from '../PaymentGateway/img/mastercard.svg';
import visa from '../PaymentGateway/img/visa.svg';
import jcb from '../PaymentGateway/img/jcb.png';
import discover from '../PaymentGateway/img/discover.svg';
import dinersclub from '../PaymentGateway/img/dinersclub.svg';
import generic from '../PaymentGateway/img/generic.png';

const cardBrands = {
  visa,
  mastercard,
  jcb,
  amex,
  discover,
  dinersclub,
  generic,
};

const styles = {
  cardBrand: { height: '35px', width: 'auto', marginLeft: '5px' },
};

function Formatter(props) {
  const { inputRef, ...other } = props;
  return <NumberFormat {...other} getInputRef={inputRef} />;
}

Formatter.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function CardBrand({ value }) {
  let card = 'generic';
  const number = value.replace(/_| /g, '');
  const mastercardRegex = /5[1-5][0-9]{4,}$/;
  const visaRegex = /^4[0-9]{6,}$/;
  const amexRegex = /^3[47][0-9]{5,}$/;
  const dinersRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/;
  const discoverRegex = /^6(?:011|5[0-9]{2})[0-9]{3,}$/;
  const jcbRegex = /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/;

  if (visaRegex.test(number)) card = 'visa';
  if (mastercardRegex.test(number)) card = 'mastercard';
  if (amexRegex.test(number)) card = 'amex';
  if (dinersRegex.test(number)) card = 'dinersclub';
  if (discoverRegex.test(number)) card = 'discover';
  if (jcbRegex.test(number)) card = 'jcb';

  return <img src={cardBrands[card]} alt={card} style={styles.cardBrand} />;
}

function TextFormatInput({ className, isPhone, isCard, formatterProps, ...other }) {
  const {
    input,
    meta: { touched, error, active },
  } = other;

  const [age, setAge] = React.useState('');

  function handleCardChange({ value }) {}

  if (isCard) {
    formatterProps.onValueChange = handleCardChange;
  }

  function HelpAdornment() {
    const handleChange = (event) => {
      setAge(event.target.value);
      formatterProps.format = event.target.value === 10 ? '+58 (###) ###-####' : '+1 (###) ###-####';
    };

    return (
      <div>
        {/* TODO: para agregar logica para banderas en input de telefono */}
        {isPhone && (
          <InputAdornment position="end">
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={age} onChange={handleChange}>
              <MenuItem value={10}>58</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </InputAdornment>
        )}

        {isCard && <InputAdornment position="end">{<CardBrand value={input.value} />}</InputAdornment>}
      </div>
    );
  }

  const helperText = (
    <Box className="text-xs fw-300" height="14px" minWidth="10px">
      {touched && !active && error}
    </Box>
  );

  return (
    <Box className={className + ' flex flex-row items-center'}>
      <TextField
        {...other}
        {...input}
        className="flex-1"
        InputProps={{
          inputComponent: Formatter,
          inputProps: formatterProps,
          endAdornment: <HelpAdornment />,
        }}
        helperText={helperText}
        FormHelperTextProps={{ component: 'div', error: true }}
      />
    </Box>
  );
}

export default TextFormatInput;
