import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import * as toasterActions from "./toaster.actions";
import store from "../../../modules/store";

const useStyles = makeStyles({ icon: { fontSize: "36px" } });

function Toaster() {
  const toaster = useSelector(({ toaster }) => toaster);
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    store.dispatch({ type: toasterActions.CLOSE_TOASTER });
    toaster.onClose && toaster.onClose();
  };

  return (
    <div>
      <Snackbar
        open={toaster.open}
        anchorOrigin={toaster.position}
        autoHideDuration={toaster.timeout || 4000}
        onClose={handleClose}
      >
        <Alert
          classes={{ icon: classes.icon }}
          className="flex flex-row items-center"
          elevation={6}
          variant="outlined"
          style={{ backgroundColor: "white" }}
          onClose={handleClose}
          severity={toaster.type}
        >
          {toaster.title && <AlertTitle>{toaster.title}</AlertTitle>}
          {toaster.text}
        </Alert>
      </Snackbar>
    </div>
  );
}

function openToaster(options) {
  store.dispatch({
    type: toasterActions.OPEN_TOASTER,
    payload: {
      ...options
    }
  });
}

export { Toaster };
export { openToaster };
