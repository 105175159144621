import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Container, Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Contactless from './img/contactless.svg';
import EMV from './img/EMVChip.svg';
import Magstripe from './img/Magstripe.svg';
import NFCWallet from './img/NFCWallet.svg';
import ValetParking from './img/ValetParking.svg';
import MiniPOS from './img/MiniPOS.svg';
import WirelessPinpad from './img/WirelessPinpad.svg';
import styles from '../../Payments.style';

function InStorePayments() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const paymentsType = [
    { label: 'CONTACLESS', image: Contactless },
    { label: 'EMV', image: EMV },
    { label: 'MAGSTRIPE', image: Magstripe },
    { label: 'NFC_WALLET', image: NFCWallet },
  ];
  const servicesType = [
    { label: 'PARKING', span: 'VALET', spanColor: 'red', image: ValetParking },
    { label: 'POS', span: 'MINI', spanColor: 'blue', image: MiniPOS },
    { label: 'PINPAD', span: 'WIRELESS', spanColor: 'green', image: WirelessPinpad },
  ];
  return (
    <Box component="div" padding={{ xs: '3rem 0', md: '5rem 0' }} style={{ background: '#f9f9f9' }}>
      <Container maxWidth="lg">
        <Grid container direction="column" justify="center" alignItems="stretch" className={classes.textGray}>
          <Grid item>
            <Box padding={{ sm: '2rem' }}>
              <Typography variant="h3" gutterBottom align="center" className="fw-400">
                {translate('PAYMENT_SECTION2_TITLE1')}
              </Typography>
              <Typography variant="subtitle2" align="center">
                {translate('PAYMENT_SECTION2_SUBTITLE1')}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box marginTop={{ xs: '1rem', sm: '5rem' }}>
              <Grid container direction="column" justify="center" alignItems="stretch">
                <Grid item>
                  <Typography variant="h4" gutterBottom align="center" className="fw-400">
                    {translate('PAYMENT_SECTION2_TITLE2')}
                  </Typography>
                  <Typography variant="body1" align="center">
                    {translate('PAYMENT_SECTION2_SUBTITLE2')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justify="space-evenly" alignItems="stretch">
                    {paymentsType.map((item, index) => (
                      <Grid item key={index} xs={6} sm>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          className="h-full"
                          marginTop={{ xs: '3rem' }}>
                          <Box width={{ xs: '4rem' }} height={{ xs: '4rem' }}>
                            <img src={item.image} alt={translate(item.label)} className="h-full w-full" />
                          </Box>
                          <Typography variant="subtitle1" align="center">
                            {translate(item.label)}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box marginTop={{ xs: '3rem', sm: '5rem' }}>
              <Grid container direction="column" justify="center" alignItems="stretch">
                <Grid item>
                  <Typography variant="h4" gutterBottom align="center" className="fw-400">
                    {translate('PAYMENT_SECTION2_TITLE3')}
                  </Typography>
                  <Typography variant="body1" align="center">
                    {translate('PAYMENT_SECTION2_SUBTITLE3')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justify="space-evenly" alignItems="stretch">
                    {servicesType.map((item, index) => (
                      <Grid item key={index} xs={6} sm>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          className="h-full"
                          marginTop={{ xs: '3rem' }}>
                          <Box width={{ xs: '4rem' }} height={{ xs: '4rem' }}>
                            <img src={item.image} alt={translate(item.label)} className="h-full w-full" />
                          </Box>
                          <Typography variant="subtitle1" align="center">
                            <label>
                              <span style={{ color: item.spanColor }}>{translate(item.span)}</span>
                              {translate(item.label)}
                            </label>
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box marginTop="4rem" paddingTop="5rem">
        <Typography variant="body1" align="center" className="m-b-2">
          <Link to="/services" className="no-underline fw-500 inline-flex">
            {translate('PAYMENT_SECTION2_ABOUT_SERVICES')}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

export default InStorePayments;
