/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Grow,
  Icon,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { paymentByGateway } from '../../../common/components/PaymentGateway/PaymentGateway';
import useQuery from '../../../common/hooks/useQuery';
import { conatinerFluid, buttons } from '../../../common/styles/default.styles';
import { Parallax } from '../../Landing/components';
import serverPromisesFactory from '../../../common/factories/serverPromises.factory';
import { shallowEqual, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const styles = (theme) => {
  return {
    container: {
      zIndex: '12',
      color: '#FFFFFF',
      ...conatinerFluid,
    },
    card: {
      borderRadius: '13px',
      background: 'white',
      boxShadow: '0px 0px 22px -1px rgba(92,92,92,1)',
      '@media (min-width: 400px) and (max-width: 599px)': {
        width: '365px',
      },
      padding: '60px 20px 0px',
      marginTop: '25px',
    },
    avatar: {
      width: '4.5rem',
      height: '4.5rem',
      marginRight: '1rem',
      background: '#36c5f0',
    },
    grayBox: {
      color: 'black',
      borderRadius: '2%',
      padding: '0rem 1rem 3rem',
      [theme.breakpoints.up('md')]: {
        padding: '1rem 5rem 3rem',
      },
    },
    blueLabel: {
      color: '#5B5B5F',
    },
    icon: {
      fontSize: '65px',
      //color: '#c2c2c2',
    },
    ...buttons,
    closeButton: {
      borderRadius: '24px',
      marginRight: 15,
    },
  };
};

const useStyles = makeStyles(styles);

export default function PaymentButton() {
  const classes = useStyles();
  const urlParams = useQuery();
  const [merchant, setMerchant] = useState();
  const [paymentData, setPaymentData] = useState();
  const [code, setCode] = useState(null);
  const [retry, setRetry] = useState(false);
  const { paymentGatewayInfo } = useSelector(
    ({ paymentGatewayInfo }) => ({
      paymentGatewayInfo,
    }),
    shallowEqual
  );

  const { amount, id } = JSON.parse(atob(urlParams.get('id')));
  const Family = atob(id);

  useEffect(() => {
    serverPromisesFactory.get({ collection: 'Entities', query: { Family } }).then(([_merchant]) => {
      setMerchant(_merchant);
    });
    setPaymentData({
      family: Family,
      totalAmount: Number(amount),
      customFields: {},
      fees: {},
      subTotal: Number(amount),
      notificationInfo: {},
      UserId: '',
      associatedService: { Name: 'payment-button' },
      viewType: 'window',
    });
  }, [Family]);

  useEffect(() => {
    const { CardconnectInfo, form } = paymentGatewayInfo;
    if (CardconnectInfo) {
      const _code = CardconnectInfo.respstat === 'A' ? 200 : 400;
      setCode(_code);
      emitResponse(CardconnectInfo, _code);
      setPaymentData({ ...paymentData, initialValues: form });
    } else if (form) {
      setPaymentData({ ...paymentData, initialValues: form });
    }
  }, [paymentGatewayInfo]);

  useEffect(() => {
    if (retry) {
      setCode(null);
      setRetry(false);
    }
  }, [retry]);

  const emitResponse = (CardconnectInfo, _code) => {
    const data = {
      response: { ...CardconnectInfo, code: _code },
      id: urlParams.get('id'),
      key: 'response',
    };

    console.log(data);
    if (window.opener) {
      window.opener.postMessage(data, '*');
    }

    if (window.parent) {
      window.parent.postMessage(data, '*');
    }
  };

  const closeWindow = () => {
    const data = {
      id: urlParams.get('id'),
      key: 'close',
    };

    if (window.opener) {
      window.close();
    }

    if (window.parent) {
      window.parent.postMessage(data, '*');
    }
  };

  const PaymentByGateway = () => paymentByGateway(paymentData);

  return (
    <Parallax filter height="100vh" roundedBorderTopPosition={'45%'}>
      <Grow in timeout={700}>
        <Box className={classes.container}>
          {merchant && (
            <Box display="flex" className="items-center">
              <Avatar className={classes.avatar} src={merchant.Logo}></Avatar>
              <Box display="flex" flexDirection="column">
                <Typography variant="body1" className="text-gray-700">
                  {merchant.Name}
                </Typography>
                <Typography variant="body2" className="text-gray-500">
                  {merchant.Address}
                </Typography>
                <Typography variant="body2" className="text-gray-500">
                  {merchant.Email && <span>{merchant.Email} | </span>}
                  {merchant.Phone}
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            className={classes.card + ' flex flex-col justify-center items-center'}
            width={{ sm: '450px', md: '600px' }}
            minHeight="500px"
          >
            {!merchant && <CircularProgress />}
            {merchant && !code && paymentData && <PaymentByGateway />}
            {code && (
              <Box className="flex-col">
                <Grid container direction="column" alignItems="center" className={classes.grayBox} spacing={2}>
                  <Grid item>
                    {code === 200 && (
                      <Icon className={'far fa-check-circle ' + classes.icon} style={{ color: green['A400'] }} />
                    )}

                    {code === 400 && (
                      <Icon className={'far fa-times-circle ' + classes.icon} style={{ color: red['500'] }} />
                    )}
                  </Grid>

                  <Grid item>
                    <Typography className={`${classes.blueLabel} fw-400 fs-24`}>
                      Payment {code === 200 ? 'approved' : 'declined'}
                    </Typography>
                    {code === 200 && (
                      <Typography className={`${classes.blueLabel} fw-400 text-center fs-18`}>
                        Approval ({paymentGatewayInfo?.CardconnectInfo?.authcode})
                      </Typography>
                    )}
                  </Grid>

                  <Grid item>
                    <Typography
                      className={`${classes.blueLabel} fw-400 fs-55`}
                      style={{ color: code === 200 ? green['A400'] : red['500'] }}
                    >
                      <NumberFormat
                        value={amount}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        prefix={'US$ '}
                        decimalScale={2}
                      />
                    </Typography>
                  </Grid>

                  <Grid item className="w-full">
                    <Divider />
                    <Box p={1}>
                      <Typography className={`${classes.blueLabel} fw-400 fs-18`}>
                        Cardholder:{' '}
                        <span className="fw-500"> {paymentGatewayInfo?.CardconnectInfo?.cardholderName}</span>
                      </Typography>

                      <Typography className={`${classes.blueLabel} fw-400 fs-18`}>
                        Card number: <span className="fw-500"> {paymentGatewayInfo?.CardconnectInfo?.cardNumber}</span>
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item className="m-t-30 flex-row w-full justify-center">
                    {code !== 200 && (
                      <>
                        <Button variant="outlined" onClick={closeWindow} className={classes.closeButton + ' fw-300'}>
                          <Box minWidth="100px">Close window</Box>
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setRetry(true)}
                          className={classes.greenGradient + ' fw-300 text-white'}
                        >
                          <Box minWidth="100px">Try again</Box>
                        </Button>
                      </>
                    )}

                    {code === 200 && (
                      <>
                        <Button
                          variant="contained"
                          onClick={closeWindow}
                          className={classes.greenGradient + ' fw-300 text-white'}
                        >
                          <Box minWidth="100px">Close window</Box>
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      </Grow>
    </Parallax>
  );
}
