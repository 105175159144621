import React from 'react';
import { Box, Grid, Button, Link, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import { TextField, textFieldValidations } from '../../../common/components/ReduxForm/TextField';
import { buttons } from '../../../common/styles/default.styles';

const useStyles = makeStyles(buttons);

const validate = (values) => {
  const fields = {
    vcode: {
      value: values.vcode,
      required: true,
    },
  };
  return textFieldValidations({ fields });
};

function VCodeEntryForm({ handleSubmit, valid, handleResendCode }) {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const userInfo = useSelector(({ userInfo }) => userInfo);

  return (
    <form onSubmit={handleSubmit}>
      <Box p={['10px', '30px']} border="solid #e4e4e4 1px" borderRadius="6px" style={{ background: '#f5fcff' }}>
        <Grid container direction="column">
          <Box className="fw-500 text-xl">{translate('LOGIN.WE_JUST_SENT')}</Box>
          <Box className="fw-400 text-sm">
            {translate('LOGIN.ENTER_VCODE') + (userInfo.Email || 'userInfo@gmail.com')}
          </Box>
          <Field name="vcode" component={TextField} label="Your code" className="m-t-2 m-b-2" />
          <Box className="flex flex-row justify-between items-center m-t-5">
            <Link className="text-sm fw-400 cursor-pointer text-left" onClick={() => handleResendCode()}>
              {translate('LOGIN.RESEND_CODE')}
            </Link>
            <Button variant="contained" disabled={!valid} type="submit" className={classes.primary + ' fw-400'}>
              <Box minWidth="100px">{translate('LOGIN.CONFIRM')}</Box>
            </Button>
          </Box>
        </Grid>
      </Box>
    </form>
  );
}

export default reduxForm({ form: 'vcodeForm', validate })(VCodeEntryForm);
