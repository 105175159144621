export default {
	"WE_ARE_HERE_FOR_YOU": "We're here for you",
	"CONTACT_SECTION_SUBTITLE": "Get in touch with our specialists and learn how WaoFlex can help your business.",
	"EMAIL_US": "Email us",
	"EMAIL_US_SUBTITLE": "Feel free to email us at any time.",
	"EMAIL_ADDRESS": "sales@waoflex.com ",
	"GIVE_US_A_CALL": "Give us a call",
	"GIVE_US_A_CALL_SUBTITLE": "Give us a call - we’d love to hear from you.",
	"PHONE_NUMBER": "+1 (833) 731-2345",
	"GET_IN_TOUCH_WITH_FLEXIPOS": "Get in touch with the WaoFlex Sales team >"

}