import { createMuiTheme  } from '@material-ui/core/styles';



const theme = createMuiTheme ({
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'p',
        subtitle2: 'p',
        body1: 'p',
        body2: 'span',
      },
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
  },
  palette: {

    // ...theme.palette,
    primary: { main: '#3CD52E' },
  }

});

theme.typography.h1 = {
  fontSize: '3.5rem',
  fontWeight: 'bold',
  [theme.breakpoints.only('sm')]: {
    fontSize: '2.4rem',
  },
  [theme.breakpoints.only('xs')]: {
    fontSize: '1.75rem',
  },
};

theme.typography.h2 = {
  fontSize: '3rem',
  fontWeight: 'bold',
  [theme.breakpoints.only('sm')]: {
    fontSize: '2.1rem',
  },
  [theme.breakpoints.only('xs')]: {
    fontSize: '1.5rem',
  },
};

theme.typography.h3 = {
  fontSize: '2.5rem',
  fontWeight: 'bold',
  [theme.breakpoints.only('sm')]: {
    fontSize: '1.7rem',
  },
  [theme.breakpoints.only('xs')]: {
    fontSize: '1.6rem',
  },
};

theme.typography.h4 = {
  fontSize: '2rem',
  fontWeight: 'bold',
  [theme.breakpoints.only('sm')]: {
    fontSize: '1.6rem',
  },
  [theme.breakpoints.only('xs')]: {
    fontSize: '1.3rem',
  },
};

theme.typography.h5 = {
  fontSize: '1.5rem',
  fontWeight: 'bold',
  [theme.breakpoints.only('sm')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.only('xs')]: {
    fontSize: '0.75rem',
  },
};

theme.typography.h6 = {
  fontSize: '1rem',
  fontWeight: 'bold',
  [theme.breakpoints.only('sm')]: {
    fontSize: '.7rem',
  },
  [theme.breakpoints.only('xs')]: {
    fontSize: '.5rem',
  },
};

theme.typography.body1 = {
  fontSize: '1.2rem',
  [theme.breakpoints.only('sm')]: {
    fontSize: '1.1rem',
  },
  [theme.breakpoints.only('xs')]: {
    fontSize: '1rem',
  },
};

theme.typography.body2 = {
  fontSize: '1rem',
  fontWeight: 'normal'
};


theme.typography.subtitle1 = {
  fontSize: '1.5rem',
  [theme.breakpoints.only('sm')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.only('xs')]: {
    fontSize: '1rem',
  },
};

theme.typography.subtitle2 = {
  fontSize: '1.6rem',
  [theme.breakpoints.only('sm')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.only('xs')]: {
    fontSize: '1rem',
  },
};

// theme.palette = {
//   ...theme.palette,
//   primary: { main: '#3CD52E' },
// }

// theme.typography.h2 = {
//   fontSize: '1.7rem',
//   fontWeight: 700,
//   textAlign: 'inherit',
//   [theme.breakpoints.down('md')]: {
//     fontSize: '1.1rem',
//   },
// };
// theme.typography.h3 = {};
// theme.typography.h = {};
// theme.typography.h1 = {};
// theme.typography.h1 = {};
// theme.typography.body1 = {
//   fontSize: '1rem',
//   lineHeight: '1.7',
//   fontWeight: 300,
//   color: 'gray',
//   [theme.breakpoints.down('md')]: {
//     fontSize: '0.8rem',

//   },

// };

export default theme;