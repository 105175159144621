import React from 'react';
import { Route } from 'react-router-dom';
import LandingRoutes from './Landing/index';
import LoginRoutes from './Login/index';
import CloudInvoicesRoutes from './CloudInvoices/index';
import PaymentButtonRoutes from './PaymentButton/index';

const routes = [
  ...LandingRoutes,
  ...LoginRoutes,
  ...CloudInvoicesRoutes,
  ...PaymentButtonRoutes
  //{ component: () => <Redirect to="/" /> } //default
];

function Routes() {
  return routes.map((route, i) => <Route exact path={route.path} component={route.component} key={i} />);
}

export default Routes;
