import HTTP from '../../services/HTTP.service';

let paymentGatewayFactory = (function () {
  function makePayment(family, body) {
    return HTTP.post('/web/v2/onlinepayment/' + family + '/2', body).catch((err) => Promise.reject(err));
  }

  return {
    makePayment,
  };
})();

export default paymentGatewayFactory;
