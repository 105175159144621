import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Container, Box, Typography, Hidden } from '@material-ui/core';
import API from './img/API.svg';

function PaymentAPI() {
  const { t: translate } = useTranslation();
  return (
    <div>
      <Box
        component="div"
        padding={{ xs: '3rem 0', sm: '3rem 1rem', md: '8rem', lg: '5rem 7rem' }}
        style={{ background: '#f9f9f9', color: '#505050' }}>
        <Container maxWidth="lg">
          <Grid container direction="column" justify="center" alignItems="stretch">
            <Grid item>
              <Box padding={{ sm: '2rem' }}>
                <Typography variant="h3" gutterBottom align="center" className="fw-300">
                  {translate('PAYMENT_SECTION6_TITLE')}
                </Typography>
                <Typography variant="body1" align="center">
                  {translate('PAYMENT_SECTION6_SUBTITLE_LINE1')}
                </Typography>
                <Typography variant="body1" align="center">
                  {translate('PAYMENT_SECTION6_SUBTITLE_LINE2')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Grid container direction="row" justify="space-between" alignItems="center" className="p-t-10">
                <Hidden mdUp>
                  <Grid item xs={12} className="flex justify-center m-b-3">
                    <Box width={{ xs: '18rem' }}>
                      <img src={API} alt={translate(`PAYMENT_SECTION6_ITEM_TITLE`)} className="w-full" />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h4" align="justify" gutterBottom className="fw-300">
                      {translate(`PAYMENT_SECTION6_ITEM_TITLE`)}
                    </Typography>
                    <Typography variant="body1" align="justify" gutterBottom>
                      {translate(`PAYMENT_SECTION6_ITEM_PARAGRAPH1`)}
                    </Typography>
                    <Typography variant="body1" align="justify" gutterBottom>
                      {translate(`PAYMENT_SECTION6_ITEM_PARAGRAPH2`)}
                    </Typography>
                  </Grid>
                </Hidden>
                <Hidden smDown>
                  <Grid item md={6} lg={4}>
                    <Typography variant="h4" align="left" gutterBottom className="fw-300">
                      {translate(`PAYMENT_SECTION6_ITEM_TITLE`)}
                    </Typography>
                    <Typography variant="body1" align="left" className="m-t-3">
                      {translate(`PAYMENT_SECTION6_ITEM_PARAGRAPH1`)}
                    </Typography>
                    <Typography variant="body1" align="left" className="m-t-3">
                      {translate(`PAYMENT_SECTION6_ITEM_PARAGRAPH2`)}
                    </Typography>
                  </Grid>
                  <Grid item md={5} lg={7} className="flex-none">
                    <Box width={{ md: '22rem' }}>
                      <img src={API} alt={translate(`PAYMENT_SECTION6_ITEM_TITLE`)} className="w-full" />
                    </Box>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Box marginTop="4rem" paddingTop="5rem">
          <Typography variant="body1" align="center" className="m-b-2">
            <a href="https://su-api.app/api-docs" className="no-underline fw-500 inline-flex">
              {translate('PAYMENT_SECTION6_START_BUILDING')}
            </a>
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default PaymentAPI;
