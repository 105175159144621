import CloudInvoice from './CloudInvoices';

const routes = [
  {
    path: '/payInvoice/:id',
    component: CloudInvoice,
  },
];

export default routes;
