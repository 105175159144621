import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography, Table, TableBody, TableRow, TableHead, TableContainer } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import { useTranslation } from 'react-i18next';
import styles from '../CloudInvoices.style';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { useSelector, shallowEqual } from 'react-redux';
const cols = ['DATE', 'RESPONSE', 'AUTH CODE', 'AMOUNT'];

export default function PaymentInfo() {
  const { t: translate } = useTranslation();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const cloudInvoice = useSelector(({ cloudInvoice }) => cloudInvoice, shallowEqual);
  const TableCell = withStyles((theme) => ({
    root: {
      border: 'none',
      padding: '.8rem',
      color: '#777272',
    },
  }))(MuiTableCell);
  return (
    <div>
      <Box marginTop="auto" borderRadius="13px" marginX={{ xs: 0, sm: '3rem' }}>
        <Grid container direction="column" className={classes.grayBox} style={{ height: 'inherit', padding: '2rem' }}>
          <Grid item>
            <TableContainer>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {cols.map((Label, key) => (
                      <TableCell align="center" key={key}>
                        <Typography variant="h6" className={classes.minLineHeight}>
                          {translate(Label)}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cloudInvoice.PaymentInfo &&
                    cloudInvoice.PaymentInfo.Details.map((row, key) => (
                      <TableRow
                        key={`TableRow ${key}`}
                        className={key % 2 === 0 ? classes.bgLightGray : classes.bgDarkGray}>
                        <TableCell align="center">
                          <Typography variant="h6" className="fw-400">
                            <Moment format="MMM DD, YYYY HH:MM" date={row.Date} />
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6" className="fw-400">
                            {row.resptext || 'N/A'}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6" className="fw-400">
                            {row.authcode || 'N/A'}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6" className="fw-400">
                            <NumberFormat
                              value={row.Total || row.amount || 0}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              prefix={'US$ '}
                              decimalScale={2}
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
