import { defaultFont, buttons } from "../../../../common/styles/default.styles";
import tooltip from "../../../../common/styles/tooltips.style";

function _custom(customTheme) {
  return theme => ({
    list: {
      ...defaultFont,
      fontSize: "14px",
      margin: 0,
      paddingLeft: "0",
      listStyle: "none",
      paddingTop: "0",
      paddingBottom: "0",
      color: "inherit"
    },
    listItem: {
      float: "left",
      color: "inherit",
      position: "relative",
      display: "block",
      width: "auto",
      margin: "0",
      padding: "0",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        "&:after": {
          width: "calc(100% - 30px)",
          content: '""',
          display: "block",
          height: "1px",
          marginLeft: "15px"
        }
      }
    },
    listItemText: {
      padding: "0 !important"
    },
    link: {
      position: "relative",
      padding: "0.5rem",
      fontWeight: "400",
      fontSize: "12px",
      textTransform: "uppercase",
      lineHeight: "20px",
      textDecoration: "none",
      margin: "0px",
      display: "inline-flex",
      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 30px)",
        marginLeft: "15px",
        marginBottom: "8px",
        marginTop: "8px",
        textAlign: "left",
        "& > span:first-child": {
          justifyContent: "flex-center"
        }
      }
    },
    navLink: {
      cursor: 'pointer',
      margin: 'auto 7px',
      color: 'black',
      textDecoration: 'none',
      "&:hover": {
        color: '#3CD52E'
      },
    },
    navButton: {
      marginLeft: '15px',
      fontWeight: '500',
      ...buttons.primary
    },
    registerNavLink: {
      top: "3px",
      position: "relative",
      fontWeight: "400",
      fontSize: "12px",
      textTransform: "uppercase",
      lineHeight: "20px",
      textDecoration: "none",
      margin: "0px",
      display: "inline-flex"
    },
    navLinkActive: {
      borderBottom: '3px solid #3CD52E',
      paddingBottom: '.2rem',
      fontWeight: 600
    },
    icons: {
      width: "auto",
      height: "19px",
      marginRight: "3px"
    },
    ...tooltip,
    marginRight5: {
      marginRight: "5px"
    }
  });
}



export default _custom;
