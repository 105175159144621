import * as Actions from "./login.actions";

const initialState = {
  loginSucess: false,
  userData: {}
};

function login(state = initialState, action) {
  switch (action.type) {
    case Actions.LOGIN_SUCCESS: {
      return {
        ...state,
        loginSucess: true
      };
    }
    case Actions.LOGIN_2FA: {
      return {
        ...state,
        ...action.payload
      };
    }

    case Actions.LOGIN_ERROR: {
      return {
        ...state,
        ...action.payload
      };
    }

    case Actions.RESEND_ERROR: {
      return {
        ...action.payload
      };
    }

    case Actions.LOGIN_RESTORE: {
      return {
        ...initialState
      };
    }

    case Actions.SET_RECAPTCHA_TOKEN: {
      return {
        ...state,
        ...action.payload
      }
    }

    default: {
      return state;
    }
  }
}

export default login;
