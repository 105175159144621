import React, { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Button, makeStyles, TextField } from '@material-ui/core';
import { textFieldValidations } from '../../../common/components/ReduxForm/TextField';
import { reduxForm } from 'redux-form';
import { buttons } from '../../../common/styles/default.styles';
import PhoneInput from 'react-phone-number-input';

const useStyles = makeStyles(buttons);

const validate = (values) => {
  const fields = {
    phone: {
      value: values.phone,
      required: true,
      isNumber: true,
      minLength: 11,
    },
  };
  return textFieldValidations({ fields });
};

const InputComponent = forwardRef((props, ref) => {
  return <TextField {...props} inputRef={ref} fullWidth size="small" name="phone" />;
});

function PhoneLoginForm({ onSubmit, valid }) {
  const classes = useStyles(buttons);
  const { t: translate } = useTranslation();
  const [value, setValue] = useState('');

  return (
    <Box px={['10px', '30px']}>
      <Grid container direction="column">
        <PhoneInput
          placeholder="Phone number"
          defaultCountry={'US'}
          value={value}
          name="phone"
          onChange={setValue}
          inputComponent={InputComponent}
        />
        <Box className="flex flex-row justify-center items-center m-t-8">
          <Button
            variant="contained"
            disabled={!valid}
            onClick={() => onSubmit(value)}
            className={classes.primary + ' fw-300'}>
            <Box minWidth="100px">{translate('LOGIN.SEND_CODE')}</Box>
          </Button>
        </Box>
      </Grid>
    </Box>
  );
}

export default reduxForm({ form: 'PhoneLogin', validate })(PhoneLoginForm);
