export default {
  "LOGIN.WELCOMETO": "Welcome to",
  "LOGIN.PAYMENTPROCESSING":
    "Payment processing solutions for small and medium-sized businesses",
  "LOGIN.DOYOUWANT": "Do you want to take your bussiness to the next level?",
  "LOGIN.LOGIN": "Login",
  "LOGIN.LOG_IN": "Log in",
  "LOGIN.ENTERDATA": "Enter your account data",
  "LOGIN.RESETPASS": "Reset your password",
  "LOGIN.LOGINWITHNUMBER": "or log in with your mobile number",
  "LOGIN.LOGINWITHEMAIL": "or log in with your account data",
  "LOGIN.FORGOTPASS": "Forgot your password?",
  "LOGIN.BACKTOLOGIN": "Go back to login",
  "LOGIN.RESEND_CODE": "Resend code",
  "LOGIN.CONTACT": "Contact our sales team",
  "LOGIN.VALIDATE_YOUR_IDENTITY": "We need to validate your identity",
  "LOGIN.VCODE_SENT": "A verification code has been sent to your email address",
  "LOGIN.ENTER_VCODE": "Enter the verification code we sent to ",
  "LOGIN.SET_NEW_PASS": "Set a new password",
  "LOGIN.RESET_YOUR_PASS": "Reset your password",
  "LOGIN.SEND": "Send",
  "LOGIN.CONFIRM": "Confirm",
  "LOGIN.SESSION_CLOSED_SUCCESSFULLY": "Your session has been successfully closed",
  "LOGIN.EMAIL_TO_COMPLETE_REGISTRATION": "The email to complete the registration was sent",
  "LOGIN.DO_YOU_WANT_TO_CLOSE": "Do you want to close your session?",
  "LOGIN.PLEASE_CONTACT_YOUR_ADMIN": "Please contact your system administrator",
  "LOGIN.DO_YOU_WANT_TO_RECEIVE": "Do you want to receive a new confirmation email?",
  "LOGIN.WE_JUST_SENT": "We just sent you an email",
  "LOGIN.ATLEAST_NUMERIC": "Must contain at least 1 numeric character.",
  "LOGIN.ATLEAST_LOWERCASE": "Must contain at least 1 lowercase character.",
  "LOGIN.ATLEAST_UPPERCASE": "Must contain at least 1 uppercase character.",
  "LOGIN.ATLEAST_SPECIAL": "Must contain at least 1 special character.",
  "LOGIN.ATLEAS_8CHARACTERS": "Must be at least 8 characters.",
  "LOGIN.ENTER_ONE_TIME_PWD": "Enter a One-time Password",
  "LOGIN.ENTER_ONE_TIME_PWD_DESCRIPTION": "Enter a 6-digit code from your authenticator device, or a single-use recovery code.",
  "LOGIN.ENTER_ONE_TIME_PWD_LABEL": "One-time Password",
  "LOGIN.SELECT_METHOD": "Please select a login method",
  "LOGIN.EMAIL_ADDRESS": "Email address",
  "LOGIN.SMS_VERIFY": "SMS Verification",
  "LOGIN.ENTERPHONE": 'Please type your phone number.',
  'LOGIN.PLEASE_TYPE_VCODE': 'Please type the verification code sent to {{phone}}',
  'LOGIN.SEND_CODE': 'Send code'

};
