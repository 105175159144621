import { container, grayBodyColor } from "../../../../common/styles/default.styles";


export default {
  headingContainer: {
    zIndex: "12",
    color: 'white',
    ...container
  },
  container: {
    // zIndex: "12",
    color: grayBodyColor,
    ...container
  },

  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  grayItemText: {
    color: "#66666c",
    margin: 0,
    textAlign: 'center',
    paddingBottom: '0.5rem'

  },
  aquaCircle: {
    color: '#4dcc9d',
    fontSize: 15,
    marginRight: 5
  },
  itemSubtitle: {
    padding: "0 2rem"
  },

  textGray: {
    color: grayBodyColor
  }
}