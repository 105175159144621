import React from 'react';
import { Box } from '@material-ui/core';

function DeviceOverview({ model }) {
  return (
    <Box bgcolor="black" display="flex" justifyContent="center">
      <iframe
        src={`https://vivoflex.us/resources/3d?model=${model}`}
        title={model}
        frameBorder="0"
        style={{ minWidth: '30rem', minHeight: '30rem', background: 'black' }}></iframe>
    </Box>
  );
}

export default DeviceOverview;
