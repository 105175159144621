import * as Actions from "./toaster.actions";

const initialState = {
  open: false,
  type: "info",
  title: "",
  text: "",
  timeout: 4000,
  position: {
    horizontal: "right",
    vertical: "top"
  }
};

function toaster(state = initialState, { type, payload }) {
  switch (type) {
    case Actions.OPEN_TOASTER: {
      return {
        ...initialState,
        open: true,
        ...payload
      };
    }
    case Actions.CLOSE_TOASTER: {
      return {
        ...state,
        open: false
      };
    }

    case Actions.SHOW_TOASTER_ERROR: {
      if ((!payload.text && !payload.title)) {
        payload.title = "We are having problems";
        payload.text = "Please try again later";
      }
      return {
        ...initialState,
        open: true,
        type: "error",
        ...payload
      };
    }


    default: {
      return state;
    }
  }
};

export default toaster;
