import React from 'react';
import { Container, Grid, Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import city from './img/City.svg';
import instagram from './img/instagram.svg';
import facebook from './img/facebook.svg';
import linkend from './img/linkend.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  bgCity: {
    background: `url(${city})`,
    backgroundRepeat: 'repeat-x',
    width: '100%',
  },
});

const nav = [
  {
    title: 'Follow us',
    links: [
      {
        label: (
          <Box className="flex m-b-1" alignItems="center">
            <img src={facebook} alt={'facebook'} className="h-4 m-r-2" />
            <Typography variant="body2" className="text-gray-500">
              Facebook
            </Typography>
          </Box>
        ),
        url: 'https://www.facebook.com/Vivo.flex/',
        externalLink: true,
      },
      // {
      //   label: (
      //     <Box className="flex m-b-1" alignItems="center">
      //       <img src={twitter} alt={'twitter'} className="h-4 m-r-2" />
      //       <Typography variant="body2" className="text-gray-500">
      //         Twitter
      //       </Typography>
      //     </Box>
      //   ),
      //   url: 'https://twitter.com/FlexiposUS',
      //   externalLink: true,
      // },
      {
        label: (
          <Box className="flex m-b-1" alignItems="center">
            <img src={instagram} alt={'instagram'} className="h-4 m-r-2" />
            <Typography variant="body2" className="text-gray-500">
              Instagram
            </Typography>
          </Box>
        ),
        url: 'https://www.instagram.com/vivo.flex/',
        externalLink: true,
      },
      {
        label: (
          <Box className="flex m-b-1" alignItems="center">
            <img src={linkend} alt={'linkend'} className="h-4 m-r-2" />
            <Typography variant="body2" className="text-gray-500">
              LinkedIn
            </Typography>
          </Box>
        ),
        url: 'https://www.linkedin.com/company/vivoflex/?viewAsMember=true',
        externalLink: true,
      },
    ],
  },
  {
    title: 'WaoFlex',
    links: [
      {
        label: 'About Us',
        url: '/about-us',
      },
    ],
  },
  {
    title: 'Developers',
    links: [
      {
        label: 'WaoFlex API',
        url: 'https://su-api.app/api-docs',
        externalLink: true,
      },
    ],
  },
  {
    title: 'Merchant Services',
    links: [
      {
        label: 'Payments',
        url: '/payments',
      },
      {
        label: 'Applications',
        url: '/services',
      },
      {
        label: 'Dashboard',
        url: '/dashboard',
      },
    ],
  },
];

function Footer({ hideSectionsLinks }) {
  const classes = useStyles();

  return (
    <Box component="div">
      <Container maxWidth="md" className="m-t-20 p-5">
        {!hideSectionsLinks && (
          <Box paddingBottom={{ xs: '0', sm: '4rem' }}>
            <Grid container direction="row" alignContent="flex-start" justify="space-between" className="p-2">
              {nav.map(({ title, links }, key) => (
                <Grid xs={12} sm item key={key}>
                  <Box className="flex flex-col m-t-3" alignItems={{ xs: 'flex-start', sm: 'center' }}>
                    <Box className="flex flex-col text-left">
                      <Typography variant="body2" className="text-bold m-b-2 text-gray-500 text-uppercase">
                        {title}
                      </Typography>
                      {links.map(({ label, url, externalLink }, key) => (
                        <Typography variant="body2" className="m-b-1 text-gray-500" key={`links_${key}`}>
                          {externalLink ? (
                            <a href={url} className="no-underline text-black">
                              {label}
                            </a>
                          ) : (
                            <Link className="text-gray-500 no-underline" rel="noopener noreferrer" to={url}>
                              {label}
                            </Link>
                          )}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Container>

      <Box height={{ xs: '70px', sm: '110px', md: '200px' }} className={classes.bgCity}></Box>
      <Typography variant="body2" className="text-gray-500">
        <Box
          className="m-t-12 m-b-12 w-full"
          display={{ xs: 'inline-block' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent={{ xs: 'center' }}
          textAlign="center">
          <span>© {new Date().getFullYear()} WaoFlex | </span>

          <span>Developed by net24-7</span>
        </Box>
      </Typography>
    </Box>
  );
}

export default Footer;
