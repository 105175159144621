import cookiesService from '../../../../common/services/cookies.service';
import serverPromises from '../../../../common/factories/serverPromises.factory';
import loginFactory from '../../login.factory';
import { SET_SHOWN_FORM } from '../forgotPass/forgotPass.actions';
import { toasterActions, alertDialogActions, userActions } from '../../../../common/store/common.actions';
import i18n from '../../../../i18n';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const RESEND_ERROR = 'RESEND_ERROR';
export const LOGIN_RESTORE = 'LOGIN_RESTORE';
export const GETVCODE_SUCCESS = 'GETVCODE_SUCCESS';
export const GETVCODE_ERROR = 'GETVCODE_ERROR';
export const LOGIN_2FA = 'LOGIN_2FA';
export const SET_RECAPTCHA_TOKEN = 'SET_RECAPTCHA_TOKEN';

function handleError(dispatch, error) {
  dispatch({
    type: toasterActions.SHOW_TOASTER_ERROR,
    payload: { text: error.message },
  });
}

export function submitLoginByEmail(credentials) {
  return (dispatch) =>
    loginFactory
      .loginByEmail(credentials)
      .then((response) => {
        let { Security, UserId, isARobot } = response;
        if (Security?.TwoFactorAuth?.Enabled && isARobot) {
          dispatch({
            type: SET_SHOWN_FORM,
            payload: {
              shownForm: '2FA',
            },
          });

          dispatch({
            type: userActions.SET_USER_INFO,
            payload: { userId: UserId },
          });
        } else {
          return loginSuccess(dispatch, response);
        }
      })
      .catch((error) => {
        let alert = {};
        const message = error.message;

        if (message && message.match(/inactive|disabled/)) {
          dispatch({
            type: toasterActions.SHOW_TOASTER_ERROR,
            payload: {
              title: message,
              text: i18n.t('LOGIN.PLEASE_CONTACT_YOUR_ADMIN'),
            },
          });
        } else if (message && message.match(/active session/)) {
          alert = {
            title: message,
            text: i18n.t('LOGIN.DO_YOU_WANT_TO_CLOSE'),
            buttons: {
              accept: {
                text: 'YES',
                onClick: () => dispatch(logout({ id: error.userData.UserId, forced: true })),
              },
              cancel: { text: 'NO' },
            },
          };

          dispatch({
            type: alertDialogActions.OPEN_ALERT_DIALOG,
            payload: {
              type: 'error',
              ...alert,
            },
          });
        } else if (message && message.match(/complete your registration/)) {
          alert = {
            title: message,
            text: i18n.t('LOGIN.DO_YOU_WANT_TO_RECEIVE'),
            buttons: {
              accept: {
                text: 'YES',
                onClick: () => dispatch(resendEmail({ email: credentials.email })),
              },
              cancel: { text: 'NO' },
            },
          };

          dispatch({
            type: alertDialogActions.OPEN_ALERT_DIALOG,
            payload: {
              type: 'error',
              ...alert,
            },
          });
        } else {
          handleError(dispatch, error);
        }

        return dispatch({
          type: LOGIN_ERROR,
          payload: { userData: error.userData },
        });
      });
}

export function LoginSMSProcess({ phone }) {
  return (dispatch) =>
    serverPromises
      .get({
        collection: 'Users',
        query: { PrincipalPhone: phone },
        // projection: 'Email Security Status',
      })
      .then(([user]) => {
        if (!user) {
          return dispatch({
            type: toasterActions.SHOW_TOASTER_ERROR,
            payload: { text: "User doesn't exist" },
          });
        }

        dispatch({
          type: userActions.SET_USER_INFO,
          payload: { ...user },
        });

        dispatch(getVCode({ phone: user.PrincipalPhone }, 'sms', 'smsVCode'));
      })
      .catch((err) => handleError(dispatch, err));
}

export function submitLoginBySMS(credentials) {
  return (dispatch) =>
    loginFactory
      .loginBySMS(credentials)
      .then((response) => {
        loginSuccess(dispatch, response);
      })
      .catch((error) => {
        let alert = {};
        const message = error.message;

        if (message && message.match(/inactive|disabled/)) {
          dispatch({
            type: toasterActions.SHOW_TOASTER_ERROR,
            payload: {
              title: message,
              text: i18n.t('LOGIN.PLEASE_CONTACT_YOUR_ADMIN'),
            },
          });
        } else if (message && message.match(/active session/)) {
          alert = {
            title: message,
            text: i18n.t('LOGIN.DO_YOU_WANT_TO_CLOSE'),
            buttons: {
              accept: {
                text: 'YES',
                onClick: () => dispatch(logout({ id: error.userData.UserId, forced: true })),
              },
              cancel: { text: 'NO' },
            },
          };

          dispatch({
            type: alertDialogActions.OPEN_ALERT_DIALOG,
            payload: {
              type: 'error',
              ...alert,
            },
          });
        } else if (message && message.match(/complete your registration/)) {
          alert = {
            title: message,
            text: i18n.t('LOGIN.DO_YOU_WANT_TO_RECEIVE'),
            buttons: {
              accept: {
                text: 'YES',
                onClick: () => dispatch(resendEmail({ email: credentials.email })),
              },
              cancel: { text: 'NO' },
            },
          };

          dispatch({
            type: alertDialogActions.OPEN_ALERT_DIALOG,
            payload: {
              type: 'error',
              ...alert,
            },
          });
        } else {
          handleError(dispatch, error);
        }

        return dispatch({
          type: LOGIN_ERROR,
          payload: { userData: error.userData },
        });
      });
}


export function logout(data) {
  return (dispatch) =>
    loginFactory
      .logout(data)
      .then(() => {
        return dispatch({
          type: toasterActions.OPEN_TOASTER,
          payload: {
            type: 'success',
            text: i18n.t('LOGIN.SESSION_CLOSED_SUCCESSFULLY'),
          },
        });
      })
      .catch((error) => handleError(dispatch, error));
}

export function resendEmail(data) {
  return (dispatch) =>
    loginFactory
      .resendEmail(data)
      .then(() => {
        return dispatch({
          type: toasterActions.OPEN_TOASTER,
          payload: {
            type: 'success',
            text: i18n.t('LOGIN.EMAIL_TO_COMPLETE_REGISTRATION'),
          },
        });
      })
      .catch((error) => {
        handleError(dispatch, error);
        return dispatch({
          type: RESEND_ERROR,
          payload: { userData: error.userData },
        });
      });
}

export function getVCode(credentials, method, shownForm) {
  return (dispatch) =>
    loginFactory
      .getVCode({ ...credentials, method })
      .then(() => {
        dispatch({
          type: toasterActions.OPEN_TOASTER,
          payload: {
            type: 'success',
            text: 'A verification code has been sent to your email address',
          },
        });

        return dispatch({
          type: SET_SHOWN_FORM,
          payload: {
            shownForm: shownForm || 'vcodeEntry',
          },
        });
      })
      .catch((error) => handleError(dispatch, error));
}

export function submitCheckTwoFA(credentials) {
  return (dispatch) =>
    loginFactory
      .checkTwoFACode(credentials)
      .then((response) => loginSuccess(dispatch, response))
      .catch((error) => {
        let alert = {};
        const message = error.message;

        if (message && message.match(/inactive|disabled/)) {
          dispatch({
            type: toasterActions.SHOW_TOASTER_ERROR,
            payload: {
              title: message,
              text: i18n.t('LOGIN.PLEASE_CONTACT_YOUR_ADMIN'),
            },
          });
        } else if (message && message.match(/active session/)) {
          alert = {
            title: message,
            text: i18n.t('LOGIN.DO_YOU_WANT_TO_CLOSE'),
            buttons: {
              accept: {
                text: 'YES',
                onClick: () => dispatch(logout({ id: error.userData.UserId, forced: true })),
              },
              cancel: { text: 'NO' },
            },
          };

          dispatch({
            type: alertDialogActions.OPEN_ALERT_DIALOG,
            payload: {
              type: 'error',
              ...alert,
            },
          });
        } else if (message && message.match(/complete your registration/)) {
          alert = {
            title: message,
            text: i18n.t('LOGIN.DO_YOU_WANT_TO_RECEIVE'),
            buttons: {
              accept: {
                text: 'YES',
                onClick: () => dispatch(resendEmail({ email: credentials.email })),
              },
              cancel: { text: 'NO' },
            },
          };

          dispatch({
            type: alertDialogActions.OPEN_ALERT_DIALOG,
            payload: {
              type: 'error',
              ...alert,
            },
          });
        } else {
          handleError(dispatch, error);
        }

        return dispatch({
          type: LOGIN_ERROR,
          payload: { userData: error.userData },
        });
      });
}

function loginSuccess(dispatch, response) {
  const cookieOpt = window.location.hostname === 'localhost' ? { path: '/', domain: 'localhost' } : {};

  console.log(response);
  //usamos las cookies para caso local
  cookiesService.set('flexiposToken', response.token, cookieOpt);

  //se guarda tambien en session storage
  sessionStorage.token = response.token;

  return dispatch({
    type: LOGIN_SUCCESS,
  });
}
