import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Typography, Grid } from '@material-ui/core';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import TopNavBar from '../../components/TopNavBar/TopNavBar';
import Footer from '../../components/Footer/Footer';
import Contact from '../../components/Contact/Contact';
import { Parallax, Heading } from '../../components/index';
import { container, grayBodyColor } from '../../../../common/styles/default.styles';
import CustomizedTabs from '../../../../common/components/CustomizedTabs/CustomizedTabs';
import { redRetail, redRestaurant, redValetParking, redOther } from './img';
import RetailTab from './Tabs/RetailTab';
import RestaurantTab from './Tabs/RestaurantTab';
import ValetParkingTab from './Tabs/ValetParkingTab';
import OtherServicesTab from './Tabs/OtherServicesTab';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {
  container: {
    zIndex: '12',
    color: '#FFFFFF',
    ...container,
  },
  main: {
    color: grayBodyColor,
  },
  expansionPanelSummaryContent: {
    maxWidth: 'max-content',
  },
  expansionPanelSummaryExpandIcon: {
    color: '#3f51b5',
  },
  expansionPanelSummary: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'flex-start',
    padding: 0,
    borderBottom: 'solid 1px #d2d2d2',
  },
  expansionPanel: {
    boxShadow: 'unset',
    background: 'none',
    borderTop: '1px solid #f9f9f9',
    '&:before': {
      background: 'unset',
    },
    marginTop: '2rem',
  },
};

const theme = 'light';
const useStyles = makeStyles(styles);

function FAQ() {
  const { t: translate } = useTranslation();
  const classes = useStyles();

  return (
    <Box component="div" style={{ background: '#f9f9f9' }} paddingBottom="4rem">
      <Container maxWidth="lg">
        <Grid container direction="column" justify="center" alignItems="stretch">
          <Grid item xs={12} className="p-t-5 p-b-5 m-b-5 m-t-8">
            <Typography variant="h3" align="center" className="m-b-2 fw-300 text-gray-500">
              {translate('SERVICES.FAQ')}
            </Typography>
          </Grid>
          <ExpansionPanel classes={{ root: classes.expansionPanel }}>
            <ExpansionPanelSummary
              classes={{
                root: classes.expansionPanelSummary,
                content: classes.expansionPanelSummaryContent,
                expandIcon: classes.expansionPanelSummaryExpandIcon,
              }}
              aria-controls="panel1c-content"
              id="panel1c-header"
              expandIcon={<ExpandMoreIcon className="text-gray-500" />}>
              <Typography variant="body1" align="left" className="text-gray-500">
                {translate('SERVICES.HOW_DO_I_GET')}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="p-r-0 p-l-0">
              <Box display="flex" flexDirection="column">
                <Typography variant="body1" align="left" className="m-b-2 p-t-5 p-b-5 text-gray-500">
                  {translate('SERVICES.TO_BENEFIT_FROM')}
                </Typography>
                <Typography variant="body1" align="left" className="m-b-2 m-t-2">
                  <a className="no-underline fw-500" href="/app/#/ContactOnboarding">
                    {translate('SERVICES.SUBMIT_YOUR_APPLICATION')}{' '}
                  </a>
                </Typography>
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel classes={{ root: classes.expansionPanel }}>
            <ExpansionPanelSummary
              classes={{
                root: classes.expansionPanelSummary,
                content: classes.expansionPanelSummaryContent,
                expandIcon: classes.expansionPanelSummaryExpandIcon,
              }}
              expandIcon={<ExpandMoreIcon className="text-gray-500" />}
              aria-controls="panel2c-content"
              id="panel2c-header">
              <Typography variant="body1" align="left" className="text-gray-500">
                {translate('SERVICES.HOW_CAN_I_CONTACT')}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="p-r-0 p-l-0">
              <Box display="flex" flexDirection="column">
                <Typography variant="body1" align="left" className="m-b-2 p-t-5 p-b-5 text-gray-500">
                  {translate('SERVICES.GET_IN_TOUCH')}
                </Typography>
                <Contact background="#f9f9f9" dropdownSection={true} />
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Container>
    </Box>
  );
}

function Services() {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const hrefButton = '/app/#/ContactOnboarding';

  const tabs = [
    {
      icon: (
        <Box height={{ xs: '2rem', md: '4rem' }}>
          <img className="h-full" src={redRetail} alt="retail" />
        </Box>
      ),
      label: 'Retail',
      content: <RetailTab />,
    },
    {
      icon: (
        <Box height={{ xs: '2rem', md: '4rem' }}>
          <img className="h-full" src={redRestaurant} alt="restaurant" />
        </Box>
      ),
      label: 'Restaurant',
      content: <RestaurantTab />,
    },
    {
      icon: (
        <Box height={{ xs: '2rem', md: '4rem' }}>
          <img className="h-full" src={redValetParking} alt="valet-parking" />
        </Box>
      ),
      label: 'Valet Parking',
      content: <ValetParkingTab />,
    },
    {
      icon: (
        <Box height={{ xs: '2rem', md: '4rem' }}>
          <img className="h-full" src={redOther} alt="other" />
        </Box>
      ),
      label: 'Other',
      content: <OtherServicesTab />,
    },
  ];

  return (
    <Box>
      <TopNavBar
        color="transparentLight"
        theme={theme}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: 'white',
        }}></TopNavBar>
      <Parallax filter title theme={theme}>
        <div className={classes.container} style={{ color: '#505050' }}>
          <Heading
            title="SERVICES.TITLE"
            subtitle="SERVICES.SUBTITLE"
            image="planet"
            button={{ text: translate('SERVICES.HEADINGBUTTONTEXT'), href: hrefButton }}></Heading>
        </div>
      </Parallax>
      <div className={classNames(classes.main)}>
        <Container maxWidth="lg" className="m-t-28">
          <Typography variant="h3" align="center" className="m-b-20 fw-300">
            {translate('SERVICES.SELECT_YOUR_BUSINESS_TYPE')}
          </Typography>

          {/* Tabs */}
          <CustomizedTabs tabs={tabs} id="services-tab"></CustomizedTabs>
        </Container>
        <FAQ />

        <Footer />
      </div>
    </Box>
  );
}

export default Services;
