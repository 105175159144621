import PaymentButton from './components/PaymentButton';
import PaymentButtonConfiguration from './components/PaymentButtonConfiguration';

const routes = [
  {
    path: '/payment-button',
    component: PaymentButton,
  },
  {
    path: '/payment-button/setup',
    component: PaymentButtonConfiguration,
  },
];

export default routes;
