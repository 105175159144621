import { container, grayBodyColor } from "../../../../common/styles/default.styles";
export default {

  headingContainer: {
    zIndex: "12",
    color: 'white',
    ...container
  },
  circleIcon: {
    fontSize: 15,
    marginRight: 5,
  },
  blueIcon: {
    color: '#37bbe3'
  },
  greenIcon: {
    color: '#4dcc9d'
  },
  yellowIcon: {
    color: '#ecb22e'
  },
  stretchWidth: {
    width: 'fill-available',
    height: 'inherit',
    maxHeight: 'fill-available'
  },
  textGray: {
    color: grayBodyColor
  }
};