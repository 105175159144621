import React from "react";
import { List, ListItemText, ListItem, Typography, Box } from "@material-ui/core";

function CustomList({ list, icon, iconType }) {
  return (
    <List component="ul">
      {list.map((e, i) => (
        <ListItem component="li" className="p-0" alignItems="center" key={i}>
          {iconType === "custom" ? icon[i] : icon}
          <ListItemText
            primary={
              <Box py="3px">
                <Typography variant="body1" align="justify">
                  {e}
                </Typography>
              </Box>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}

export default CustomList;
