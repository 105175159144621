import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import TopNavBar from '../../components/TopNavBar/TopNavBar';
import TopNavBarLinks from '../../components/TopNavBar/TopNavBarLinks';
import { Parallax, Contact, Footer } from '../../components/index';
import { container, buttons } from '../../../../common/styles/default.styles';
import { PaymentBrand } from '../Payments/Sections/index';
import { Integrations, Devices, Services, DiagonalContainer } from './Sections/index';
import { Box, Container, Grid, Typography, Button, Hidden } from '@material-ui/core';
import header from './img/header.svg';
import clouds from './img/clouds.png';
import useWindowSize from '../../../../common/hooks/useWindowSize';

const styles = {
  container: {
    zIndex: '12',
    color: '#FFFFFF',
    background: 'white',
    ...container,
  },
};

// const dashboardRoutes = [];
const theme = 'light';
const useStyles = makeStyles(styles);

export default function Main(props) {
  const { ...rest } = props;
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const buttonStyle = makeStyles(buttons)();
  const { height } = useWindowSize();
  const leftList = [
    {
      label: 'SERVICES',
      type: 'sectionLink',
      section: 'seccion1',
      component: <Services />,
    },
    {
      label: 'DEVICES',
      type: 'sectionLink',
      section: 'seccion2',
      component: <Devices />,
    },
    {
      label: 'INTEGRATIONS',
      type: 'sectionLink',
      section: 'seccion3',
      component: (
        <div>
          <PaymentBrand /> <Integrations />
        </div>
      ),
    },
    {
      label: 'CONTACT',
      type: 'sectionLink',
      section: 'seccion4',
      component: <Contact background='#f9f9f9' />,
    },
  ];
  return (
    <div>
      <TopNavBar
        color='transparentLight'
        theme={theme}
        leftLinks={<TopNavBarLinks theme={theme} list={leftList} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: 'white',
        }}
        {...rest}
      ></TopNavBar>
      <Parallax filter title hideRounded theme={theme} height='100vh'>
        <div className={classes.container} style={{ color: '#505050', background: 'transparent' }}>
          <Box className={height > 770 ? 'm-t-32' : 'm-t-60'}>
            <Container>
              <Grid container direction='row' justify='space-between' alignItems='center' spacing={5}>
                <Hidden mdUp>
                  <Grid item xs={12} className='m-r-16'>
                    <Box mt={15} mb={3} height={{ xs: '230px', sm: '300px' }} textAlign='center'>
                      <img src={header} alt='header-img' style={{ width: '100%', height: '100%' }} />
                    </Box>
                  </Grid>
                </Hidden>
                <Grid item container direction='column' justify='flex-start' alignItems='stretch' xs={12} md lg={6}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    textAlign={{ xs: 'center', md: 'left' }}
                    alignItems={{ xs: 'center', md: 'normal' }}
                  >
                    <Typography variant='h4' align='inherit' gutterBottom className='fw-300'>
                      {translate('TITLE')}
                    </Typography>
                    <Typography variant='body1' align='inherit' gutterBottom className='m-t-3 p-r-10 text-gray-500'>
                      {translate('SUBTITLE')}
                    </Typography>
                    <Box display='flex' flexDirection='row'>
                      <a href='/app/#/ContactOnboarding' className='no-underline'>
                        <Button
                          style={{ width: 'max-content', borderRadius: '30px' }}
                          variant='outlined'
                          color='primary'
                          className='m-2 m-l-0'
                        >
                          {translate('ASK_US_ANYTHING')}
                        </Button>
                      </a>
                      <a href='/app/#/apply' className='no-underline'>
                        <Button
                          style={{ width: 'max-content', borderRadius: '30px' }}
                          variant='contained'
                          color='primary'
                          className={`m-2 ${buttonStyle.primary}`}
                        >
                          {translate('APPLY_NOW')}
                        </Button>
                      </a>
                    </Box>
                  </Box>
                </Grid>
                <Hidden smDown>
                  <Grid item xs={12} md lg={6}>
                    <img src={header} alt='header-img' style={{ width: '100%', height: '100%' }} />
                  </Grid>
                </Hidden>
              </Grid>
            </Container>
          </Box>
          <Box className='m-16'>
            <img src={clouds} alt='clouds-img' style={{ width: '100%', height: '100%' }} />
          </Box>
        </div>
      </Parallax>

      <div>
        <DiagonalContainer />
      </div>
      <div>
        {leftList.map((elem) => (
          <div name={elem.section} key={elem.section}>
            {elem.component}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}
