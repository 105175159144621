import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography, Table, TableBody, TableRow, TableHead, TableContainer } from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import { useTranslation } from 'react-i18next';
import styles from '../CloudInvoices.style';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { useSelector, shallowEqual } from 'react-redux';

const cols = ['ITEMS', 'UNIT_PRICE', 'QTY', 'TAX', 'TOTAL_AMOUNT'];
const sum = ['Total', 'Discount', 'ServiceFee', 'Shipping', 'TotalAmount'];

const TableCell = withStyles((theme) => ({
  root: {
    border: 'none',
    padding: '.8rem',
    color: '#777272',
  },
}))(MuiTableCell);

export default function InvoiceDetails() {
  const { t: translate } = useTranslation();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const cloudInvoice = useSelector(({ cloudInvoice }) => cloudInvoice, shallowEqual);

  const { CustomerInfo, CustomFields } = cloudInvoice;

  return (
    <div>
      <Box marginTop="auto" borderRadius="13px" marginX={{ xs: 0, sm: '3rem' }}>
        <Grid item container direction="column" justify="center" alignItems="center" className="p-t-10 p-b-4">
          <Grid item className="p-t-3 p-b-3">
            <Typography variant="h2" component="h2" className={`${classes.blueLabel} fw-500`}>
              <NumberFormat
                value={cloudInvoice.TotalAmount}
                displayType={'text'}
                thousandSeparator={true}
                fixedDecimalScale={true}
                prefix={'US$ '}
                decimalScale={2}
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.blueLabel} variant="body1">
              {translate('DUE ON')}
              <Moment format=" MMM DD, YYYY" date={cloudInvoice.Date} />
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column" className={`${classes.blueLabel} fw-500`}>
          <Grid item className="p-b-10 p-t-4">
            <Typography variant="h5" className={classes.blueLabel}>
              <span>
                {translate('INVOICE')} #{cloudInvoice.Number}{' '}
              </span>
            </Typography>
          </Grid>
          <Grid item className="p-b-5">
            <Typography className={`${classes.blueLabel} ${classes.capitalize}`} variant="h6">
              {translate('BILLING DETAILS')}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            className={classes.fieldsItems}>
            <Grid item>
              <Typography variant="h6" className={`${classes.blueLabel} fw-500`}>
                {translate('BILL TO')}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {CustomerInfo &&
                ['Name', 'Email', 'Phone'].map((e, key) => (
                  <Grid item key={`CustomerInfo${key}`}>
                    <Typography className="fw-400" variant="h6">
                      <label style={{ wordBreak: 'break-all' }}>{CustomerInfo[e]}</label>
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            className={classes.fieldsItems}>
            <Grid item>
              <Typography variant="h6" className={`${classes.blueLabel} fw-500`}>
                {translate('INVOICE ID')}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {' '}
              <Typography className="fw-400" variant="h6">
                {cloudInvoice.InvoiceId}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            className={classes.fieldsItems}>
            <Grid item>
              <Typography variant="h6" className={`${classes.blueLabel} fw-500`}>
                {translate('REFERENCE')}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {' '}
              <Typography className="fw-400" variant="h6">
                {cloudInvoice.Reference || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
          {CustomFields &&
            Object.keys(CustomFields).map((e, key) => (
              <Grid
                key={key}
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                className={classes.fieldsItems}>
                <Grid item>
                  <Typography variant="h6" className={`${classes.blueLabel} fw-500`}>
                    {e}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {' '}
                  <Typography className="fw-400" variant="h6">
                    {CustomFields[e] || 'Empty'}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            className={classes.fieldsItems}>
            <Grid item>
              <Typography variant="h6" className={`${classes.blueLabel} fw-500`}>
                {translate('MESSAGE')}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              {' '}
              <Typography className="fw-400" variant="h6">
                {cloudInvoice.Message}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="p-b-5 p-t-10">
            <Typography className={`${classes.blueLabel} ${classes.capitalize}`} variant="h6">
              {translate('INVOICE SUMARY')}
            </Typography>
          </Grid>
          <Grid item container direction="column">
            <TableContainer>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {cols.map((Label, key) => (
                      <TableCell align="center" key={key} className="p-0">
                        <Typography variant="h6" className={classes.minLineHeight} style={{ lineHeight: 'initial' }}>
                          {translate(Label)}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cloudInvoice.Items &&
                    cloudInvoice.Items.map((row, key) => (
                      <TableRow
                        key={`TableRow ${key}`}
                        className={key % 2 === 0 ? classes.bgLightGray : classes.bgDarkGray}>
                        <TableCell component="th" scope="row">
                          <Typography variant="h6" className="fw-400">
                            {row.Name}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6" className="fw-400">
                            <NumberFormat
                              value={row.Price}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              prefix={'US$ '}
                              decimalScale={2}
                            />
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6" className="fw-400">
                            {row.Quantity}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6" className="fw-400">
                            <NumberFormat
                              value={row.Tax.Mode === 'percentage' ? (row.Price / 100) * row.Tax.Rate : row.Tax.Rate}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              prefix={'US$ '}
                              decimalScale={2}
                            />
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h6" className="fw-400">
                            <NumberFormat
                              value={row.Total}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'US$ '}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  {sum
                    .filter((e) => cloudInvoice[e])
                    .map((row, key, array) => (
                      <TableRow key={`TableRow_${key}`}>
                        <TableCell colSpan={3} className="p-b-0"></TableCell>
                        <TableCell align="right" className="p-b-0">
                          <Typography
                            variant="h6"
                            className={`${key === array.length - 1 ? 'text-bold text-capitalize' : 'fw-400'}`}>
                            {translate(row)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" className="p-b-0">
                          <Typography
                            variant="h6"
                            className={`${key === array.length - 1 ? 'text-bold text-capitalize' : ' fw-400'}`}>
                            <NumberFormat
                              value={cloudInvoice[row]}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              prefix={'$'}
                              decimalScale={2}
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
