import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Container, Box, Typography } from '@material-ui/core';
import visa from './img/visa.svg';
import discover from './img/discover.svg';
import GPay from './img/GPay.svg';
import ApplePay from './img/ApplePay.svg';
import samsungpay from './img/samsungpay.svg';
import mastercard from './img/mastercard.svg';
import amex from './img/amex.svg';
import jcb from './img/jcb.svg';
import nfc from './img/nfc.svg';
import pciPADSS from './img/pciPADSS.svg';
import pcipts from './img/pcipts.svg';
import FirstData from './img/FirstData.svg';
import EMVCo from './img/EMVCo.svg';
import { grayBodyColor } from '../../../../../../common/styles/default.styles';

function PaymentBrand({ displayLink = true }) {
  // console.log(displayLink);
  const { t: translate } = useTranslation();
  const brands = [
    { img: visa, alt: 'visa', col: { xs: 2 }, GridClass: 'flex justify-end m-r-5', height: '5rem' },
    { img: GPay, alt: 'GPay', col: { xs: 2 }, GridClass: 'flex justify-center m-r-5', height: '3rem' },
    { img: ApplePay, alt: 'ApplePay', col: { xs: 2 }, GridClass: 'flex justify-center m-r-5', height: '3rem' },
    {
      img: discover,
      alt: 'discover',
      col: { xs: 2 },
      GridClass: 'flex justify-start m-r-5',
      height: '3rem',
      width: '9rem',
    },
    {
      img: samsungpay,
      alt: 'samsungpay',
      col: { xs: 6 },
      className: 'flex justify-end',
      GridClass: 'flex justify-end p-r-10',
      height: '3rem',
      width: '11rem',
    },
    {
      img: mastercard,
      alt: 'mastercard',
      col: { xs: 6 },
      className: 'flex justify-start',
      GridClass: 'flex justify-start p-l-10',
      height: '3rem',
      width: '11rem',
    },
    {
      img: amex,
      alt: 'amex',
      col: { xs: 6 },
      className: 'flex justify-end',
      GridClass: 'flex justify-end',
      height: '3rem',
      width: '10rem',
    },
    {
      img: jcb,
      alt: 'jcb',
      col: { xs: 6 },
      className: 'flex justify-start',
      GridClass: 'flex justify-start',
      height: '3rem',
      width: '10rem',
    },
  ];

  const certifications = [
    { img: nfc, alt: 'nfc', col: { xs: 4 }, GridClass: 'flex justify-end', height: '3rem' },
    { img: pciPADSS, alt: 'pciPADSS', col: { xs: 3 }, GridClass: 'flex justify-center', height: '4rem', width: '8rem' },
    { img: pcipts, alt: 'pcipts', col: { xs: 4 }, GridClass: 'flex justify-start', height: '4rem' },
    {
      img: FirstData,
      alt: 'SAS',
      col: { xs: 6 },
      className: 'flex justify-end',
      GridClass: 'flex justify-end p-r-5',
      height: '2rem',
      width: '8rem',
    },
    {
      img: EMVCo,
      alt: 'salesForce',
      col: { xs: 6 },
      className: 'flex justify-start',
      GridClass: 'flex justify-start p-l-5',
      height: '2rem',
      width: '10rem',
    },
  ];
  // function openPADSSModal() {
  //   dispatch({
  //     type: customDialogActions.OPEN_CUSTOM_DIALOG,
  //     payload: {
  //       title: 'PCI/PA-DSS Payment Application Validation',
  //       content: <PADSSValidation />,
  //     },
  //   });
  // }

  return (
    <div>
      <Box
        component="div"
        padding={{ xs: '3rem 0', sm: '3rem 1rem', md: '8rem', lg: '5rem 7rem' }}
        style={{ background: '#f9f9f9' }}>
        <Container maxWidth="lg">
          <Grid container direction="column" justify="center" alignItems="stretch">
            <Grid item>
              <Box marginY="3rem">
                <Typography
                  variant="h3"
                  gutterBottom
                  align="center"
                  className="fw-300"
                  style={{ color: grayBodyColor }}>
                  {translate('PAYMENT_SECTION4_TITLE1')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="row" justify="center" alignItems="center">
                {brands.map(
                  (
                    {
                      img,
                      alt,
                      col: { xs, sm },
                      className = 'flex justify-center',
                      imgClass = 'w-full h-full',
                      GridClass,
                      height,
                      width,
                    },
                    key
                  ) => (
                    <Grid item xs={xs} sm={sm} key={key} className={GridClass + ' p-t-0 p-b-0'}>
                      <Box marginY=".5rem" width={width || '7rem'} className={className} height={height}>
                        <img src={img} alt={alt} className={imgClass} />
                      </Box>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg">
          <Grid container direction="column" justify="center" alignItems="stretch">
            <Grid item>
              <Box marginY="3rem">
                <Typography
                  variant="h3"
                  gutterBottom
                  align="center"
                  className="fw-300"
                  style={{ color: grayBodyColor }}>
                  {translate('PAYMENT_SECTION4_TITLE2')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                {certifications.map(
                  (
                    {
                      img,
                      alt,
                      col: { xs, sm },
                      className = 'flex justify-center',
                      imgClass = 'w-full h-full',
                      GridClass,
                      height,
                      width,
                    },
                    key
                  ) => (
                    <Grid item xs={xs} sm={sm} key={key} className={GridClass + ' p-t-0 p-b-0'}>
                      <Box marginY=".5rem" width={width || '7rem'} className={className} height={height}>
                        <img src={img} alt={alt} className={imgClass} />
                      </Box>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

// {displayLink ? (
//   <Box marginY="4rem">
//     {/* PADSS MODAL */}
//     <Typography variant="body1" align="center" className="m-b-2">
//       <Link to="" onClick={openPADSSModal} className="no-underline fw-500 inline-flex">
//         <AddCircleRoundedIcon style={{ marginRight: '.3rem' }} />
//         {translate('ABOUT_CERTIFICATIONS')}
//       </Link>
//     </Typography>
//   </Box>
// ) : (
//   ''
// )}
export default PaymentBrand;
