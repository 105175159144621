export default {
	"PARAGRAPH1": "The Payment Application Data Security Standard (PA-DSS) is a set of requirements for software vendors to develop secure payment applications that	support PCI-DSS compliance. To prove this compliance, a software provider	must have its application audited by a PA-DSS Qualified Security Assessor.",
	"PARAGRAPH2": "Sitio Uno is a software house specialized in developing EMV, NFC, and PCI-DSS validated payment applications.",
	"PARAGRAPH3": "Onetip is a validated POS application which allows	WaoFlex devices to process EMV, magstripe, and NFC	payments from AmEx, Discover, Visa and MasterCard.",
	"PARAGRAPH4": "In order to meet PCI-DSS requirements, OneTip undergoes regular security audit and certification led by PCI Approved	Qualified Security Assessor (PA-QSA). The outcome of the	PA-DSS audit and certification is listed on the PCI “List of Validated Applications” site:",
	"PARAGRAPH5": "Contact Sitio Uno Inc, or WaoFlex support in order to upgrade or receive further information	if you cannot find the OneTip version running on your terminals on this list.",
	"PARAGRAPH6": "The PA-DSS requirements must be read and understood by terminal operators including resellers, ECR integrators, support organizations and the merchant controlling the terminal.",
	"PARAGRAPH7": "Sitio Uno instructs and advices its customers to deploy the OneTip application in a manner that adheres to the PCI Data Security Standard (v3.2.) Subsequent to this, best practices and hardening methods, such as those referenced by the	Center for Internet Security (CIS) and their various “Benchmarks”, should be followed in order to enhance system logging,	reduce the chance of intrusion and increase the ability to detect intrusion, as well as other general recommendations to	secure networking environments.",
	"PARAGRAPH8": "Neither Sitio Uno Inc., nor WaoFlex possess the authority to state that a merchant may be deemed “PCI Compliant”.	Each merchant is responsible for creating a PCI-compliant environment.",
	"QUERY": "Query",
	"COMPANY_NAME": "Company Name",
	"APPLICATION_NAME": "Application Name",
	"ONETIP": "OneTip",
	"SITIO_UNO": "Sitio Uno Inc",
	"CHECK_OUR_VALIDATED_PAYMENT_APP": "Check our Validated Payment Application >"
}