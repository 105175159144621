import React from 'react';
import { Button, Dialog, Box, colors, IconButton, Typography } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import * as dialogActions from './customDialog.actions';
import store from '../../../modules/store';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  buttonAccept: {
    margin: '0 10px',
    background: '#39b54a',
    '&:hover': {
      background: '#339c42',
    },
  },
  buttonCancel: {
    margin: '0 10px',
    background: '#ed5565',
    '&:hover': {
      background: '#d24d5b',
    },
  },
  dialog: {
    '@media (min-width: 320px) and (max-width: 450px)': {
      width: '256px',
    },
    '@media (min-width: 451px) and (max-width: 599px)': {
      width: '385px',
    },
  },
};
const useStyles = makeStyles(styles);

const DialogTitle = (props) => {
  const { onClose, align = 'center', children, ...other } = props;
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <Box display='flex' justifyContent='flex-end'>
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </Box>
      ) : null}
      <Typography align={align} variant='h5'>
        {children}
      </Typography>
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomDialog() {
  const classes = useStyles();
  const customDialog = useSelector(({ customDialog }) => customDialog);
  const { buttons } = customDialog;

  function handleClose(e, reason) {
    if (reason === 'backdropClick') return;
    customDialog.closeOnAcceptClick && store.dispatch({ type: dialogActions.CLOSE_CUSTOM_DIALOG });
  }

  function buttonClick(cb) {
    handleClose();
    cb && cb();
  }

  return (
    <Box className={classes.dialog} color={colors.grey[700]}>
      <Dialog
        onClose={handleClose}
        scroll='paper'
        open={customDialog.open}
        maxWidth={customDialog.maxWidth}
        disableEscapeKeyDown
      >
        <DialogTitle onClose={handleClose}>{customDialog.title}</DialogTitle>
        <DialogContent>{customDialog.content}</DialogContent>
        {customDialog.buttons && (
          <DialogActions>
            <Box color='white' pb='10px'>
              {buttons.cancel && (
                <Button
                  onClick={() => buttonClick(buttons.cancel.onClick)}
                  className={classes.buttonCancel}
                  color='inherit'
                  variant='contained'
                >
                  {buttons.cancel.text}
                </Button>
              )}
              {buttons.accept && (
                <Button
                  onClick={() => buttonClick(buttons.accept.onClick)}
                  className={classes.buttonAccept}
                  color='inherit'
                  variant='contained'
                >
                  {buttons.accept.text}
                </Button>
              )}
            </Box>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
}

function openCustomDialog(options) {
  store.dispatch({
    type: dialogActions.OPEN_CUSTOM_DIALOG,
    payload: {
      ...options,
    },
  });
}

function closeCustomDialog() {
  store.dispatch({
    type: dialogActions.CLOSE_CUSTOM_DIALOG,
  });
}

export { CustomDialog };
export { openCustomDialog };
export { closeCustomDialog };
