import paymentGatewaytFactory from './paymentGateway.factory';
import { toasterActions, customDialogActions } from '../../store/common.actions';
import i18n from '../../../i18n';

export const ONLINEPAYMENT_SUCCESS = 'ONLINEPAYMENT_SUCCESS';
export const ONLINEPAYMENT_ERROR = 'ONLINEPAYMENT_ERROR';
export const ONLINEPAYMENT_RESTORE = 'ONLINEPAYMENT_RESTORE';
export const ONLINEPAYMENT_LOADING = 'ONLINEPAYMENT_LOADING';

export function makePayment({ family, postData, form }) {
  return (dispatch) => {
    return paymentGatewaytFactory
      .makePayment(family, postData)
      .then((response) => {
        console.log(response);
        _setLoading(dispatch, false);

        dispatch({
          type: customDialogActions.CLOSE_CUSTOM_DIALOG,
        });

        dispatch({
          type: ONLINEPAYMENT_SUCCESS,
          payload: { ...response.message.receipt, Success: true, form },
        });

        return dispatch({
          type: toasterActions.OPEN_TOASTER,
          payload: {
            type: 'success',
            text: i18n.t('PAYMENT_GATEWAY.SUCCESS'),
          },
        });
      })
      .catch((error) => {
        _setLoading(dispatch, false);

        const { err, cardConnectBody: CardconnectInfo } = error;
        dispatch({
          type: ONLINEPAYMENT_ERROR,
          payload: { CardconnectInfo, Success: false, form },
        });

        return dispatch({
          type: toasterActions.OPEN_TOASTER,
          payload: {
            type: 'error',
            title: 'Error processing payment',
            text: err?.message || i18n.t('PAYMENT_GATEWAY.ERROR'),
          },
        });
      });
  };

  function _setLoading(dispatch, loading) {
    dispatch({
      type: ONLINEPAYMENT_LOADING,
      payload: { loading, form },
    });
  }
}
