import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Container, Box, Typography } from '@material-ui/core';
import excel from './img/excel.svg';
import sugarcrm from './img/sugarcrm.svg';
import SAS from './img/SAS.svg';
import salesForce from './img/salesForce.svg';
import quickbooks from './img/quickbooks.svg';

function Integrations() {
  const { t: translate } = useTranslation();

  const tools = [
    { img: excel, alt: 'excel', col: { xs: 4 }, GridClass: 'flex justify-end', imgClass: 'm-t-1 m-b-1' },
    { img: sugarcrm, alt: 'sugarcrm', col: { xs: 4 }, GridClass: 'flex justify-center' },
    { img: quickbooks, alt: 'quickbooks', col: { xs: 4 }, GridClass: 'flex justify-start' },
    {
      img: SAS,
      alt: 'SAS',
      col: { xs: 6 },
      className: 'flex justify-end',
      imgClass: 'h-full',
      GridClass: 'flex justify-end p-r-10',
    },
    {
      img: salesForce,
      alt: 'salesForce',
      col: { xs: 6 },
      className: 'flex justify-start',
      imgClass: 'h-full',
      GridClass: 'flex justify-start p-l-10',
    },
  ];
  return (
    <div>
      <Box component="div" padding={{ xs: '3rem 0', sm: '3rem 1rem', md: '8rem', lg: '5rem 7rem' }}>
        <Container>
          <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
            <Grid item>
              <Box marginY="3rem">
                <Typography variant="h3" gutterBottom align="center" className="fw-300">
                  {translate('BUSINESS_TOOLS_TITLE')}
                </Typography>
                <Typography variant="subtitle2" align="center" className="text-gray-500">
                  {translate('BUSINESS_TOOLS_SUBTITLE')}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                {tools.map(
                  (
                    {
                      img,
                      alt,
                      col: { xs, sm },
                      className = 'flex justify-center',
                      imgClass = 'w-full h-full',
                      GridClass,
                    },
                    key
                  ) => (
                    <Grid item xs={xs} sm={sm} key={key} className={GridClass}>
                      <Box
                        marginY=".5rem"
                        width={{ xs: '8rem', sm: '13rem' }}
                        className={className}
                        height={{
                          xs: !(tools.length - (key + 1)) ? '3rem' : '2rem',
                          sm: !(tools.length - (key + 1)) ? '5rem' : '4rem',
                        }}>
                        <img src={img} alt={alt} className={imgClass} />
                      </Box>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

export default Integrations;
