import serverPromises from "../../factories/serverPromises.factory";
import { toasterActions } from "../common.actions"

export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const SET_USER_INFO = "SET_USER_INFO";
export const RESTORE_USER_INFO = "RESTORE_USER_INFO";

export function getUser({ query }) {
  return dispatch =>
    serverPromises
      .get({
        collection: "Users",
        projection: "Email Security Status",
        query
      })
      .then(([user]) => {
        if (!user) {
          return dispatch({
            type: toasterActions.SHOW_TOASTER_ERROR,
            payload: { text: "User doesn't exist" }
          });
        }
        return dispatch({
          type: SET_USER_INFO,
          payload: { ...user }
        });
      })
      .catch(error => {
        return dispatch({
          type: toasterActions.SHOW_TOASTER_ERROR,
          payload: error.message
            ? { text: error.message }
            : { title: "We're having problems", text: "Please try again later" }
        });
      });
}