import * as Actions from "./alertDialog.actions";

const initialState = {
  open: false,
  type: "info",
  title: "",
  text: "",
  buttons: {},
};

function alertDialog(state = initialState, { type, payload }) {
  switch (type) {
    case Actions.OPEN_ALERT_DIALOG: {
      return {
        ...initialState,
        open: true,
        ...payload
      };
    }
    case Actions.CLOSE_ALERT_DIALOG: {
      return {
        ...state,
        open: false
      };
    }


    default: {
      return state;
    }
  }
};

export default alertDialog;
