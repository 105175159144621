import React from 'react';
import { Box, Grid, Button, makeStyles, Typography, Divider } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { buttons } from '../../../common/styles/default.styles';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles({
  buttons: {
    color: '#5B5B5F',
    justifyContent: 'space-between',
    textTransform: 'none',
  },
});

function SelectMethodForm({ onSelect }) {
  const { t: translate } = useTranslation();
  const classes = useStyles(buttons);
  return (
    <Box px={['10px', '30px']}>
      <Grid container direction="column">
        <Box className="flex flex-col m-t-5">
          <Button className={classes.buttons} onClick={() => onSelect('email')}>
            <Typography variant="h6" className="fw-400">
              {translate('LOGIN.EMAIL_ADDRESS')}
            </Typography>
            <ArrowIcon fontSize="small" />
          </Button>
          <Divider className="m-t-3 m-b-3" />
          <Button className={classes.buttons} onClick={() => onSelect('sms')}>
            <Typography variant="h6" className="fw-400">
              {translate('LOGIN.SMS_VERIFY')}
            </Typography>
            <ArrowIcon fontSize="small" />
          </Button>
        </Box>
      </Grid>
    </Box>
  );
}

export default SelectMethodForm;
