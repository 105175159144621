import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../Payments.style';
import { Grid, Container, Box, Typography, Hidden } from '@material-ui/core';
import growth from './img/growth.svg';
import nuts from './img/nuts.svg';

function ProperlyAndFeatures() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const itemImg = [growth, nuts];
  return (
    <Box component="div" padding={{ xs: '3rem 0', sm: '3rem 1rem', md: '5rem 3rem', lg: '5rem 7rem' }}>
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="stretch" justify="center">
          <Grid item>
            <Box padding={{ sm: '2rem' }}>
              <Typography variant="h3" gutterBottom align="center" className={'fw-300 ' + classes.textGray}>
                {translate('PAYMENT_SECTION1_TITLE')}
              </Typography>
            </Box>
          </Grid>
          {[1, 2].map((itemValue, index) => (
            <Grid item xs key={itemValue}>
              <Box marginTop={{ xs: '3rem' }}>
                <Grid
                  container
                  direction={itemValue % 2 ? 'row' : 'row-reverse'}
                  alignItems="stretch"
                  justify="flex-start">
                  <Hidden smUp>
                    <Grid item xs={12} className="flex justify-center">
                      <Box width={{ xs: '12rem' }} paddingX="2rem">
                        <img
                          src={itemImg[index]}
                          alt={translate(`PAYMENT_SECTION3_ITEM${itemValue}_TITLE`)}
                          className="w-full"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box textAlign="center">
                        <Typography variant="h4" gutterBottom className={'fw-500 ' + classes.textGray}>
                          {translate(`PAYMENT_SECTION1_ITEM${itemValue}_TITLE`)}
                        </Typography>
                        <Typography variant="body2">
                          {translate(`PAYMENT_SECTION1_ITEM${itemValue}_PARAGRAPH`)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Hidden>
                  <Hidden xsDown>
                    <Grid item sm={4} className="p-0">
                      <Box width="100%" height={itemValue % 2 ? '15rem' : '16rem'} display="flex" alignItems="center">
                        <img src={itemImg[index]} alt="" className={classNames(classes.stretchWidth)} />
                      </Box>
                    </Grid>
                    <Grid item sm={8} style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                      <Box
                        margin={{
                          sm: itemValue % 2 ? '0 0 0 1rem' : '0 1rem 0 0',
                          md: itemValue % 2 ? '0 10rem 0 2rem' : '0 2rem 0 10rem',
                        }}
                        textAlign={itemValue % 2 ? 'left' : 'right'}>
                        <Typography variant="h4" gutterBottom className={'fw-400 ' + classes.textGray}>
                          {translate(`PAYMENT_SECTION1_ITEM${itemValue}_TITLE`)}
                        </Typography>
                        <Typography variant="body2" className={classes.textGray}>
                          {translate(`PAYMENT_SECTION1_ITEM${itemValue}_PARAGRAPH`)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Hidden>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default ProperlyAndFeatures;
