import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../Payments.style';
import { Grid, Container, Box, Typography, List, ListItem, ListItemText, Hidden } from '@material-ui/core';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import security from './img/security.svg';
import laptop from './img/laptop.svg';

function ProperlyAndFeatures() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const CircleIconClass = ['greenIcon', 'blueIcon'];
  const itemImg = [security, laptop];
  return (
    <Box component="div" padding={{ xs: '3rem 0', sm: '3rem 1rem', md: '5rem 3rem', lg: '5rem 7rem' }}>
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="stretch" justify="center" className={classes.textGray}>
          {[1, 2].map((itemValue, index) => (
            <Grid item xs key={itemValue}>
              <Box marginTop={{ xs: '3rem' }}>
                <Grid
                  container
                  direction={itemValue % 2 ? 'row' : 'row-reverse'}
                  alignItems="stretch"
                  justify={itemValue % 2 ? 'flex-start' : 'flex-end'}>
                  <Hidden smUp>
                    <Grid item xs={12} className="flex justify-center m-b-4">
                      <Box width={{ xs: itemValue % 2 ? '13rem' : '17rem' }}>
                        <img
                          src={itemImg[index]}
                          alt={translate(`PAYMENT_SECTION3_ITEM${itemValue}_TITLE`)}
                          className="w-full"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h4" align="center" gutterBottom className="fw-400">
                        {translate(`PAYMENT_SECTION3_ITEM${itemValue}_TITLE`)}
                      </Typography>
                      <Typography variant="body1" align="center" gutterBottom>
                        {translate(`PAYMENT_SECTION3_ITEM${itemValue}_SUBTITLE`)}
                      </Typography>
                      <Box display="flex" justifyContent={{ xs: 'center' }} textAlign="left">
                        <List component="ul">
                          {[4, 3, 2, 1]
                            .splice(itemValue % 2)
                            .sort()
                            .map((listValue) => (
                              <ListItem
                                component="li"
                                className="p-0 p-b-1"
                                alignItems="center"
                                key={`listItem${listValue}`}>
                                <CircleIcon
                                  className={classNames(classes.circleIcon, classes[CircleIconClass[itemValue - 1]])}
                                />
                                <ListItemText
                                  primary={
                                    <Typography variant="body1" align="left">
                                      {translate(`PAYMENT_SECTION3_ITEM${itemValue}_LISTITEM${listValue}`)}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ))}
                        </List>
                      </Box>
                    </Grid>
                  </Hidden>
                  <Hidden only={['xs', 'md', 'lg', 'xl']}>
                    <Grid item sm={12}>
                      <Box display="flex" flexDirection="column" marginY="2rem">
                        <Typography variant="h4" align="center" className="fw-400">
                          {translate(`PAYMENT_SECTION3_ITEM${itemValue}_TITLE`)}
                        </Typography>
                        <Typography variant="body1" align="center">
                          {translate(`PAYMENT_SECTION3_ITEM${itemValue}_SUBTITLE`)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item sm={4}>
                      <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent={itemValue % 2 ? 'flex-end' : 'flex-start'}>
                        <img
                          src={itemImg[index]}
                          alt={translate(`PAYMENT_SECTION3_ITEM${itemValue}_TITLE`)}
                          className={itemValue % 2 ? 'h-48 m-r-2 w-full' : ''}
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={5} className={itemValue % 2 ? '' : 'm-l-20'}>
                      <Box
                        display="flex"
                        justifyContent={itemValue % 2 ? 'flex-start' : 'flex-end'}
                        alignItems="center"
                        height="100%">
                        <List component="ul">
                          {[4, 3, 2, 1]
                            .splice(itemValue % 2)
                            .sort()
                            .map((listValue) => (
                              <ListItem component="li" className="p-0" key={`listItem${listValue}`}>
                                <Box
                                  display="flex"
                                  width="100%"
                                  alignItems="center"
                                  flexDirection={'row'}
                                  textAlign={'left'}>
                                  <ListItemText
                                    primary={
                                      <Typography variant="body1">
                                        <CircleIcon
                                          className={classNames(
                                            classes.circleIcon,
                                            classes[CircleIconClass[itemValue - 1]]
                                          )}
                                        />
                                        {translate(`PAYMENT_SECTION3_ITEM${itemValue}_LISTITEM${listValue}`)}
                                      </Typography>
                                    }
                                  />
                                </Box>
                              </ListItem>
                            ))}
                        </List>
                      </Box>
                    </Grid>
                  </Hidden>
                  <Hidden smDown>
                    <Grid item md={5} lg={4}>
                      <Box width="100%" height={itemValue % 2 ? '95%' : '100%'} display="flex" alignItems="center">
                        <img src={itemImg[index]} alt="" className={classNames(classes.stretchWidth)} />
                      </Box>
                    </Grid>
                    <Grid item md={7} style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                      <Box marginY="4rem" textAlign={itemValue % 2 ? 'left' : 'right'}>
                        <Typography variant="h4" className="fw-400">
                          {translate(`PAYMENT_SECTION3_ITEM${itemValue}_TITLE`)}
                        </Typography>
                        <Typography variant="body1">
                          {translate(`PAYMENT_SECTION3_ITEM${itemValue}_SUBTITLE`)}
                        </Typography>
                        <List component="ul">
                          <Box display="inline-block" paddingRight="3rem">
                            {[4, 3, 2, 1]
                              .splice(itemValue % 2)
                              .sort()
                              .map((listValue) => (
                                <ListItem component="li" className="p-0 p-b-1" key={`listItem${listValue}`}>
                                  <Box
                                    display="flex"
                                    width="100%"
                                    alignItems="center"
                                    flexDirection={'row'}
                                    textAlign={'left'}>
                                    <ListItemText
                                      primary={
                                        <Typography variant="body1">
                                          <CircleIcon
                                            className={classNames(classes.circleIcon, classes[CircleIconClass[index]])}
                                          />
                                          {translate(`PAYMENT_SECTION3_ITEM${itemValue}_LISTITEM${listValue}`)}
                                        </Typography>
                                      }
                                    />
                                  </Box>
                                </ListItem>
                              ))}
                          </Box>
                        </List>
                      </Box>
                    </Grid>
                  </Hidden>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default ProperlyAndFeatures;
