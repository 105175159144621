import { Cookies } from "react-cookie";

let cookiesService = (function () {
  let cookiesInstance = new Cookies();
  return {
    set: (key, value, options) => cookiesInstance.set(key, value, options || {}),
    get: (key) => cookiesInstance.get(key),
    remove: (key) => cookiesInstance.remove(key),
  };
})();

export default cookiesService;
