import * as Actions from './paymentGateway.actions';

const initialState = {};

function paymentGatewayReducer(state = initialState, { type, payload }) {
  switch (type) {
    case Actions.ONLINEPAYMENT_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    case Actions.ONLINEPAYMENT_ERROR: {
      return {
        ...state,
        ...payload,
      };
    }

    case Actions.ONLINEPAYMENT_LOADING: {
      return {
        ...state,
        ...payload,
      };
    }
    case Actions.ONLINEPAYMENT_RESTORE: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
}

export default paymentGatewayReducer;
