import * as Actions from './user.actions';

const initialState = {};

function toaster(state = initialState, { type, payload }) {
  switch (type) {
    case Actions.SET_USER_INFO: {
      return {
        ...state,
        ...payload,
      };
    }
    case Actions.RESTORE_USER_INFO: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
}

export default toaster;
