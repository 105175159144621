import React, { useState } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import EventEmitter from "../../services/EventEmitter.service";

TopBarProgress.config({
  barColors: {
    "0": "#4372e6"
  }
});

function PaceProgress({ children }) {
  const [paceOn, setPaceOn] = useState(false);
  // console.log(paceOn);
  EventEmitter.on("httpStart", () => setPaceOn(true));
  EventEmitter.on("httpEnd", () => setPaceOn(false));
  return <div>{paceOn ? <TopBarProgress /> : null}</div>;
}


export default PaceProgress;
