import React, { useState, useEffect } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import { Link } from 'react-router-dom';
// @material-ui/icons

import { Menu, Close } from '@material-ui/icons';

// core components
import topNavBarStyles from './TopNavBar.style';
import logo from './img/logo.png';

import TopNavBarLinks from './TopNavBarLinks';

function RightLinks(theme) {
  const rightList = [
    {
      label: 'COMMON.LOG_IN',
      href: '/login',
      type: 'link',
      customClasses: 'text-black',
    },
    {
      label: 'COMMON.APPLY_NOW',
      href: '/app/#/apply',
      type: 'button',
    },
  ];

  return <TopNavBarLinks theme={theme} list={rightList} />;
}

export default function TopNavBar(props) {
  // const history = useHistory();
  const useStyles = makeStyles(topNavBarStyles);
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);

  useEffect(() => {
    headerColorChange();
    if (props.changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body.getElementsByTagName('header')[0].classList.remove(classes[color]);
      document.body.getElementsByTagName('header')[0].classList.add(classes[changeColorOnScroll.color]);
      setScrollDown(true);
    } else {
      document.body.getElementsByTagName('header')[0].classList.add(classes[color]);
      document.body.getElementsByTagName('header')[0].classList.remove(classes[changeColorOnScroll.color]);
      setScrollDown(false);
    }
  };
  const { color, leftLinks, fixed, absolute } = props,
    rightLinks = RightLinks(props.theme);
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
    [classes.flex]: true,
  });
  const brandComponent = (scrollDown) => (
    <Link to="/" className="no-underline p-l-4 p-r-4 p-t-3">
      <img className="w-auto h-8" alt="flexipos" src={logo} />
    </Link>
  );

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        {leftLinks !== undefined ? brandComponent(scrollDown) : null}
        <div className={classes.flex}>
          {leftLinks !== undefined ? (
            <Hidden smDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent(scrollDown)
          )}
        </div>
        {!props.hideRightLinks && (
          <Hidden smDown implementation="css">
            {rightLinks}
          </Hidden>
        )}
        <Hidden mdUp>
          <IconButton
            style={{
              color: props.theme === 'light' || scrollDown ? 'gray' : 'white',
              marginRight: '20px',
            }}
            aria-label="open drawer"
            onClick={handleDrawerToggle}>
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}>
          <div className="flex justify-end">
            <IconButton
              style={{
                color: 'gray',
              }}
              aria-label="close drawer"
              onClick={handleDrawerToggle}
              className="p-b-0">
              <Close />
            </IconButton>
          </div>
          <div className={classes.appResponsive}>
            {leftLinks}
            {!props.hideRightLinks && rightLinks}
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

TopNavBar.defaultProp = {
  color: 'white',
};

TopNavBar.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'transparent',
    'white',
    'rose',
    'dark',
    'transparentLight',
    'transparentDark',
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.node,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'transparent', 'white', 'rose', 'dark'])
      .isRequired,
  }),
};
