import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Box, Grid, Typography, Grow, Avatar, Fade } from '@material-ui/core';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';
import { Parallax } from '../Landing/components/index';
import styles from './CloudInvoices.style';
import InvoiceDetails from './tabs/InvoiceDetails';
import PaymentInfo from './tabs/PaymentInfo';
import SwipeableViews from 'react-swipeable-views';
import { getInvoice, updateInvoice, payInvoce } from './store/cloudInvoices.actions';
import { paymentGatewayActions } from '../../common/store/common.actions';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useParams } from 'react-router-dom';
import Moment from 'react-moment';

const Tabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#0d2430',
    },
  },
}))((props) => <MuiTabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const Tab = withStyles((theme) => ({
  root: {
    textTransform: 'initial',
    color: '#0d2430',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(20),
    marginRight: theme.spacing * 1,
    '&:hover': {
      color: '#0d2430',
    },
    '&$selected': {
      color: '#0d2430',
    },
    '&:focus': {
      color: '#0d2430',
    },
  },
  selected: {},
}))((props) => <MuiTab disableRipple {...props} />);

export default function CloudInvoice() {
  const { t: translate } = useTranslation();
  const useStyles = makeStyles(styles);
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const urlParams = useParams();
  const [value, setValue] = useState(0);
  const { cloudInvoice, paymentGatewayInfo } = useSelector(
    ({ cloudInvoice, paymentGatewayInfo }) => ({
      cloudInvoice,
      paymentGatewayInfo,
    }),
    shallowEqual
  );
  const hasPayments = cloudInvoice.PaymentInfo && cloudInvoice.PaymentInfo.Details.length;

  const handleChange = (event, newValue) => setValue(newValue);
  const handleChangeIndex = (index) => setValue(index);

  useEffect(() => {
    const { CardconnectInfo: ccInfo, PaymentInfo: pgPaymentInfo, Date, _id } = paymentGatewayInfo;
    const { PaymentInfo: ciPaymentInfo } = cloudInvoice;
    console.log(paymentGatewayInfo, cloudInvoice);
    if (ccInfo) {
      let update = {
        $inc: { 'PaymentInfo.TotalReceived': ccInfo.amount || 0 },
        $push: { 'PaymentInfo.Details': { Date, ...ccInfo, ...pgPaymentInfo, method: 'epayment', _id } },
      };

      if (cloudInvoice.TotalAmount === ciPaymentInfo.TotalReceived + ccInfo.amount) {
        update['Status'] = 'closed';
      }
      //actualizo la factura
      dispatch(updateInvoice(urlParams.id, update));

      //limpio el paymentGateway
      dispatch({ type: paymentGatewayActions.ONLINEPAYMENT_RESTORE });
    }
  }, [cloudInvoice, dispatch, paymentGatewayInfo, urlParams]);

  useEffect(() => {
    dispatch(getInvoice(urlParams.id));
  }, [dispatch, urlParams.id]);

  let tabs = [
    {
      label: translate('INVOICE'),
      content: <InvoiceDetails />,
    },
  ];

  if (hasPayments) {
    tabs.push({ label: translate('PAYMENT_INFO'), content: <PaymentInfo /> });
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Typography
        component='div'
        role='tabpanel'
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  console.log(paymentGatewayInfo);
  return (
    <div>
      <Box className='flex flex-col justify-center'>
        <Parallax filter theme='dark' customParallaxStyles={{ maxHeight: '100%' }}>
          <Grow in timeout={700}>
            <Box className={classes.container} my={8}>
              <Grid container direction='row' justify='space-between' alignItems='center'>
                <Grid item>
                  {cloudInvoice.merchant && (
                    <Box display='flex'>
                      <Avatar className={classes.avatar} src={cloudInvoice.merchant.Logo}></Avatar>
                      <Box display='flex' flexDirection='column'>
                        <Typography variant='body1' className='text-gray-700'>
                          {cloudInvoice.merchant.Services.CloudInvoices.Configuration.MerchantInfo.Name}
                        </Typography>
                        <Typography variant='body2' className='text-gray-500'>
                          {cloudInvoice.merchant.Services.CloudInvoices.Configuration.MerchantInfo.Address}
                        </Typography>
                        <Typography variant='body2' className='text-gray-500'>
                          {cloudInvoice.merchant.Services.CloudInvoices.Configuration.MerchantInfo.Email && (
                            <span>
                              {cloudInvoice.merchant.Services.CloudInvoices.Configuration.MerchantInfo.Email} |{' '}
                            </span>
                          )}
                          {cloudInvoice.merchant.Services.CloudInvoices.Configuration.MerchantInfo.Phone}
                        </Typography>
                        <Typography variant='body2'>
                          <Moment format='MMM DD, YYYY ' date={cloudInvoice.Date} />
                          <Moment format='HH:MM' date={cloudInvoice.Date} />
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Grid>
                {cloudInvoice.Status && cloudInvoice.Status !== 'closed' && (
                  <Grid item>
                    <Button
                      variant='contained'
                      onClick={() => payInvoce(cloudInvoice)}
                      className={classes.greenGradient + ' fw-300'}
                    >
                      <Box minWidth='100px'>{translate('PAY_INVOICE')}</Box>
                    </Button>
                  </Grid>
                )}
              </Grid>

              <Box className={classes.card}>
                {!cloudInvoice.Status && (
                  <Box className='flex items-center justify-center' height='70vh'>
                    <CircularProgress size={50} thickness={2.5} />
                  </Box>
                )}
                {cloudInvoice.Status && (
                  <Fade in timeout={700}>
                    <div>
                      {tabs.length > 1 && (
                        <Tabs
                          className='m-t-3'
                          value={value}
                          variant='fullWidth'
                          onChange={handleChange}
                          aria-label='tabs'
                        >
                          {tabs.map((tab, i) => (
                            <Tab label={tab.label} key={i} />
                          ))}
                        </Tabs>
                      )}
                      <SwipeableViews axis={'x'} index={value} onChangeIndex={handleChangeIndex}>
                        {tabs.map((tab, i) => (
                          <Box key={`Box${i}`}>
                            <TabPanel value={value} index={i} dir={theme.direction}>
                              {tab.content}
                            </TabPanel>
                          </Box>
                        ))}
                      </SwipeableViews>
                    </div>
                  </Fade>
                )}
              </Box>
            </Box>
          </Grow>
        </Parallax>
      </Box>
    </div>
  );
}
