export default {
    INVOICE: "Invoice",
    "BILLING DETAILS": "Billing details",
    "BILL TO": "Bill to",
    "INVOICE ID": "Invoice ID",
    "CUSTOM FIELDS": "Custom fields",
    "DUE ON": "Due on",
    "INVOICE SUMARY": "Invoice sumary",
    "PAY": "Pay",
    "ITEMS": "Items",
    "UNIT_PRICE": "Unit Price (USD)",
    "QTY": "Qty",
    "TAX": "TAX",
    "TOTAL_AMOUNT": "Total Amount",
    "Total": "Subtotal",
    "Discount": "Discount",
    "ServiceFee": "Appreciation fee",
    "Shipping": "Shipping",
    "TotalAmount": "Total",
    "PAY_INVOICE": "Pay invoice",
    "PAYMENT_INFO": "Payment information",
    'DATE': 'Date',
    'RESPONSE': "Response",
    'AUTH CODE': "Auth code",
    'AMOUNT': "Amount",
    'MESSAGE': 'Message',
    'REFERENCE': 'Reference'
};