import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./common/styles/scss/default.scss";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./themes/Flexipos.js";
import "./index.css";
import "react-phone-number-input/style.css";

import "./common/styles/scss/default.scss";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import Routes from "./modules/Routes";
import "./i18n";
import { Provider } from "react-redux";
import store from "../src/modules/store";
import { Toaster } from "./common/components/Toaster/Toaster";
import { AlertDialog } from "./common/components/AlertDialog/AlertDialog";
import { CustomDialog } from "./common/components/CustomDialog/CustomDialog";
import PaceProgress from "./common/components/PaceProgress/PaceProgress";
import LocationWatcher from "./common/components/LocationWatcher/LocationWatcher";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function app() {
  return (
    <Provider store={store}>
      <PaceProgress />
      <Toaster />
      <AlertDialog />
      <CustomDialog />
      <Suspense fallback={null}>
        <ThemeProvider theme={theme}>
          <Router basename="/main">
            <LocationWatcher />
            <ScrollToTop />
            <Routes />
          </Router>
        </ThemeProvider>
      </Suspense>
    </Provider>
  );
}

ReactDOM.render(app(), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
