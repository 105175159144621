import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Link as RegularLink } from '@material-ui/core';

import CircleIcon from '@material-ui/icons/FiberManualRecord';
import { miniPOS, check, _1, _2, _3, _4, cashier, invoices, virtualTerminal } from '../img';

import { primaryColor } from '../../../../../common/styles/default.styles';
import CustomList from '../../../../../common/components/CustomList/CustomList';

const useStyles = makeStyles({
  listIcon: {
    color: primaryColor,
    fontSize: 15,
    marginRight: 5,
  },
});

const borderDebug = false
  ? {
      yellow: { border: '2px solid yellow' },
      blue: { border: '2px solid blue' },
      pink: { border: '2px solid pink' },
      red: { border: '2px solid red' },
      green: { border: '2px solid green' },
      brown: { border: '4px solid brown' },
      grey: { border: '2px solid grey' },
    }
  : {};

function ServiceSection({ service }) {
  const classes = useStyles();
  const { t: translate } = useTranslation();

  return (
    <Grid container direction="row" className="m-b-32" style={borderDebug.brown} id={service.title}>
      <Grid item xs={12} className="p-t-5 p-b-5" style={borderDebug.yellow}>
        <Typography variant="h3" align="left" className="m-b-2 fw-300">
          {service.title}
        </Typography>
      </Grid>

      <Grid item xs={12} className="flex flex-row" style={borderDebug.yellow}>
        <Grid container direction="row" alignContent="flex-start" alignItems="center">
          <Grid item xs={12} sm={5} style={borderDebug.pink}>
            <Box
              height={{ xs: '15rem', sm: '20rem' }}
              className="flex flex-row justify-center"
              mb={{ xs: '20px', sm: '0' }}>
              <img className="h-full p-l-4 p-r-4" src={service.icon} alt="miniPos" style={borderDebug.grey} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} style={borderDebug.red}>
            <Box className="flex flex-col">
              <Typography variant="body1" align="left" className="m-b-2 fw-500">
                {service.mainText}
              </Typography>
              <CustomList list={service.pointList} icon={<CircleIcon className={classes.listIcon} />} />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className="flex flex-col m-b-6" style={borderDebug.yellow}>
        <Box maxWidth={{ xs: '900px' }}>
          <CustomList
            list={service.checkList}
            icon={
              <Box height={{ xs: '1.5rem', sm: '2rem' }} mr="20px" my="10px">
                <img className="h-full" src={check} alt="check" />
              </Box>
            }
          />
        </Box>
        {service.paymentsLink && (
          <Typography variant="body1" align="left" className="m-b-2 m-t-2">
            <Link to="/payments" className="fw-500 no-underline">
              {translate('MINIPOS.LEARN_ABOUT_PAYMENTS')}
            </Link>
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} className="flex flex-col" style={borderDebug.yellow}>
        <Box maxWidth={{ xs: '950px' }}>
          <CustomList
            list={service.numericList}
            iconType="custom"
            icon={[
              <Box height={{ xs: '2.6rem', sm: '3rem' }} mr="2.2rem" my="10px">
                <img className="h-full" src={_1} alt="1" />
              </Box>,
              <Box height={{ xs: '2.6rem', sm: '3rem' }} mr="20px" my="10px">
                <img className="h-full" src={_2} alt="2" />
              </Box>,
              <Box height={{ xs: '2.6rem', sm: '3rem' }} mr="20px" my="10px">
                <img className="h-full" src={_3} alt="3" />
              </Box>,
              <Box height={{ xs: '2.6rem', sm: '3rem' }} mr="20px" my="10px">
                <img className="h-full" src={_4} alt="4" />
              </Box>,
            ]}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

function Title() {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const mainList = [
    translate('RETAIL.PRODUCT_CATALOG'),
    translate('RETAIL.BARCODE_READER'),
    translate('RETAIL.INSTORE_PAYMENTS'),
    translate('RETAIL.ONLINE_PAYMENTS'),
  ];

  return (
    <Box>
      <Typography variant="h4" align="left" className="m-b-2 fw-300">
        {translate('SERVICES.RETAIL')}
      </Typography>
      <Typography variant="body1" align="left">
        {translate('SERVICES.TAILORED_MADE')}
      </Typography>
      <CustomList list={mainList} icon={<CircleIcon className={classes.listIcon} />} />
    </Box>
  );
}

function RetailTab() {
  const { t: translate } = useTranslation();

  function firstSentenceBold(text) {
    const [firstSentence, ...rest] = text.split('.');
    return (
      <span>
        <span className="text-bold text-gray-500"> {firstSentence}.</span>
        {rest.join('.')}
      </span>
    );
  }

  const services = [
    {
      title: translate('RETAIL.MINI_POS'),
      icon: miniPOS,
      mainText: translate('MINIPOS.MAIN_TEXT'),
      pointList: Array(6)
        .fill()
        .map((e, i) => translate(`MINIPOS.POINT_LIST${i + 1}`)),
      checkList: Array(3)
        .fill()
        .map((e, i) => translate(`MINIPOS.CHECK_LIST${i + 1}`)),
      paymentsLink: true,
      numericList: Array(3)
        .fill()
        .map((e, i) => firstSentenceBold(translate(`MINIPOS.NUMERIC_LIST${i + 1}`))),
    },
    {
      title: translate('RETAIL.CASHIER'),
      icon: cashier,
      mainText: translate('CASHIER.MAIN_TEXT'),
      pointList: Array(6)
        .fill()
        .map((e, i) => translate(`CASHIER.POINT_LIST${i + 1}`)),
      checkList: Array(5)
        .fill()
        .map((e, i) => translate(`CASHIER.CHECK_LIST${i + 1}`)),
      paymentsLink: false,
      numericList: Array(4)
        .fill()
        .map((e, i) => firstSentenceBold(translate(`CASHIER.NUMERIC_LIST${i + 1}`))),
    },
    {
      title: translate('RETAIL.INVOICES'),
      icon: invoices,
      mainText: translate('INVOICES.MAIN_TEXT'),
      pointList: Array(6)
        .fill()
        .map((e, i) => translate(`INVOICES.POINT_LIST${i + 1}`)),
      checkList: Array(4)
        .fill()
        .map((e, i) => translate(`INVOICES.CHECK_LIST${i + 1}`)),
      paymentsLink: false,
      numericList: Array(3)
        .fill()
        .map((e, i) => firstSentenceBold(translate(`INVOICES.NUMERIC_LIST${i + 1}`))),
    },
    {
      title: translate('RETAIL.VIRTUAL_TERMINAL'),
      icon: virtualTerminal,
      mainText: translate('VIRTUAL_TERMINAL.MAIN_TEXT'),
      pointList: Array(7)
        .fill()
        .map((e, i) => translate(`VIRTUAL_TERMINAL.POINT_LIST${i + 1}`)),
      checkList: Array(5)
        .fill()
        .map((e, i) => translate(`VIRTUAL_TERMINAL.CHECK_LIST${i + 1}`)),
      paymentsLink: false,
      numericList: Array(3)
        .fill()
        .map((e, i) => firstSentenceBold(translate(`VIRTUAL_TERMINAL.NUMERIC_LIST${i + 1}`))),
    },
  ];

  function changeTab(tabId, serviceId) {
    document.getElementById(tabId).click();
    setTimeout(() => {
      const serv = document.getElementById(serviceId);
      window.scrollTo(0, serv.offsetParent.offsetTop + serv.offsetTop - 80);
    }, 50);
  }

  return (
    <div>
      <Grid container direction="column" alignContent="flex-start">
        <Grid item xs={12} className="p-t-5 p-b-5" style={borderDebug.blue}>
          <Title />
        </Grid>

        <Grid item xs={12} className="p-t-5" style={borderDebug.green}>
          <Typography variant="h3" align="center" className="m-b-2 fw-300">
            {translate('RETAIL.RETAIL_SERVICES')}
          </Typography>
        </Grid>

        <Grid item xs={12} className="p-b-5" style={borderDebug.green}>
          {services.map((service, i) => {
            return <ServiceSection service={service} key={i} />;
          })}
        </Grid>

        <Grid item xs={12} className="p-t-5 p-b-5" style={borderDebug.green}>
          <Typography variant="h4" align="center" className="m-b-2 fw-300">
            {translate('SERVICES.YOU_MAY_ALSO')}
          </Typography>
        </Grid>

        <Grid item xs={12} className="p-t-5 p-b-5 flex flex-col" style={borderDebug.green}>
          <Typography variant="body1" align="left" className="m-b-2 fw-500">
            <RegularLink onClick={() => changeTab('Other', 'Staff Management')}>
              {translate('SERVICES.STAFF_MANAGEMENT')}
            </RegularLink>
          </Typography>
          <Typography variant="body1" align="left" className="m-b-2 fw-500">
            <RegularLink onClick={() => changeTab('Restaurant', 'Tips')}>{translate('RETAIL.TIPS')}</RegularLink>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default RetailTab;
