import React from 'react';
import { Box, Grid, Button, Link, makeStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import { TextField, textFieldValidations } from '../../../common/components/ReduxForm/TextField';
import { buttons } from '../../../common/styles/default.styles';

const useStyles = makeStyles(buttons);

const validate = (values) => {
  const fields = {
    email: {
      value: values.email,
      required: true,
      isEmail: true,
    },
  };
  return textFieldValidations({ fields });
};

function ForgotPassForm({ handleSubmit, valid, handleBackToLogin }) {
  const { t: translate } = useTranslation();
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <Box px={['10px', '30px']}>
        <Grid container direction="column">
          <Field name="email" component={TextField} label="Email" className="m-t-2 m-b-2" />
          <Box className="flex flex-row justify-between items-center m-t-5">
            <Link className="text-sm fw-400 cursor-pointer text-left" onClick={() => handleBackToLogin()}>
              {translate('LOGIN.BACKTOLOGIN')}
            </Link>
            <Button variant="contained" disabled={!valid} type="submit" className={classes.primary + ' fw-300'}>
              <Box minWidth="100px">{translate('LOGIN.SEND')}</Box>
            </Button>
          </Box>
        </Grid>
      </Box>
    </form>
  );
}

export default reduxForm({ form: 'forgotPassEmail', validate })(ForgotPassForm);
