

export default {
  "ABOUTUS.HOME": "HOME",
  "ABOUTUS.IN-STORE": "IN-STORE PAYMENTS",
  "ABOUTUS.E-PAYMENTS": "E-PAYMENTS",
  "ABOUTUS.API": "API",
  "ABOUTUS.TITLE": "Get more done with less effort with our help",
  "ABOUTUS.SUBTITLE": "WaoFlex software and hardware systems are solutions for online payment experiences",
  "ABOUTUS.TEXT1": "Our technology is built to meet all merchants needs.",
  "ABOUTUS.TEXT2": "No matter what you’re selling or what stage you’re at, we’ve got you covered.",
  "ABOUTUS.WELCOME_NEWERA": "Welcome to the new era",
  "ABOUTUS.WELCOME_NEWERA_TEXT1": `Technology innovation has changed the way we live, and continues to do so with their ever
  evolving advancements. While credit cards changed the way we make payments, the way we accept payments is constantly evolving 
  and improving. From magstripe to EMV chips and now contactless payments and digital wallets, the methods for accepting payments 
  are more secure and efficient than ever. People have become adjusted to these sorts of technologies and advancements and expect 
  nothing less in their everyday life.`,
  "ABOUTUS.WELCOME_NEWERA_TEXT2": `That’s why WaoFlex has dedicated itself to becoming part of the evolution with its own features 
  and developments. Our payment software and hardware is designed to keep up with any business. Find the perfect device for your company’s 
  needs, never miss a sale, and take a look at your team and services performance without missing any of the important details.`,
  "ABOUTUS.WELCOME_NEWERA_TEXT3": `Discover the benefits of working with WaoFlex and get ready to change the way your business processes payments.`,
  "ABOUTUS.WHATWEDO": `What we do`,
  "ABOUTUS.FINANCIAL_DASHBOARD": `Financial dashboard and reporting`,
  "ABOUTUS.LEARNMORE": "LEARN MORE",
  "ABOUTUS.TOOLS": "All the right tools in one place",
  "ABOUTUS.DEVICE_SOLUTIONS": "Device solutions",
  "ABOUTUS.IMPORTANT_RESOURCE": "WaoFlex most important resource is people",
  "ABOUTUS.JOINUS_TEXT": "Systems are always improving, and our team is always creating new ideas to accomplish goals. We strive to have a positive impact on online commerce and create more business.",
  "ABOUTUS.JOINUS": "JOIN US"
}
