import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, Hidden, Button, Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { useHistory } from "react-router-dom";

import planet from './img/planet.svg';
import dashboard from './img/dashboard.svg';
import puzzle from './img/puzzle.svg';
import device from './img/device.svg';
import TopNavBar from '../../components/TopNavBar/TopNavBar';
import Footer from '../../components/Footer/Footer';
import { Parallax, Heading } from '../../components/index';
import styles from '../Main/Main.style';
import sectionsStyles from './AboutUs.style';

const dashboardRoutes = [];
const theme = 'light';
const useStyles = makeStyles(styles);
const useStylesSections = makeStyles(sectionsStyles);

function AboutUs(props) {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const sectionsClasses = useStylesSections();
  // const history = useHistory();

  return (
    <Box>
      <TopNavBar
        color="transparentLight"
        theme={theme}
        routes={dashboardRoutes}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: 'white',
        }}></TopNavBar>
      <Parallax filter theme={theme}>
        <div className={classes.container} style={{ color: '#505050' }}>
          <Heading title="ABOUTUS.TITLE" subtitle="ABOUTUS.SUBTITLE" image="computer"></Heading>
        </div>
      </Parallax>
      <div className={classNames(classes.main)}>
        {/* Seccion 1 */}
        <Grid container direction="column" alignContent="center" className="text-gray-500 text-center m-t-16 p-5">
          <Grid item xs={12}>
            <Typography variant="h4" className="fw-300">
              {translate('ABOUTUS.TEXT1')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">{translate('ABOUTUS.TEXT2')}</Typography>
          </Grid>
        </Grid>
        {/* Seccion 2 */}
        <Grid
          container
          direction="row"
          alignContent="center"
          justify="flex-start"
          className="text-gray-500 text-left m-t-16 p-5 p-l-0 p-r-0">
          <Grid item xs={false} md={4} lg={3} className="self-center">
            <img src={planet} alt="planet" />
          </Grid>

          <Grid item xs={12} md={8} lg={9} xl={7} className="p-10 leading-10 text-justify">
            <Typography variant="h3" className="m-b-4 fw-300">
              {translate('ABOUTUS.WELCOME_NEWERA')}
            </Typography>

            <Typography variant="body1" className="m-b-3">
              {translate('ABOUTUS.WELCOME_NEWERA_TEXT1')}
            </Typography>
            <Typography variant="body1" className="m-b-3">
              {translate('ABOUTUS.WELCOME_NEWERA_TEXT2')}
            </Typography>
            <Typography variant="body1" className="m-b-3">
              {translate('ABOUTUS.WELCOME_NEWERA_TEXT3')}
            </Typography>
          </Grid>
        </Grid>
        {/* Seccion 3 */}
        <Grid
          container
          direction="row"
          alignContent="center"
          justify="flex-start"
          className={'text-black text-left m-t-16 p-l-0 p-r-0 w-full ' + sectionsClasses.bgSectionGray}>
          <Grid item xs={12} sm={7} className="flex flex-col items-center justify-center">
            <Box minWidth="283px" width={{ md: '365px' }} p={{ xs: '20px' }}>
              {' '}
              <Typography variant="subtitle1" className="m-t-2 m-b-6 text-gray-500">
                {translate('ABOUTUS.FINANCIAL_DASHBOARD')}
              </Typography>
              <Link to="/dashboard" className="no-underline">
                <Button className={sectionsClasses.button}>{translate('ABOUTUS.LEARNMORE')}</Button>
              </Link>
            </Box>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={false} sm={5} className="flex flex-row justify-end">
              <Box height={{ sm: '250px', md: '350px', lg: '492px', xl: '550px' }}>
                <img className="h-full" src={dashboard} alt="dashboard" />
              </Box>
            </Grid>
          </Hidden>
        </Grid>
        {/* Seccion 4 */}
        <Grid
          container
          direction="row"
          alignContent="center"
          justify="flex-start"
          className={'text-black text-left p-l-0 p-r-0 w-full ' + sectionsClasses.bgSectionGray}>
          <Grid item xs={12} sm={7} className="flex flex-col items-center justify-center">
            <Box minWidth="283px" width={{ md: '365px' }} p={{ xs: '20px' }}>
              <Typography variant="subtitle1" className="m-t-2 m-b-6 text-gray-500">
                {translate('ABOUTUS.TOOLS')}
              </Typography>
              <Link to="/services" className="no-underline">
                <Button className={sectionsClasses.button}>{translate('ABOUTUS.LEARNMORE')}</Button>
              </Link>
            </Box>
          </Grid>

          <Hidden xsDown>
            <Grid item xs={false} sm={5} className="flex flex-row justify-end">
              <Box height={{ sm: '250px', md: '350px', lg: '492px', xl: '550px' }}>
                <img className="h-full" src={puzzle} alt="puzzle" />
              </Box>
            </Grid>
          </Hidden>
        </Grid>
        {/* Seccion 5 */}
        <Grid
          container
          direction="row"
          alignContent="center"
          justify="flex-start"
          className={'text-black text-left p-l-0 p-r-0 w-full ' + sectionsClasses.bgSectionGray}>
          <Grid item xs={12} sm={7} className="flex flex-col items-center justify-center">
            <Box minWidth="283px" width={{ md: '365px' }} p={{ xs: '20px' }}>
              <Typography variant="subtitle1" className="m-t-2 m-b-6 text-gray-500">
                {translate('ABOUTUS.DEVICE_SOLUTIONS')}
              </Typography>

              <Link to="/payments" className="no-underline">
                <Button className={sectionsClasses.button}>{translate('ABOUTUS.LEARNMORE')}</Button>
              </Link>
            </Box>
          </Grid>

          <Hidden xsDown>
            <Grid item xs={false} sm={5} className="flex flex-row justify-end">
              <Box height={{ sm: '250px', md: '350px', lg: '492px', xl: '550px' }}>
                <img className="h-full" src={device} alt="device" />
              </Box>
            </Grid>
          </Hidden>
        </Grid>
        {/* Seccion 6 */}
        <Grid container direction="column" alignContent="center" className="text-black text-center m-b-16S p-5">
          <Grid item xs={12}>
            <Box width={{ sm: '500px' }}>
              <Typography variant="h3" className="m-b-1 m-t-16 text-gray-500 fw-300">
                {translate('ABOUTUS.IMPORTANT_RESOURCE')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>

      <Footer></Footer>
    </Box>
  );
}

export default AboutUs;
