import { vivoGreenGradientColor } from "../../../../common/styles/default.styles";

const styles = theme => ({
  bgSectionGray: {
    backgroundColor: "rgb(246, 253, 255)"
  },
  button: {
    background: vivoGreenGradientColor,
    color: "white"
  },

});

export default styles;