import { conatinerFluid, blueGradientColor, buttons } from '../../common/styles/default.styles';

const styles = (theme) => ({
  container: {
    zIndex: '12',
    color: '#FFFFFF',
    ...conatinerFluid,
  },
  card: {

    borderRadius: '13px',
    background: 'white',
    boxShadow: '0px 0px 22px -1px rgba(92,92,92,1)',
    '@media (min-width: 400px) and (max-width: 599px)': {
      width: '365px',
    },
    '@media (min-width: 600px) and (max-width: 739px)': {
      width: '550px',
    },
    '@media (min-width: 740px) and (max-width: 959px)': {
      width: '700px',
    },
    '@media (min-width: 960px)': {
      width: '900px',
    },
    marginTop: '1rem',
    overflowX: 'auto',
  },
  bgBlueGradient: {
    background: blueGradientColor,
  },
  ...buttons,
  avatar: {
    width: '4rem',
    height: '4rem',
    marginRight: '1rem',
    background: '#36c5f0',
  },
  grayBox: {
    //backgroundColor: "#e8e8e81f",
    color: 'black',
    borderRadius: '2%',
    padding: '1rem 1rem 3rem',
    [theme.breakpoints.up('md')]: {
      padding: '1rem 5rem 3rem',

    }
  },
  blueLabel: {
    color: '#5B5B5F',
  },
  bgLightGray: {
    background: '#f6f6f6',
  },
  bgDarkGray: {
    background: '#eaeaea',
  },
  capitalize: {
    textTransform: "uppercase"
  },
  minLineHeight: {
    lineHeight: "0.5rem"
  },
  fieldsItems: {
    borderBottom: ".5px solid #cccccc",
    padding: ".3rem 0",
    wordBreak: 'break-all'
  }
});
export default styles;
