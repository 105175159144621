export default {
	"DASHBOARD.TITLE": "Go further with financial dashboards and reporting.",
	"DASHBOARD.HEADINGBUTTONTEXT": "Contact our sale team",
	"DIVE_DEEPER_INTO_THE_DATA": "Dive deeper into the data",
	"DASHBOARD.FLEXIPOS": "WaoFlex dashboard outlines and recaps sales, services, payment methods, and team performances.",
	"SECTION1.LISTITEM1": "Check your data for every transaction.",
	"SECTION1.LISTITEM2": "Keep track of your team with our time-clock report.",
	"SECTION1.LISTITEM3": "Look at your revenues classified by payment methods.",
	"SECTION1.LISTITEM4": "Easily oversee your services behavior",
	"FLEXIPOS_DASHBOARD_IS": "WaoFlex dashboard is where numbers are turned into data to help your business grow.",
	"SECTION2.ITEMTITLE1": "The details you need",
	"SECTION2.ITEMSUBTITLE1": "Our reporting capabilities are designed to work for all businesses. Check progress toward goals, no matter where you are.",
	"SECTION2.ITEMTITLE2": "Powerful statistics",
	"SECTION2.ITEMSUBTITLE2": "Monitor client behavior by comparing sales over days, weeks or months.",
	"SECTION2.ITEMTITLE3": "Team management",
	"SECTION2.ITEMSUBTITLE3": "Recent activity is the perfect way to take care of your team. Know when your employees sign in or out of work, how busy they are, and tips earned."
}