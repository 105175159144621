import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../Payments.style';
import { Grid, Container, Box, Typography, List, ListItem, ListItemText, Hidden } from '@material-ui/core';

import { Link } from 'react-router-dom';

import OnlineCashier from './img/onlineCashier.svg';
import Invoices from './img/invoices.svg';
import VirtualTerminal from './img/virtualTerminal.svg';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import OCicon1 from './img/OCicon1.svg';
import OCicon2 from './img/OCicon2.svg';
import OCicon3 from './img/OCicon3.svg';
import INicon1 from './img/INicon1.svg';
import INicon2 from './img/INicon2.svg';
import INicon3 from './img/INicon3.svg';
import VTicon1 from './img/VTicon1.svg';
import VTicon2 from './img/VTicon2.svg';
import VTicon3 from './img/VTicon3.svg';
function OnlinePayments() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const sectionImg = [OnlineCashier, Invoices, VirtualTerminal];
  const gridItemImages = [
    [OCicon1, OCicon2, OCicon3],
    [INicon1, INicon2, INicon3],
    [VTicon1, VTicon2, VTicon3],
  ];
  const CircleIconClass = ['blueIcon', 'yellowIcon', 'greenIcon'];

  return (
    <Box component="div" padding={{ xs: '3rem 0', sm: '3rem 1rem', md: '5rem 3rem', lg: '5rem 7rem' }}>
      <Container maxWidth="lg">
        <Grid container direction="column" justify="center" alignItems="stretch" className={classes.textGray}>
          <Grid item>
            <Box padding={{ sm: '2rem' }}>
              <Typography variant="h3" gutterBottom align="center" className="fw-300">
                {translate('PAYMENT_SECTION5_TITLE')}
              </Typography>
              <Typography variant="body1" align="center">
                {translate('PAYMENT_SECTION5_SUBTITLE')}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="stretch" justify="center">
              {[1, 2, 3].map((itemValue) => (
                <Box key={itemValue.toString()} marginTop={{ xs: '5rem' }}>
                  <Grid item xs>
                    <Grid
                      container
                      direction={itemValue % 2 ? 'row' : 'row-reverse'}
                      justify="space-evenly"
                      alignItems="center"
                      spacing={3}>
                      <Hidden smUp>
                        <Grid item xs={12} className="flex justify-center">
                          <Box width={{ xs: '15rem' }}>
                            <img
                              src={sectionImg[itemValue - 1]}
                              alt={translate(`PAYMENT_SECTION5_ITEM${itemValue}_TITLE`)}
                              className="w-full"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h3" align="center" gutterBottom className="fw-300">
                            {translate(`PAYMENT_SECTION5_ITEM${itemValue}_TITLE`)}
                          </Typography>
                          <Typography variant="body1" align="center" gutterBottom>
                            {translate(`PAYMENT_SECTION5_ITEM${itemValue}_SUBTITLE`)}
                          </Typography>
                        </Grid>
                      </Hidden>
                      <Hidden xsDown>
                        <Grid item sm={6} md={5} lg={4}>
                          <Typography
                            variant="h3"
                            align={itemValue % 2 ? 'left' : 'right'}
                            gutterBottom
                            className="fw-300">
                            {translate(`PAYMENT_SECTION5_ITEM${itemValue}_TITLE`)}
                          </Typography>
                          <Typography variant="body1" align={itemValue % 2 ? 'left' : 'right'} gutterBottom>
                            {translate(`PAYMENT_SECTION5_ITEM${itemValue}_SUBTITLE`)}
                          </Typography>
                        </Grid>
                        <Grid item sm className="flex-none">
                          <Box width={{ sm: '15rem', md: '20rem' }}>
                            <img
                              src={sectionImg[itemValue - 1]}
                              alt={translate(`PAYMENT_SECTION5_ITEM${itemValue}_TITLE`)}
                              className="w-full"
                            />
                          </Box>
                        </Grid>
                      </Hidden>
                    </Grid>
                  </Grid>
                  <Grid item xs>
                    <Box marginTop={{ xs: '3rem' }}>
                      <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={2}>
                        {[1, 2, 3].map((gridValue, index) => (
                          <Grid item key={`gridItem${gridValue}`} xs={12} sm>
                            <Box
                              display="flex"
                              flexDirection={{ xs: 'row', sm: 'column' }}
                              alignItems="center"
                              height="100%"
                              marginBottom={{ xs: '1rem' }}>
                              <Box
                                width={{ xs: '3.5rem', sm: '5rem' }}
                                height={{ xs: '100%', sm: '5rem' }}
                                display="flex"
                                justifyContent={{ xs: 'center' }}>
                                <img
                                  src={gridItemImages[itemValue - 1][index]}
                                  alt={translate('')}
                                  className="h-full w-full"
                                />
                              </Box>
                              <Box
                                paddingX={{ xs: '.5rem', sm: '0' }}
                                display="flex"
                                width="100%"
                                height="100%"
                                flexDirection={{ xs: 'column' }}
                                justifyContent={{ xs: 'center', sm: 'stretch' }}
                                margin={{ sm: '1rem 0' }}
                                textAlign={{ sm: 'center' }}>
                                <Typography variant="body2">
                                  {translate(`PAYMENT_SECTION5_ITEM${itemValue}_GRID_ITEM${gridValue}`)}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box
                      display="flex"
                      justifyContent={{ xs: 'flex-start', sm: 'flex-start' }}
                      marginTop={{ xs: '3rem' }}>
                      <List component="ul">
                        {[1, 2, 3].map((listValue) => (
                          <ListItem
                            component="li"
                            className="p-0 p-b-1"
                            alignItems="center"
                            key={`listItem${listValue}`}>
                            <CircleIcon
                              className={classNames(classes.circleIcon, classes[CircleIconClass[itemValue - 1]])}
                            />
                            <ListItemText
                              primary={
                                <Typography variant="body1" align="left">
                                  {translate(`PAYMENT_SECTION5_ITEM${itemValue}_LIST_ITEM${listValue}`)}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Grid>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Box marginTop="4rem" paddingTop="5rem">
        <Typography variant="body1" align="center" className="m-b-2">
          <Link to="/services" className="no-underline fw-500 inline-flex">
            {translate('PAYMENT_SECTION2_ABOUT_SERVICES')}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

export default OnlinePayments;
