import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Button, Typography, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { TextField, textFieldValidations } from '../ReduxForm/TextField';
import TextFormatInput from '../ReduxForm/TextFormatInput';
import styles from '../../../modules/CloudInvoices/CloudInvoices.style';
import NumberFormat from 'react-number-format';
import { customDialogActions, paymentGatewayActions } from '../../store/common.actions';
import store from '../../../modules/store';
import { shallowEqual, useSelector } from 'react-redux';
import { useEffect } from 'react';

const validate = (values) => {
  const fields = {
    cardholderName: {
      value: values.cardholderName,
      required: true,
    },
    cardNumber: {
      value: values.cardNumber,
      required: true,
      isCardNumber: true,
    },
    expiryDate: {
      value: values.expiryDate,
      required: true,
      isExpiryDate: true,
    },
    cvv: {
      value: values.cvv,
      required: true,
      isNumber: true,
      minLength: 3,
    },
  };
  return textFieldValidations({ fields, removeFormat: true });
};

function paymentByGateway(props) {
  const initialValues = props.initialValues || {
    cardholderName: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
  };
  console.log('debug 1', initialValues);

  const GatewayForm = reduxForm({ form: 'paymentGateway', initialValues, validate })(PaymentGatewayForm);
  const {
    family,
    totalAmount,
    customFields,
    fees,
    subTotal,
    notificationInfo,
    UserId,
    associatedService,
    viewType = 'modal',
  } = props;
  function getAccType(cardNumber) {
    let [fd] = cardNumber;
    return (
      {
        3: 'AMEX',
        4: 'VISA',
        5: 'MASTERCARD',
        6: 'DISCOVER',
      }[fd] || 'UNKNOWN'
    );
  }

  const handlePayment = ({ cardholderName, cardNumber, expiryDate, cvv }) => {
    const account = cardNumber.replace(/_| /g, '');
    const [month, year] = expiryDate.split('/');
    const TotalDetails = { SubTotal: subTotal, Total: totalAmount, ...fees };
    const paymentInfo = {
      accttype: getAccType(account),
      account,
      expiry: '20' + year + month,
      amount: Number(totalAmount).toFixed(2),
      currency: 'USD',
      name: cardholderName,
      cvv2: cvv,
      capture: 'Y',
    };

    store.dispatch(
      paymentGatewayActions.makePayment({
        family,
        postData: {
          UserId,
          paymentInfo,
          TotalDetails,
          customFields,
          notificationInfo,
          associatedService,
        },
        form: { cardholderName, cardNumber, expiryDate, cvv },
      })
    );
  };

  if (viewType === 'modal') {
    store.dispatch({
      type: customDialogActions.OPEN_CUSTOM_DIALOG,
      payload: {
        title: 'Pay your invoice',
        content: <GatewayForm {...props} onSubmit={handlePayment} />,
      },
    });

    return '';
  }

  return <GatewayForm {...props} onSubmit={handlePayment} />;
}

function PaymentGatewayForm({ handleSubmit, valid, totalAmount, subTotal, fees }) {
  const { t: translate } = useTranslation();
  const useStyles = makeStyles(styles);
  const [loading, setLoading] = useState();
  const classes = useStyles();
  const paymentGatewayInfo = useSelector(({ paymentGatewayInfo }) => paymentGatewayInfo, shallowEqual);

  useEffect(() => {
    if (paymentGatewayInfo.CardConnectInfo) {
      setLoading(false);
    }
  }, [paymentGatewayInfo]);

  const onSubmit = (e) => {
    handleSubmit(e);
    setLoading(true);
  };

  return (
    <Box className="flex-col">
      <Grid container direction="column" alignItems="center" className={classes.grayBox} spacing={3}>
        <Grid item>
          <Typography variant="h3" className={`${classes.blueLabel} fw-500`}>
            <NumberFormat
              value={totalAmount}
              displayType={'text'}
              thousandSeparator={true}
              fixedDecimalScale={true}
              prefix={'US$ '}
              decimalScale={2}
            />
          </Typography>
        </Grid>

        <Grid item>
          <form onSubmit={onSubmit} autoComplete="off">
            <Box>
              <Grid container direction="column">
                <Field name="cardholderName" component={TextField} label="Cardholder Name" className="m-b-2" />
                <Field
                  className="m-b-2 w-100"
                  label="Card Number"
                  name="cardNumber"
                  isCard={true}
                  formatterProps={{
                    format: '#### #### #### ####',
                    mask: '_',
                  }}
                  component={TextFormatInput}
                />
                <Box display="flex" flexDirection="row">
                  <Field
                    className="m-r-8 flex"
                    label="Expiry date"
                    name="expiryDate"
                    placeholder="MM/YY"
                    formatterProps={{
                      format: '##/##',
                    }}
                    component={TextFormatInput}
                  />
                  <Field
                    className="flex"
                    label="CVV"
                    name="cvv"
                    placeholder="1234"
                    formatterProps={{
                      format: '####',
                    }}
                    component={TextFormatInput}
                  />
                </Box>
                <Box display="flex" justifyContent="center" padding="3rem">
                  <Button
                    variant="contained"
                    disabled={!valid || loading}
                    type="submit"
                    className={classes.greenGradient + ' fw-300 text-white'}
                  >
                    <Box minWidth="100px" className="flex justify-center">
                      {!loading && translate('PAY')}
                      {loading && <CircularProgress thickness={6} size={22} color="#fff" />}
                    </Box>{' '}
                  </Button>
                </Box>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
}

export { paymentByGateway };
