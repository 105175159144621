export const ScriptText = `
<div id="container-payment-button"></div>
<script src="{{host}}/SDK/OnlinePayment"></script>
<script>
    waoFlex.button.create({
    style:{
        color:'{{color}}',
        label:'{{text}}',
        border: '{{border}}',
        displayLogo: {{displayLogo}}
    },
    createOrder: function (actions) {
        return actions.order.create({
            amount: "1.00",
            id: "{{mid}}",
            deployment: "{{deployment}}",
        });
    },
    onApprove: function () {
        alert(
            "Transaction approved!"
        );
    },
    onDecline: function () {
        alert(
            "Transaction declined!"
        );
    }
    });
</script>
`