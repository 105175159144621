import HTTP from "../../common/services/HTTP.service";

let loginFactory = (function () {
  return ({
    loginByEmail: ({ email: Email, password: Password, recaptchaToken: RecaptchaToken }) => HTTP.post("web/Login", { Email, Password, RecaptchaToken }),
    loginBySMS: ({ phone: Phone, password: Password }) => HTTP.post("web/LoginbySMS", { Phone, Password }),
    checkTwoFACode: ({ userId, token }) => HTTP.post("web/v2/twofactor/CheckTwoFACode", { userId, token }),
    logout: ({ id, forced = false }) => HTTP.put(`web/Logout/${id}/${forced}`),
    resendEmail: ({ email: Email }) => HTTP.post(`web/mailManager?type=welcome`, { Email }),
    getVCode: ({ phone: Phone, email, method = "sms", options = {} }) => HTTP.post("web/GetVerificationCode", { Phone, email, Method: method, options }),
    checkVCode: ({ vCode, email }) => HTTP.post("web/CheckVCode", { vCode, email }),
    updatePass: data => HTTP.post("web/UpdatePass", data),
    setnewpass: (data) => HTTP.post(`web/setnewpass/${data.id}`, data),
  });
})();

export default loginFactory;