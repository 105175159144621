import axios from "axios";
import EventEmitter from "./EventEmitter.service";

let HTTP = (function() {
  //defaults
  const axiosInstance = axios.create({
    baseURL: window.location.origin.replace(/www./g, '')
  });

  //Interceptors
  axiosInstance.interceptors.request.use(request => {
    EventEmitter.broadCast("httpStart");
    if (request.method.match(/post|put/)) {
      request.data = request.data || {};
      request.data.DateTime =
        Date.now() - new Date().getTimezoneOffset() * 60 * 1000;
      request.data.TimeZoneOffset = new Date().getTimezoneOffset();
    }

    if (request.url.match(/v3\/promises/))
      request.headers.authorization = "ZmxleGlwb3xYzBE5Cg==";

    return request;
  });

  axiosInstance.interceptors.response.use(
    response => {
      EventEmitter.broadCast("httpEnd");
      return Promise.resolve(response.data);
    },
    err => {
      EventEmitter.broadCast("httpEnd");
      console.log(err, err.response);
      return Promise.reject(err.response ? err.response.data : err);
    }
  );

  return {
    get: url => axiosInstance.get(url).catch(Promise.reject),
    post: (url, postData) =>
      axiosInstance.post(url, postData).catch(err => Promise.reject(err)),
    put: url => axiosInstance.put(url).catch(err => Promise.reject(err))
  };
})();

export default HTTP;
