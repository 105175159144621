import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@material-ui/core';
import { Link } from '@material-ui/core';

import {
  vp_cloud,
  vp_check,
  vp_dashboard,
  vp_payments,
  vp_report,
  vp_staff,
  vp_tickets,
  vp_tipping,
  vp_vehicle,
} from '../img';

const borderDebug = false
  ? {
      yellow: { border: '2px solid yellow' },
      blue: { border: '2px solid blue' },
      pink: { border: '2px solid pink' },
      red: { border: '2px solid red' },
      green: { border: '2px solid green' },
      brown: { border: '4px solid brown' },
      grey: { border: '2px solid grey' },
    }
  : {};

function BoxItem({ item }) {
  function firstSentenceBold(text) {
    const [firstSentence, ...rest] = text.split('.');
    return (
      <span>
        <span className="text-bold"> {firstSentence}.</span>
        {rest.join('.')}
      </span>
    );
  }
  return (
    <Box
      className="flex flex-col"
      pr={{ sm: '1.5rem', md: '3.5rem', lg: '8rem' }}
      maxWidth={{ xs: '350px', sm: '100%' }}
      style={{ border: borderDebug.blue }}>
      <Box
        height={{ xs: '2.5rem', sm: '3rem' }}
        className="flex flex-row justify-start"
        mb={{ xs: '20px', sm: '15px' }}
        width="3.5rem">
        <img className="h-full w-full" src={item.icon} alt="vp_cloud" style={borderDebug.grey} />
      </Box>

      <Typography variant="body2" align="left" className="m-b-2">
        {firstSentenceBold(item.text)}
      </Typography>
    </Box>
  );
}

function Title() {
  const { t: translate } = useTranslation();

  return (
    <Box maxWidth="800px" id="Valet Parking">
      <Typography variant="h4" align="left" className="m-b-2 fw-300">
        {translate('SERVICES.VALET_PARKING')}
      </Typography>
      <Typography variant="body1" align="left" className="m-b-2">
        {translate('VALET_PARKING.REDEFINED')}
      </Typography>
      <Typography variant="body1" align="left">
        {translate('VALET_PARKING.ROBUST_POS_AND_WEB')}
      </Typography>
    </Box>
  );
}

function ValetParkingTab() {
  const { t: translate } = useTranslation();

  const featuresList = [
    {
      icon: vp_cloud,
      text: translate('VALET_PARKING.FULLY_SIZED'),
    },
    {
      icon: vp_check,
      text: translate('VALET_PARKING.CHECKIN_CHECKOUT'),
    },
    {
      icon: vp_vehicle,
      text: translate('VALET_PARKING.ADVANCED_VEHICLE'),
    },
    {
      icon: vp_report,
      text: translate('VALET_PARKING.DAMAGE_CLAIM'),
    },
    {
      icon: vp_payments,
      text: translate('VALET_PARKING.PAYMENT_PROCESSING'),
    },
    {
      icon: vp_tickets,
      text: translate('VALET_PARKING.DIGITAL_PARKING_TICKETS'),
    },
    {
      icon: vp_staff,
      text: translate('VALET_PARKING.STAFF_MANAGEMENT'),
    },
    {
      icon: vp_tipping,
      text: translate('VALET_PARKING.TIPPING'),
    },
    {
      icon: vp_dashboard,
      text: translate('VALET_PARKING.DASHBOARD_AND_REPORTING'),
    },
  ];

  function changeTab(tabId, serviceId) {
    document.getElementById(tabId).click();
    setTimeout(() => {
      const serv = document.getElementById(serviceId);
      window.scrollTo(0, serv.offsetParent.offsetTop + serv.offsetTop - 80);
    }, 50);
  }

  return (
    <div>
      <Grid container direction="column" alignContent="flex-start">
        <Grid item xs={12} className="p-t-5 p-b-5" style={borderDebug.blue}>
          <Title />
        </Grid>

        <Grid container direction="row" alignContent="flex-start" justify="space-between" className="p-b-16">
          {featuresList.map((feature, i) => {
            return (
              <Grid item xs={12} sm={5} md={4} lg={4} className="p-t-5 p-b-5" style={borderDebug.green} key={i}>
                <BoxItem item={feature} />
              </Grid>
            );
          })}
        </Grid>

        <Grid item xs={12} className="p-t-5 p-b-5" style={borderDebug.green}>
          <Typography variant="h4" align="center" className="m-b-2 fw-300">
            {translate('SERVICES.YOU_MAY_ALSO')}
          </Typography>
        </Grid>

        <Grid item xs={12} className="p-t-5 p-b-5 flex flex-col" style={borderDebug.green}>
          <Typography variant="body1" align="left" className="m-b-2 fw-500">
            <Link onClick={() => changeTab('Retail', 'Invoices')}>{translate('RETAIL.INVOICES')}</Link>
          </Typography>
          <Typography variant="body1" align="left" className="m-b-2 fw-500">
            <Link onClick={() => changeTab('Other', 'Staff Management')}>{translate('SERVICES.STAFF_MANAGEMENT')}</Link>
          </Typography>
          <Typography variant="body1" align="left" className="m-b-2 fw-500">
            <Link onClick={() => changeTab('Retail', 'Virtual Terminal')}>{translate('RETAIL.VIRTUAL_TERMINAL')}</Link>
          </Typography>
          <Typography variant="body1" align="left" className="m-b-2 fw-500">
            <Link onClick={() => changeTab('Restaurant', 'Tips')}>{translate('RETAIL.TIPS')}</Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default ValetParkingTab;
