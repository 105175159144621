import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import { Link } from '@material-ui/core';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import { tips, check } from '../img';

import { primaryColor } from '../../../../../common/styles/default.styles';
import CustomList from '../../../../../common/components/CustomList/CustomList';

const useStyles = makeStyles({
  listIcon: {
    color: primaryColor,
    fontSize: 15,
    marginRight: 5,
  },
});

const borderDebug = false
  ? {
      yellow: { border: '2px solid yellow' },
      blue: { border: '2px solid blue' },
      pink: { border: '2px solid pink' },
      red: { border: '2px solid red' },
      green: { border: '2px solid green' },
      brown: { border: '4px solid brown' },
      grey: { border: '2px solid grey' },
    }
  : {};

function ServiceSection({ service }) {
  const classes = useStyles();

  return (
    <Grid container direction="column" className="m-b-32" style={borderDebug.brown} id={service.title}>
      <Grid item xs={12} className="p-t-5 p-b-5" style={borderDebug.yellow}>
        <Typography variant="h3" align="left" className="m-b-2 fw-300">
          {service.title}
        </Typography>
      </Grid>

      <Grid item xs={12} className="flex flex-row" style={borderDebug.yellow}>
        <Grid container direction="row" alignContent="flex-start" alignItems="center">
          <Grid item xs={12} sm={5} style={borderDebug.pink}>
            <Box
              height={{ xs: '15rem', sm: '20rem' }}
              className="flex flex-row justify-center"
              mb={{ xs: '20px', sm: '0' }}>
              <img src={service.icon} alt="miniPos" style={{ height: '90%' }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} style={borderDebug.red}>
            <Box className="flex flex-col">
              <Typography variant="body1" align="left" className="m-b-2">
                {service.mainText}
              </Typography>
              <CustomList list={service.pointList} icon={<CircleIcon className={classes.listIcon} />} />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className="flex flex-col m-b-6" style={borderDebug.yellow}>
        <Box maxWidth={{ xs: '900px' }}>
          <CustomList
            list={service.checkList}
            icon={
              <Box height={{ xs: '1.5rem', sm: '2rem' }} mr="20px" my="10px">
                <img className="h-full" src={check} alt="check" />
              </Box>
            }
          />
        </Box>
      </Grid>
    </Grid>
  );
}

function Title() {
  const classes = useStyles();
  const { t: translate } = useTranslation();
  const mainList = [
    translate('RESTAURANT.DIFFERENT_TIPPING'),
    translate('RESTAURANT.ALL_MAJOR_CHIP_CARDS'),
    translate('RESTAURANT.AT_THE_TABLE_PAYMENTS'),
    translate('RESTAURANT.DIGITAL_PAYMENT_RECEIPTS'),
    translate('SERVICES.STAFF_MANAGEMENT'),
  ];

  return (
    <Box>
      <Typography variant="h4" align="left" className="m-b-2 fw-300">
        {translate('SERVICES.RESTAURANT')}
      </Typography>
      <Typography variant="body1" align="left">
        {translate('RESTAURANT.STREAM_LINE_PAYMENTS')}
      </Typography>
      <CustomList list={mainList} icon={<CircleIcon className={classes.listIcon} />} />
    </Box>
  );
}

function RestaurantTab() {
  const { t: translate } = useTranslation();

  const services = [
    {
      title: translate('RESTAURANT.TIPS'),
      icon: tips,
      mainText: translate('TIPS.MAIN_TEXT'),
      pointList: Array(4)
        .fill()
        .map((e, i) => translate(`TIPS.POINT_LIST${i + 1}`)),
      checkList: Array(4)
        .fill()
        .map((e, i) => translate(`TIPS.CHECK_LIST${i + 1}`)),
    },
  ];

  function changeTab(tabId, serviceId) {
    document.getElementById(tabId).click();
    setTimeout(() => {
      const serv = document.getElementById(serviceId);
      window.scrollTo(0, serv.offsetParent.offsetTop + serv.offsetTop - 80);
    }, 50);
  }

  return (
    <div>
      <Grid container direction="row" alignContent="flex-start">
        <Grid item xs={12} className="p-t-5 p-b-5" style={borderDebug.blue}>
          <Title />
        </Grid>

        <Grid item xs={12} className="p-t-5" style={borderDebug.green}>
          <Typography variant="h3" align="center" className="m-b-2 fw-300">
            {translate('RESTAURANT.RESTAURANT_SERVICES')}
          </Typography>
        </Grid>

        <Grid item xs={12} className="p-b-5" style={borderDebug.green}>
          {services.map((service, i) => {
            return <ServiceSection service={service} key={i} />;
          })}
        </Grid>

        <Grid item xs={12} className="p-t-5 p-b-5" style={borderDebug.green}>
          <Typography variant="h4" align="center" className="m-b-2 fw-300">
            {translate('SERVICES.YOU_MAY_ALSO')}
          </Typography>
        </Grid>

        <Grid item xs={12} className="p-t-5 p-b-5 flex flex-col" style={borderDebug.green}>
          <Typography variant="body1" align="left" className="m-b-2 fw-500">
            <Link onClick={() => changeTab('Retail', 'Cashier')}>{translate('RETAIL.CASHIER')}</Link>
          </Typography>
          <Typography variant="body1" align="left" className="m-b-2 fw-500">
            <Link onClick={() => changeTab('Other', 'Staff Management')}>{translate('SERVICES.STAFF_MANAGEMENT')}</Link>
          </Typography>
          <Typography variant="body1" align="left" className="m-b-2 fw-500">
            <Link onClick={() => changeTab('Valet Parking', 'Valet Parking')}>
              {translate('SERVICES.VALET_PARKING')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default RestaurantTab;
