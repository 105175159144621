import serverPromises from '../../../../common/factories/serverPromises.factory';
import loginFactory from '../../login.factory';

import { userActions, toasterActions } from '../../../../common/store/common.actions';
import * as logingActions from '../emailLogin/login.actions';

export const FORGOTPASS_RESTORE = 'FORGOTPASS_RESTORE';
export const UPDATE_PASS_SUCCESS = 'UPDATE_PASS_SUCCESS';
export const CHECK_VCODE_SUCCESS = 'CHECK_VCODE_SUCCESS';
export const SET_SHOWN_FORM = 'SET_SHOWN_FORM';

const handleError = (dispatch, error) => {
  return dispatch({
    type: toasterActions.SHOW_TOASTER_ERROR,
    payload: { text: error.message },
  });
};

export function forgotPassProcess({ email }) {
  return (dispatch) =>
    serverPromises
      .get({
        collection: 'Users',
        query: { Email: email },
        projection: 'Email Security Status',
      })
      .then(([user]) => {
        if (!user) {
          return dispatch({
            type: toasterActions.SHOW_TOASTER_ERROR,
            payload: { text: "User doesn't exist" },
          });
        }

        dispatch({
          type: userActions.SET_USER_INFO,
          payload: { ...user },
        });

        dispatch(logingActions.getVCode({ email: user.Email }, 'email'));
      })
      .catch((err) => handleError(dispatch, err));
}

export function checkVCode(data) {
  return (dispatch) =>
    loginFactory
      .checkVCode(data)
      .then(() => {
        return dispatch({
          type: CHECK_VCODE_SUCCESS,
        });
      })
      .catch((err) => handleError(dispatch, err));
}


export function updatePass(data) {
  return (dispatch) =>
    loginFactory
      .updatePass(data)
      .then(() => {
        dispatch({
          type: toasterActions.OPEN_TOASTER,
          payload: {
            type: 'success',
            title: 'Password updated',
            text: 'Your password was successfully changed',
            timeout: 8000,
          },
        });
        return dispatch({
          type: UPDATE_PASS_SUCCESS,
        });
      })
      .catch((err) => handleError(dispatch, err));
}

export function setnewpass(data) {
  return (dispatch) =>
    loginFactory
      .setnewpass(data)
      .then(() => {
        dispatch({
          type: toasterActions.OPEN_TOASTER,
          payload: {
            type: 'success',
            title: 'Your password has been set',
            text: 'Please login into your account, and see what we got for you!',
            timeout: 8000,
          },
        });
        return dispatch({
          type: UPDATE_PASS_SUCCESS,
        });
      })
      .catch((err) => handleError(dispatch, err));
}