import { toasterActions } from '../../../common/store/common.actions';
import serverPromises from '../../../common/factories/serverPromises.factory';
import { paymentByGateway } from '../../../common/components/PaymentGateway/PaymentGateway';

export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const RESTORE_INVOICE = 'RESTORE_INVOICE';
export const GET_ENTITY_SUCCESS = 'GET_ENTITY_SUCCESS';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';

export function getInvoice(id) {
  return (dispatch) =>
    serverPromises
      .getById({
        collection: 'Invoice',
        id,
      })
      .then((invoice) => {
        return serverPromises
          .get({
            collection: 'Entities',
            query: { UID: invoice.Family.split(':').pop() },
          })
          .then(([merchant]) => ({ invoice: { ...invoice, merchant } }));
      })
      .then(({ invoice }) => {
        if (!invoice) {
          return dispatch({
            type: toasterActions.SHOW_TOASTER_ERROR,
            payload: { text: "Invoice couldn't be found" },
          });
        }

        return dispatch({
          type: GET_INVOICE_SUCCESS,
          payload: { ...invoice },
        });
      })
      .catch((err) => {
        console.log(err);
        return dispatch({
          type: toasterActions.SHOW_TOASTER_ERROR,
          payload: {},
        });
      });
}

export function updateInvoice(id, update) {
  return (dispatch) =>
    serverPromises
      .updateOne({
        collection: 'Invoice',
        id,
        update,
      })
      .then((invoice) => {
        return dispatch({
          type: UPDATE_INVOICE_SUCCESS,
          payload: { ...invoice },
        });
      })
      .catch((err) => {
        console.log(err);
        return dispatch({
          type: toasterActions.SHOW_TOASTER_ERROR,
          payload: {},
        });
      });
}

export function payInvoce(cloudInvoice) {
  const {
    UserId,
    TotalAmount: totalAmount,
    Total: subTotal,
    CustomerInfo: { Email, Phone },
    ServiceFee,
    Discount,
    Shipping,
    Tax,
    Family: family,
  } = cloudInvoice;
  const notificationInfo = { Method: Email ? 'Email' : 'SMS', Email, Phone };

  // console.log('debug 1');
  paymentByGateway({
    UserId,
    family,
    totalAmount,
    subTotal,
    notificationInfo,
    associatedService: {
      Name: 'cloudinvoice',
      TicketId: cloudInvoice._id
    },
    fees: {
      ServiceFee,
      Discount,
      Shipping,
      Tax,
    },
  });
}
