import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Parallax, Heading, Contact, TopNavBar, Footer } from '../../components/index';
import { List, ListItem, ListItemText, Grid, Container, Box, Typography, Hidden } from '@material-ui/core';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import styles from './Dashboard.style';
import report from './img/report.svg';
import statistics from './img/statistics.svg';
import team from './img/team.svg';
import dashboard from './img/dashboard.svg';

const theme = 'light';
const useStyles = makeStyles(styles);

function Dashboard(props) {
  const { ...rest } = props;
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const images = [report, statistics, team],
    hrefButton = '/app/#/ContactOnboarding';
  return (
    <div>
      <TopNavBar
        color="transparentDark"
        theme={theme}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: 'white',
        }}
        {...rest}></TopNavBar>
      <Parallax filter title theme={'light'}>
        <div className={classes.headingContainer} style={{ color: '#505050' }}>
          <Heading
            title={translate('DASHBOARD.TITLE')}
            image="rocket"
            button={{ text: translate('DASHBOARD.HEADINGBUTTONTEXT'), href: hrefButton }}></Heading>
        </div>
      </Parallax>
      <div>
        <Box component="div" padding="3rem 0">
          <Container className={classNames(classes.main)} maxWidth="lg">
            <Grid container direction="column" justify="center" alignItems="stretch">
              <Grid item>
                <Typography variant="h3" gutterBottom align="center" className={'fw-300 ' + classes.textGray}>
                  {translate('DIVE_DEEPER_INTO_THE_DATA')}
                </Typography>
                <Typography variant="subtitle2" align="center" className="text-gray-500">
                  {translate('DASHBOARD.FLEXIPOS')}
                </Typography>
              </Grid>
              <Grid item>
                <Box position="relative">
                  <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Hidden only={['md', 'lg', 'xl']}>
                      <Grid item className="w-full">
                        <Box height={{ xs: 'auto', sm: '15rem' }}>
                          <img src={dashboard} alt="dashboard" className="h-full w-full" />
                        </Box>
                      </Grid>
                    </Hidden>
                    <Hidden only={['xs', 'sm']}>
                      <Grid item>
                        <Box
                          position="absolute"
                          top="0"
                          left="0"
                          padding={{ md: '3rem 0', lg: '5rem' }}
                          height={{ md: '18rem', lg: '20rem' }}>
                          <img src={dashboard} alt="report" className=" h-full w-auto" />
                        </Box>
                      </Grid>
                    </Hidden>
                    <Grid item xs>
                      <Box
                        display="flex"
                        padding={{ xs: '1rem 0', sm: '3rem 0', md: '.5rem 5rem 0 0', lg: '8rem 8rem 0 0' }}
                        justifyContent={{ xs: 'center', md: 'flex-end' }}>
                        <List component="ul">
                          {[1, 2, 3, 4].map((value) => (
                            <ListItem component="li" className="p-0" alignItems="center" key={value}>
                              <CircleIcon className={classNames(classes.aquaCircle)} />
                              <ListItemText
                                primary={
                                  <Typography variant="body2" className="text-gray-500">
                                    {translate(`SECTION1.LISTITEM${value}`)}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box component="div" padding={{ xs: '3rem 0', md: '10rem 0' }} style={{ background: '#f9f9f9' }}>
          <Container maxWidth="lg">
            <Grid container direction="column" justify="center" alignItems="stretch">
              <Grid item>
                <Typography variant="h3" gutterBottom align="center" className={'fw-300 ' + classes.textGray}>
                  {translate('FLEXIPOS_DASHBOARD_IS')}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" justify="space-between" alignItems="stretch" className="p-t-20">
                  <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                    {[1, 2, 3].map((index) => (
                      <Grid item xs key={index}>
                        <Grid
                          container
                          direction="column"
                          justify="flex-start"
                          alignItems="center"
                          className="text-center">
                          <Grid item>
                            <img src={images[index - 1]} alt="report" className="w-32 h-32 p-b-3" />
                          </Grid>
                          <Grid item>
                            <Typography variant="body1" gutterBottom align="center" className="text-bold text-gray-500">
                              {translate(`SECTION2.ITEMTITLE${index}`)}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              className={classNames(classes.grayItemText, classes.itemSubtitle)}
                              variant="body2"
                              align="center">
                              {translate(`SECTION2.ITEMSUBTITLE${index}`)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Dashboard;
