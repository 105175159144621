import * as Actions from './cloudInvoices.actions';

const initialState = {};

function invoiceReducer(state = initialState, { type, payload }) {
  switch (type) {
    case Actions.GET_INVOICE_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }

    case Actions.UPDATE_INVOICE_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }

    case Actions.RESTORE_INVOICE: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
}

export default invoiceReducer;
