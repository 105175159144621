import React from 'react';
import { Box, Grid, Button, Link, makeStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { TextField, textFieldValidations } from '../../../common/components/ReduxForm/TextField';
import { buttons } from '../../../common/styles/default.styles';

const useStyles = makeStyles(buttons);

const validate = (values) => {
  const fields = {
    email: {
      value: values.email,
      required: true,
      isEmail: true,
    },
    password: {
      value: values.password,
      required: true,
    },
  };
  return textFieldValidations({ fields });
};

function EmailLoginForm({ handleSubmit, valid, handleForgotPass }) {
  const { t: translate } = useTranslation();
  const classes = useStyles(buttons);
  return (
    <form onSubmit={handleSubmit}>
      <Box px={['10px', '30px']}>
        <Grid container direction="column">
          <Field name="email" component={TextField} label="Email" className="m-t-2 m-b-2" />
          <Field
            inputProps={{ type: 'password' }}
            name="password"
            component={TextField}
            label="Password"
            className="m-t-2 m-b-2"
          />
          <Box className="flex flex-row justify-between items-center m-t-5">
            <Link className="text-sm fw-400 cursor-pointer text-left" onClick={() => handleForgotPass()}>
              {translate('LOGIN.FORGOTPASS')}
            </Link>
            <Button variant="contained" disabled={!valid} type="submit" className={classes.primary + ' fw-300'}>
              <Box minWidth="100px">{translate('LOGIN.LOG_IN')}</Box>
            </Button>
          </Box>
        </Grid>
      </Box>
    </form>
  );
}

export default reduxForm({ form: 'emailLogin', validate })(EmailLoginForm);
