import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TopNavBar from '../../components/TopNavBar/TopNavBar';
import { Parallax } from '../../components/index';
import { container } from '../../../../common/styles/default.styles';
import Footer from '../../components/Footer/Footer';
import login from './img/login.svg';

const styles = {
  container: {
    zIndex: '12',
    color: '#FFFFFF',
    ...container,
  },
};

const theme = 'light';
const useStyles = makeStyles(styles);

export default function ThankYouPage(props) {
  const { t: translate } = useTranslation();
  const classes = useStyles(styles);
  return (
    <Box>
      <TopNavBar
        color="transparentLight"
        theme={theme}
        fixed
        hideRightLinks
        changeColorOnScroll={{
          height: 50,
          color: 'white',
        }}></TopNavBar>
      <Parallax filter title hideRounded theme={theme} height="100vh">
        <div className={classes.container} style={{ color: '#505050' }}>
          <Container maxWidth="md" className="m-t-32">
            <Grid container direction="column" justify="space-between" alignItems="center">
              <Grid item className="w-full">
                <Box
                  height={{ xs: '11rem', sm: '18rem', md: '19rem', xl: '28rem' }}
                  className="flex flex-row justify-center">
                  <img className="h-full" src={login} alt={'login'} />
                </Box>
              </Grid>
              <Grid item className="w-full">
                <Box maxWidth="800px" minWidth={{ xs: '250px', sm: '450px', md: '670px' }}>
                  <Typography variant="h2" gutterBottom align="center">
                    {translate('THANK YOU PAGE TITLE')}
                  </Typography>
                </Box>
                <Box maxWidth="800px" display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="subtitle1" gutterBottom align="center" className="m-b-8">
                    {translate('THANK YOU PAGE SUBTITLE')}
                  </Typography>
                  <Typography variant="body1" className="m-l-5" align="left">
                    <a className="no-underline fw-500" href="/">
                      {translate('BACK TO HOME')}
                    </a>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Parallax>
      <Footer hideSectionsLinks />
    </Box>
  );
}
