const EventEmitter = (function() {
  let events = {};

  return {
    broadCast: (event, data) => {
      if (!events[event]) return;
      events[event].forEach(cb => cb(data));
    },
    on: (event, cb) => {
      if (!events[event]) events[event] = [];
      events[event].push(cb);
    }
  };
})();

export default EventEmitter;