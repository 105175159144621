import React from 'react';
import { Box, Grid, Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import { textFieldValidations } from '../../../common/components/ReduxForm/TextField';
import TextFormatInput from '../../../common/components/ReduxForm/TextFormatInput';
import { buttons } from '../../../common/styles/default.styles';

const useStyles = makeStyles(buttons);

const validate = (values) => {
  const fields = {
    vcode: {
      value: values.token,
      required: true,
    },
  };
  return textFieldValidations({ fields });
};

function TwoFAForm({ handleSubmit, valid }) {
  const { t: translate } = useTranslation();
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <Box p={['10px', '30px']} border="solid #e4e4e4 1px" borderRadius="6px" style={{ background: '#f5fcff' }}>
        <Grid container direction="column">
          <Box className="fw-500 text-xl">{translate('LOGIN.ENTER_ONE_TIME_PWD')}</Box>
          <Box className="fw-400 text-sm">{translate('LOGIN.ENTER_ONE_TIME_PWD_DESCRIPTION')}</Box>
          <Field
            className="m-r-8 flex m-t-2 m-b-2"
            label={translate('LOGIN.ENTER_ONE_TIME_PWD_LABEL')}
            name="token"
            placeholder="123 456"
            formatterProps={{
              format: '### ###',
            }}
            component={TextFormatInput}
          />
          <Box className="flex flex-row justify-end items-center m-t-5">
            <Button variant="contained" disabled={!valid} type="submit" className={classes.primary + ' fw-400'}>
              <Box minWidth="100px">{translate('LOGIN.CONFIRM')}</Box>
            </Button>
          </Box>
        </Grid>
      </Box>
    </form>
  );
}

export default reduxForm({ form: 'twoFAForm', validate })(TwoFAForm);
