import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// core components
import styles from './Parallax.style';

export default function Parallax(props) {
  const useStyles = makeStyles(styles(props.height, props.customParallaxStyles, props.roundedBorderTopPosition));

  const { filter, className, children, small } = props;
  const classes = useStyles();
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes.filter]: filter,
    [classes.small]: small,
    [className]: className !== undefined,
  });
  return (
    <div className={parallaxClasses + ' flex flex-col justify-center'}>
      {children}
      {!props.hideRounded && <Box className={classes.roundedBg}></Box>}
    </div>
  );
}

Parallax.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.string,
  image: PropTypes.string,
  small: PropTypes.bool,
};
