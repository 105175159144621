import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Container, Box, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './DiagonalContainer.style';
import rocket from './img/rocket.svg';
import rocket2 from './img/rocket2.svg';

function DiagonalContainer() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { t: translate } = useTranslation();
  return (
    <Box paddingY="3rem">
      <Box component="div" className={classes.diagonalBorder}>
        <Container className={classes.container}>
          <Box>
            <Grid
              style={{ position: 'relative' }}
              container
              direction="row"
              justify="space-between"
              alignItems="stretch"
              className={classes.diagonalBorderContainer}>
              <Hidden mdUp>
                <Grid item md className={classes.maskedElement}>
                  <Box display="flex" justifyContent="center">
                    <img src={rocket2} alt="rocket-img" style={{ height: '11rem' }} />
                  </Box>
                </Grid>
              </Hidden>
              <Grid md={7} item container direction="column" justify="flex-start" alignItems="stretch">
                <Box
                  display="flex"
                  flexDirection="column"
                  textAlign={{ xs: 'center', md: 'left' }}
                  alignItems={{ xs: 'center', md: 'normal' }}
                  paddingY={{ xs: '8rem', md: '5rem' }}
                  paddingBottom={{ xs: '6rem', md: '2.5rem' }}>
                  <Typography variant="h4" align="inherit" gutterBottom className="fw-500 text-uppercase">
                    {translate('ROCKET_SECTION_TITLE')}
                  </Typography>
                  <Typography variant="body1" align="inherit" gutterBottom className="m-t-3">
                    {translate('ROCKET_SECTION_PARAGRAPH1')}
                  </Typography>
                  <Typography variant="body1" align="inherit" gutterBottom className="m-t-3">
                    {translate('ROCKET_SECTION_PARAGRAPH2')}
                  </Typography>
                </Box>
              </Grid>
              <Hidden smDown>
                <Grid item md className={classes.maskedElement}>
                  <img src={rocket} alt="rocket-img" />
                </Grid>
              </Hidden>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default DiagonalContainer;
