import React, { useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Tooltip,
  ClickAwayListener,
  IconButton,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import { TextField, textFieldValidations } from '../../../common/components/ReduxForm/TextField';
import { buttons } from '../../../common/styles/default.styles';

const useStyles = makeStyles({
  ...buttons,
  customWidth: {
    maxWidth: '300px',
  },
});

const validate = (values) => {
  const fields = {
    password: {
      value: values.password,
      required: true,
      isNewPassword: true,
      minLength: 8,
    },
    confirmation: {
      value: values.confirmation,
      required: true,
      equalTo: {
        field: 'password',
        error: 'Password must match',
      },
    },
  };
  return textFieldValidations({ fields });
};

function NewPassForm({ handleSubmit, valid }) {
  const { t: translate } = useTranslation();
  const classes = useStyles(buttons);
  const [openTooltip, setOpenTooltip] = useState(false);

  const tooltipText = (
    <Box className="flex flex-col items-start p-1" fontSize="12px">
      <span className="p-b-1">{translate('LOGIN.ATLEAST_LOWERCASE')}</span>
      <span className="p-b-1">{translate('LOGIN.ATLEAST_UPPERCASE')}</span>
      <span className="p-b-1">{translate('LOGIN.ATLEAST_NUMERIC')}</span>
      <span className="p-b-1">{translate('LOGIN.ATLEAST_SPECIAL')}</span>
      <span className="p-b-1">{translate('LOGIN.ATLEAS_8CHARACTERS')}</span>
    </Box>
  );

  function HelpAdornment() {
    return (
      <InputAdornment position="end">
        <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
          <div>
            <Tooltip
              classes={{ tooltip: classes.customWidth }}
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => setOpenTooltip(false)}
              open={openTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={tooltipText}>
              <IconButton
                className="text-gray-700"
                aria-label="help"
                component="span"
                onClick={() => setOpenTooltip(true)}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </InputAdornment>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box px={['10px', '30px']}>
        <Grid container direction="column" justify="flex-start">
          <Field
            inputProps={{ type: 'password' }}
            name="password"
            component={TextField}
            label="New password"
            className="m-t-2 m-b-2"
            InputProps={{
              endAdornment: <HelpAdornment />,
            }}
          />
          <Field
            inputProps={{ type: 'password' }}
            name="confirmation"
            component={TextField}
            label="Confirm new password"
            className="m-t-2 m-b-2"
          />

          <Box className="flex flex-row justify-end items-center m-t-5">
            <Button variant="contained" disabled={!valid} type="submit" className={classes.primary + ' fw-300'}>
              <Box minWidth="100px">{translate('LOGIN.SEND')}</Box>
            </Button>
          </Box>
        </Grid>
      </Box>
    </form>
  );
}

export default reduxForm({ form: 'newPassForm', validate })(NewPassForm);
