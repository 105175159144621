import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, Typography, Hidden } from '@material-ui/core';
import Phill from './img/Phill.png';
import emailIcon from './img/emailIcon.svg';
import phoneIcon from './img/phoneIcon.svg';
import planeA from './img/planeA.svg';
import planeB from './img/planeB.svg';
import { grayBodyColor } from '../../../../common/styles/default.styles';
import { makeStyles } from '@material-ui/core/styles';
import topNavBarStyles from '../../components/TopNavBar/TopNavBar.style';

function Contact({ background = '#fff', dropdownSection = false, noPadding }) {
  const useStyles = makeStyles(topNavBarStyles);
  const classes = useStyles();
  const { t: translate } = useTranslation();
  return (
    <Box
      component="div"
      style={{ background }}
      padding={dropdownSection ? '0' : { xs: '3rem 0', sm: '3rem 0', md: '5rem 0', lg: '5rem 7rem' }}
      paddingX={dropdownSection ? '0' : { sm: '1.5rem', md: 0 }}
      marginLeft={dropdownSection ? '-1.5rem' : '0'}
      position="relative">
      <Container className={classes.container}>
        {!dropdownSection ? (
          <div>
            <Box
              position="absolute"
              width={{ xs: '5rem', md: '8rem', lg: '10rem' }}
              right={{ xs: '5rem', sm: '10rem', lg: '20rem' }}
              top={{ xs: '-2rem', sm: '-3rem', lg: '-6rem' }}>
              <img src={planeA} alt="planeA" />
            </Box>
            <Box
              position="absolute"
              width={{ xs: '5rem', md: '8rem', lg: '10rem' }}
              left={{ xs: '5rem', sm: '6rem', md: '10rem' }}
              bottom={{ xs: '-2rem', lg: '-4rem' }}>
              <img src={planeB} alt="planeB" />
            </Box>
          </div>
        ) : (
          ''
        )}
        <Grid container direction="column" justify="center" alignItems="stretch">
          {!dropdownSection && (
            <Grid item>
              <Box marginY="3rem">
                <Typography
                  variant="h3"
                  component="h2"
                  align="center"
                  gutterBottom
                  className="fw-300"
                  style={{ color: grayBodyColor }}>
                  {translate('WE_ARE_HERE_FOR_YOU')}
                </Typography>
                <Hidden smUp>
                  <Box display="flex" justifyContent="center">
                    <img src={Phill} alt="phill" className="h-64 p-t-4 p-b-4" />
                  </Box>
                </Hidden>
                <Typography variant="subtitle2" align="center" className="text-gray-500">
                  {translate('CONTACT_SECTION_SUBTITLE')}
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item container direction="row" justify="space-evenly" alignItems="stretch">
            <Grid
              item
              container
              direction={dropdownSection ? 'row' : 'column'}
              justify="flex-start"
              alignItems="stretch"
              className="m-l-5"
              xs>
              <Grid
                sm={dropdownSection ? 5 : true}
                item
                container
                direction={!dropdownSection ? 'row' : 'column'}
                alignItems={!dropdownSection ? 'center' : 'flex-start'}
                justify="center"
                className="text-gray-500">
                <Grid
                  item
                  xs={dropdownSection ? 12 : 2}
                  sm={2}
                  className={dropdownSection ? 'flex' : 'flex justify-end'}>
                  <img src={emailIcon} alt="emailIcon" className="h-14" />
                </Grid>
                <Grid item xs>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems={{ xs: dropdownSection ? 'flex-start' : 'center', sm: 'flex-start' }}
                    textAlign={{ xs: dropdownSection ? 'left' : 'center', sm: 'left' }}
                    padding={dropdownSection ? '1rem 0' : '1rem'}>
                    <Typography variant="body1" className="text-bold" align="center">
                      {translate('EMAIL_US')}
                    </Typography>
                    <Typography variant="body1" style={{ lineHeight: 'normal' }}>
                      {translate('EMAIL_US_SUBTITLE')}
                    </Typography>
                    <Typography variant="body1" className="text-bold" align="center">
                      {translate('EMAIL_ADDRESS')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                sm={dropdownSection ? 5 : true}
                item
                container
                direction={!dropdownSection ? 'row' : 'column'}
                alignItems={!dropdownSection ? 'center' : 'flex-start'}
                className="text-gray-500">
                <Grid
                  item
                  xs={dropdownSection ? 12 : 2}
                  sm={2}
                  className={dropdownSection ? 'flex' : 'flex justify-end'}>
                  <img src={phoneIcon} alt="phoneIcon" className="h-14" />
                </Grid>
                <Grid item xs>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems={{ xs: dropdownSection ? 'flex-start' : 'center', sm: 'flex-start' }}
                    textAlign={{ xs: dropdownSection ? 'left' : 'center', sm: 'left' }}
                    padding={dropdownSection ? '1rem 0' : '1rem'}>
                    <Typography variant="body1" className="text-bold" align="center">
                      {translate('GIVE_US_A_CALL')}
                    </Typography>
                    <Typography variant="body1" style={{ lineHeight: 'normal' }}>
                      {translate('GIVE_US_A_CALL_SUBTITLE')}
                    </Typography>
                    <Typography variant="body1" className="text-bold" align="center">
                      {translate('PHONE_NUMBER')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid item sm={dropdownSection ? 12 : false}>
                <Typography variant="body1" align="left">
                  <a className="no-underline fw-500" href="/app/#/ContactOnboarding">
                    {translate('GET_IN_TOUCH_WITH_FLEXIPOS')}
                  </a>
                </Typography>
              </Grid>
            </Grid>
            {!dropdownSection && (
              <Hidden xsDown>
                <Grid item xs>
                  <Box height={{ xs: '16rem', md: '25rem' }} display="flex" justifyContent={{ xs: 'flex-end' }}>
                    <img src={Phill} alt="phill" className="w-auto h-full" />
                  </Box>
                </Grid>
              </Hidden>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Contact;
