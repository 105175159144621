import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Container, Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import laptop from './img/laptop.png';
import POS from './img/POS.png';
import puzzle from './img/pluzze.png';

function Services() {
  const { t: translate } = useTranslation();
  const items = [
    {
      src: POS,
      alt: 'D200T',
      width: { xs: '100%' },
      height: { xs: '15rem', sm: '23rem', md: '20rem', lg: '23rem' },
      paddingText: { lg: '0 10rem 0 0', xl: '0 20rem 0 0' },
      link: '/payments',
    },
    {
      src: puzzle,
      alt: 'puzzle',
      width: { xs: '100%' },
      height: { xs: '10rem', sm: '15rem', md: '15rem' },
      paddingText: { lg: '0 0 0 10rem', xl: '0 0 0 20rem' },
      link: '/services',
    },
    {
      src: laptop,
      alt: 'laptop',
      width: { xs: '100%' },
      height: { xs: '10rem', sm: '15rem', md: '16rem' },
      paddingText: { lg: '0 5rem', xl: '0 20rem 0 0' },
      link: '/dashboard',
    },
  ];

  return (
    <Box component="div" padding={{ xs: '3rem 0', sm: '3rem 0', md: '5rem 0', lg: '5rem 7rem' }}>
      <Container maxWidth="lg">
        <Grid container direction="column" justify="center" alignItems="stretch">
          {items.map(({ src, alt, width, height, paddingText, link }, key) => (
            <Grid item key={key}>
              <Grid
                container
                direction={key % 2 ? 'row-reverse' : 'row'}
                justify="flex-start"
                alignItems="center"
                spacing={5}>
                <Grid item xs={12} md={4} lg={4}>
                  <Box
                    display="flex"
                    justifyContent={{ xs: 'center', md: `${key % 2 ? 'flex-start' : 'flex-end'}` }}
                    width={width}
                    height={height}
                    padding={{ xs: '2rem 0 1rem 0' }}>
                    <img src={src} alt={alt} className="h-full" />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5} lg>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={{ xs: 'center', md: 'stretch' }}
                    textAlign={{ xs: 'center', md: `${key % 2 ? 'right' : 'left'}` }}
                    p={paddingText}>
                    <Typography style={{ lineHeight: 'normal' }} variant="h5" className="fw-300">
                      {translate(`SERVICES_ITEM${key + 1}_TITLE`)}
                    </Typography>
                    <Typography variant="body2" className="p-t-3 text-gray-500">
                      {translate(`SERVICES_ITEM${key + 1}_DESCRIPTIION`)}
                    </Typography>
                    <Typography variant="body1" className="p-t-3">
                      <Link replace to={link} className="no-underline fw-500">
                        {translate(`SERVICES_ITEM${key + 1}_LINK`)}
                      </Link>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Services;
