const parallaxStyle = (height = 'auto', customParallaxStyles = {}, roundedBorderTopPosition) => {
  return () => ({
    parallax: {
      maxHeight: '1000px',
      overflow: 'hidden',
      position: 'relative',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      margin: '0',
      padding: '0',
      border: '0',
      display: 'flex',
      alignItems: 'center',
      height,
      ...customParallaxStyles,
    },
    filter: {
      '&:after,&:before': {
        position: 'absolute',
        zIndex: '-1',
        width: '100%',
        height: '100%',
        display: 'block',
        left: '0',
        top: '0',
        content: "''",
      },
    },
    small: {
      height: '380px',
    },
    roundedBg: {
      width: '150%',
      background: 'white',
      zIndex: -1,
      height: '150%',
      position: 'absolute',
      borderRadius: '50%',
      border: '4px solid #3cd52e',
      top: roundedBorderTopPosition || '70%',
      '&:after': {
        position: 'absolute',
        content: "''",
        width: '106%',
        height: '100%',
        borderRadius: '50%',
        background: "white",
        right: '-3%',
        top: '1%'
      }
      // [theme.breakpoints.only('xs')]: {
      //   clipPath: 'circle(1000px at 50% 1000px)',
      // },
      // [theme.breakpoints.only('sm')]: {
      //   clipPath: 'circle(1000px at 50% 1000px)',
      // },
      // [theme.breakpoints.only('md')]: {
      //   clipPath: 'circle(1830px at 50% 1920px)',
      //   top: '62%',
      // },
      // [theme.breakpoints.only('lg')]: {
      //   clipPath: 'circle(2600px at 50% 2600px)',
      //   top: '70%',
      // },
      // [theme.breakpoints.only('xl')]: {
      //   clipPath: 'circle(3200px at 50% 3200px)',
      //   top: '73%',
      // },
    },
  });
};

export default parallaxStyle;


