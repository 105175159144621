export default {
	'TITLE': 'Merchant services and credit card processing for in-store and online sales',
	'SUBTITLE': 'Payment processing solutions for small and medium-sized businesses.',
	ASK_US_ANYTHING: "Ask us anything",
	APPLY_NOW: "Apply now",
	'CONTACT_OUR_SALES_TEAM': 'Contact our sales team',
	'ROCKET_SECTION_TITLE': 'Grow your entire business with tailored-made apps',
	'ROCKET_SECTION_PARAGRAPH1': 'We want to help you get more done with less effort. Our	payment software and hardware systems are built to meet all merchants needs. No matter what you’re selling or what stage you’re at, we’ve got you covered.',
	'ROCKET_SECTION_PARAGRAPH2': 'Take a look at our powerful tools and learn more on how	we can boost your business.',
	'BUSINESS_TOOLS_TITLE': 'Sync with top-notch business tools',
	'BUSINESS_TOOLS_SUBTITLE': 'WaoFlex brings together everything you need to streamline your business operation and sync with other accounting systems by exporting your sales records.',
	'SERVICES_ITEM1_TITLE': 'Accept payments quickly, secure, and hassle-free.',
	'SERVICES_ITEM1_DESCRIPTIION': 'From taking payments in person, online, or via invoices, to building your own solution with our payment platform.',
	'SERVICES_ITEM2_TITLE': 'All the right tools in one place.',
	'SERVICES_ITEM2_DESCRIPTIION': 'WaoFlex is the turn-key solution for every type of business like Valet Parking, Retail, Restaurant and Car Wash. It is easily customizable and all synced with the entire platform.',
	'SERVICES_ITEM3_TITLE': 'Go further with financial dashboards and reporting.',
	'SERVICES_ITEM3_DESCRIPTIION': 'Analyze the context of your transactions with no complex charts. Reduce administrative paperwork by exporting your data in the most common formats.',
	'PAYMENT_PROCESSING_TITTLE': 'Payment processing made easy',
	'PAYMENT_PROCESSING_SUBTITTLE': 'WaoFlex devices have the purpose to make mobile payment processing a breeze. Accept any kind of payment at any time.',
	'D200T_DESCRIPTION': 'Compactly designed for accepting payments on the go.',
	'D220_DESCRIPTION': 'Built for comfort and speed in every transaction.',
	'Q80_DESCRIPTION': 'Traditional and reliable. Printer-equipped.',
	'S920_DESCRIPTION': 'Portability plus robustness in one device.',
	'SPECIFICATION': 'Specification ›',
	'3D_OVERVIEW': '3D overview ›',
	'SERVICES_ITEM1_LINK': 'Learn about payments ›',
	'SERVICES_ITEM2_LINK': 'Start selling more with WaoFlex >',
	'SERVICES_ITEM3_LINK': 'Dive deeper into your business ›',

}