import React, { useState, useEffect } from 'react';
import { ButtonPreview, ButtonStyle } from '@net247lib/react-net247-library';
import serverPromisesFactory from '../../../common/factories/serverPromises.factory';
import { Grid, Typography, makeStyles, Box, Button } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { ScriptText } from './ScriptRenderButton';

import greenDarkLogo from '../img/greenDark.svg';
import greenWhiteLogo from '../img/greenWhite.svg';
import whiteLogo from '../img/white.svg';
import whiteDarkLogo from '../img/whiteDark.svg';
import { OPEN_TOASTER } from '../../../common/components/Toaster/toaster.actions';
import { useDispatch } from 'react-redux';
import useQuery from '../../../common/hooks/useQuery';
//4036
const useStyles = makeStyles({
  container: {
    color: '#5b5b5f',
  },
  box: {
    border: '2px solid #e7eaec',
    padding: '30px',
  },
  containerPreview: {
    marginTop: 10,
    padding: '10px 20px',
    borderRadius: 5,
    background: '#f1f1f18f',
    '& .title': {
      color: 'rgba(86, 103, 131, 0.38)',
    },
  },
  buttonCopy: {
    borderRadius: '24px',
    color: '#fff',
    background: '#3cd52e',
  },
});

const palette = [
  {
    name: 'green',
    background: '#3cd52e',
    border: '#3cd52e',
    label: 'Green',
    src: whiteDarkLogo,
    color: '#4a4a56',
  },
  {
    name: 'white',
    background: '#ffffff',
    border: '#5b5b5f',
    label: 'White',
    src: greenDarkLogo,
    color: '#5b5b5f',
  },
  {
    name: 'dark',
    background: '#4a4a56',
    border: '#4a4a56',
    label: 'Dark',
    src: greenWhiteLogo,
    color: '#ffffff',
  },
  {
    name: 'gradient-dark',
    background: 'linear-gradient(-45deg, #3cd52e,#06aac3)',
    border: 'none',
    label: 'Dark Gradient',
    src: whiteDarkLogo,
    color: '#4a4a56',
  },
  {
    name: 'gradient-white',
    background: 'linear-gradient(-45deg, #3cd52e,#06aac3)',
    border: 'none',
    label: 'White Gradient',
    src: whiteLogo,
    color: '#ffffff',
  },
];

export default function PaymentButtonConfiguration() {
  const formRef = React.useRef();
  const [merchant, setMerchant] = useState();
  const urlParams = useQuery();
  const classes = useStyles();
  const [isFormValid, setIsFormValid] = useState(false);
  const dispatch = useDispatch();
  const [customization, setCustomization] = useState({
    text: 'Pay with',
    color: 'white',
    tender: null,
    displayLogo: true,
    language: 'en',
    backgroundType: 'solid',
    backgroundValue: '#4C5CC3',
    fileId: '',
    url: '',
    logoSize: 'medium',
    border: 'normal',
    logoPosition: 'left',
    hasLogo: false,
    primaryColor: '#4C5CC3',
    secondaryColor: '#EAECF8',
    textColor: '#566783',
    hasName: true,
    methods: ['creditCard'],
    deployment: 'popup',
  });

  const { displayLogo, color, border, text } = customization;
  const family = urlParams.get('family');
  console.log(family);

  useEffect(() => {
    console.log(22222);
    serverPromisesFactory
      .get({ collection: 'Entities', query: { Family: family } })
      .then(([_merchant]) => {
        setMerchant(_merchant);
        console.log(_merchant);
      })
      .catch((err) => {
        console.log('error', err);
      });
  }, [family]);

  const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const openToaster = (data) => {
    dispatch({
      type: OPEN_TOASTER,
      payload: data,
    });
  };

  function onChangeButtonStyle(e) {
    setCustomization({ ...customization, ...e });
  }

  const validateForm = async () => {
    const isValid = formRef.current ? await formRef.current.isFormValid() : false;

    if (!isValid) {
      openToaster({
        type: 'warning',
        text: 'Validate the required fields',
      });
    }
    setIsFormValid(isValid);
  };

  const handleClick = () => {
    let token = btoa(merchant.Family);

    let dataText = {
      ...customization,
      mid: token,
      host: /localhost/.test(window.location.origin) ? 'http://localhost:5001' : window.location.origin,
      language: 'en',
    };

    const dataValue = {
      primary: customization.primaryColor === '' ? '#4C5CC3' : customization.primaryColor,
      secondary: customization.secondaryColor === '' ? '#EAECF8' : customization.secondaryColor,
      text: customization.textColor === '' ? '#566783' : customization.textColor,
      hasName: customization.hasName,
      background: {
        type: customization.backgroundType,
        value: customization.backgroundValue,
      },
      logo: {
        enabled: customization.hasLogo,
        value: customization.fileId,
        size: customization.logoSize,
        position: customization.logoPosition,
      },
      buttons: {
        border: customization.border,
      },
    };

    dataText = {
      ...dataText,
      dataValue: JSON.stringify(dataValue),
      dataName: 'interface',
    };

    let text = ScriptText;

    Object.keys(dataText).forEach((key) => {
      let regex = new RegExp(`{{${key}}}`, 'g');
      text = text.replace(regex, dataText[key]);
    });

    copyToClipboard(text);

    openToaster({
      type: 'success',
      text: 'Copied to clipboard',
      timeout: 2000,
      position: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    });
  };

  //return 'test'

  return (
    <ValidatorForm
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();
        if (isFormValid) {
          handleClick();
        }
      }}
    >
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography className='fw-500 m-t-12 m-b-10' variant='h5'>
            Customize your checkout button
          </Typography>
          <Typography className='fs-14 m-b-25'>
            Change the appearance of the checkout button in your website.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.box}>
          <ButtonStyle onChange={onChangeButtonStyle} lang={'en'} palette={palette} />
          <Box className={classes.containerPreview} p={1}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className='title'>Preview</Typography>
              </Grid>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='center' my={3}>
                  <ButtonPreview
                    displayLogo={displayLogo}
                    color={color}
                    border={border}
                    text={text}
                    palette={palette}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box p={3} display='flex' justifyContent='center'>
            <Button
              type='submit'
              size='large'
              variant='contained'
              className={classes.buttonCopy + ' MuiButton-containedPrimary'}
              onClick={validateForm}
            >
              Copy code
            </Button>
          </Box>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}
