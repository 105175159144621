/*eslint-disable*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

// core components
import Button from '../../../../common/components/CustomButtons/Button';

import styles from './TopNavBarLinks.style';
import { Link as ScrollLink } from 'react-scroll';

function TopNavBarLinks(props) {
  const { list = [], theme = 'light' } = props;
  const useStyles = makeStyles(styles(theme));
  const classes = useStyles();
  const { t: translate } = useTranslation();

  const buildListItem = {
    button: (e) => (
      <a href={e.href} className="no-underline">
        <Button
          size="lg"
          fullWidth
          round
          color="primary"
          target="_blank"
          variant="contained"
          className={`${classes.navButton} ${classes.link} ${e.customClasses || ''}`}>
          {e.icon} {translate(e.label)}
        </Button>
      </a>
    ),
    sectionLink: (e) => (
      <ScrollLink
        className={`${classes.navLink} ${classes.link}`}
        activeClass={classes.navLinkActive}
        to={e.section}
        spy={true}
        smooth={true}
        duration={500}>
        {e.icon} {e.label}
      </ScrollLink>
    ),
    link: (e) => (
      <Link to={e.href} className={`${classes.navLink} ${classes.link} no-underline ${e.customClasses}`}>
        {e.icon} {translate(e.label)}
      </Link>
    ),
    a: (e) => (
      <a
        href={e.href}
        rel="noopener noreferrer"
        className={`${classes.navLink} ${classes.link} no-underline ${e.customClasses}`}>
        {e.icon} {translate(e.label)}
      </a>
    ),
  };

  function _buildList() {
    return list.map((e, index) => {
      return (
        <ListItem className={classes.listItem} key={index}>
          {buildListItem[e.type](e)}
        </ListItem>
      );
    });
  }

  return <List className={classes.list}>{_buildList()}</List>;
}

export default TopNavBarLinks;
