import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Parallax, Heading, TopNavBar, Footer } from '../../components/index';
import TopNavBarLinks from '../../components/TopNavBar/TopNavBarLinks';
import styles from './Payments.style';
import {
  FirstSection,
  InStorePayments,
  OnlinePayments,
  PaymentAPI,
  PaymentBrand,
  ProperlyAndFeatures,
} from './Sections/index';

const useStyles = makeStyles(styles);

function Payments(props) {
  const { ...rest } = props;
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const hrefButton = '/app/#/ContactOnboarding';
  const theme = 'dark';

  const leftList = [
    {
      label: 'IN-STORE PAYMENTS',
      type: 'sectionLink',
      section: 'seccion2',
      component: (
        <div>
          <InStorePayments /> <ProperlyAndFeatures /> <PaymentBrand displayLink={false} />
        </div>
      ),
    },
    {
      label: 'E-PAYMENTS',
      type: 'sectionLink',
      section: 'seccion3',
      component: <OnlinePayments />,
    },
    {
      label: 'API',
      type: 'sectionLink',
      section: 'seccion4',
      component: <PaymentAPI />,
    },
  ];
  return (
    <div>
      <TopNavBar
        color="transparentLight"
        theme="dark"
        fixed
        leftLinks={<TopNavBarLinks theme={theme} list={leftList} />}
        changeColorOnScroll={{
          height: 70,
          color: 'white',
        }}
        {...rest}></TopNavBar>
      <Parallax filter title theme="light">
        <div className={classes.headingContainer} style={{ color: '#505050' }}>
          <Heading
            title={translate('PAYMENT_MAIN_TITLE')}
            theme="dark"
            image="planet"
            button={{ text: translate('HEADINGBUTTONTEXT'), href: hrefButton }}></Heading>
        </div>
      </Parallax>
      <div>
        <FirstSection />
        {leftList.map((elem) => (
          <div name={elem.section} key={elem.section}>
            {elem.component}
          </div>
        ))}
        <Footer />
      </div>
    </div>
  );
}

export default Payments;
