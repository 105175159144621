export default {
	"PAYMENT_MAIN_TITLE": "Thrive in a world of rapidly changing payment technology",
	"HEADINGBUTTONTEXT": "Start taking payments",
	"PAYMENT_SECTION1_TITLE": 'WaoFlex allows you to take every payment that comes your way',
	"PAYMENT_SECTION1_ITEM1_TITLE": "The payment platform engineered for growth",
	"PAYMENT_SECTION1_ITEM1_PARAGRAPH": "Both online and in-person procedures can come together	and interface perfectly. Learn all the ways you can	combine both your WaoFlex devices with your online operations and unify customer payouts across channels.",
	"PAYMENT_SECTION1_ITEM2_TITLE": "Smart solutions for your business",
	"PAYMENT_SECTION1_ITEM2_PARAGRAPH": "Smart solutions for your business: Whether you are a restaurant, a delivery service, or something even more specific —like a ticket selling marketplace— you may find the perfect tool to make your business more efficient.",

	"PAYMENT_SECTION2_TITLE1": "In-store payments",
	"PAYMENT_SECTION2_SUBTITLE1": "Our robust POS software makes traditional payment processing a snap.",
	"PAYMENT_SECTION2_TITLE2": "Allow your customers to pay however they like",
	"PAYMENT_SECTION2_SUBTITLE2": "Accept any type of technology.",
	"PAYMENT_SECTION2_TITLE3": "Pick up the tools you need",
	"PAYMENT_SECTION2_SUBTITLE3": "As a merchant, you need more than just payment processing. We've built several features for specific types of merchants",
	"PAYMENT_SECTION2_ABOUT_SERVICES": "Learn more about our services >",
	"CONTACLESS": "Contactless",
	"EMV": "EMV Chip",
	"MAGSTRIPE": "Magstripe",
	"NFC_WALLET": "NFC Wallets",
	"VALET": "Valet",
	"PARKING": "Parking",
	"MINI": "Mini",
	"POS": "POS",
	"WIRELESS": "Wireless",
	"PINPAD": "Pinpad",
	"PAYMENT_SECTION3_ITEM1_TITLE": "Industry-leading security",
	"PAYMENT_SECTION3_ITEM1_SUBTITLE": "Every part of our platform is properly safeguarded.",
	"PAYMENT_SECTION3_ITEM1_LISTITEM1": "PCI Data Security Standard compliance",
	"PAYMENT_SECTION3_ITEM1_LISTITEM2": "Security updates and patches",
	"PAYMENT_SECTION3_ITEM1_LISTITEM3": "Strong cryptographic controls",
	"PAYMENT_SECTION3_ITEM2_TITLE": "It does not stop there",
	"PAYMENT_SECTION3_ITEM2_SUBTITLE": "WaoFlex devices offer a variety of features.",
	"PAYMENT_SECTION3_ITEM2_LISTITEM1": "Next-day funding",
	"PAYMENT_SECTION3_ITEM2_LISTITEM2": "Cash Discount Program",
	"PAYMENT_SECTION3_ITEM2_LISTITEM3": "Staff Management",
	"PAYMENT_SECTION3_ITEM2_LISTITEM4": "Financial Dashboards and Reporting",

	"PAYMENT_SECTION4_TITLE1": "Accept every major payment brand",
	"PAYMENT_SECTION4_TITLE2": "Certifications",
	"PAYMENT_SECTION5_TITLE": "Online Payments",
	"PAYMENT_SECTION5_SUBTITLE": "Allow your customers to connect and transact in new ways. Integrating online	payments with your business gives you the ability to process transactions based	on both your commerce and your customers needs.",
	"PAYMENT_SECTION5_ITEM1_TITLE": "Online Cashier",
	"PAYMENT_SECTION5_ITEM1_SUBTITLE": "Ease the POS transactions, simplify credit card payments and run multiple checkouts.",
	"PAYMENT_SECTION5_ITEM1_GRID_ITEM1": "Sign in to your WaoFlex account > Cashier",
	"PAYMENT_SECTION5_ITEM1_GRID_ITEM2": "Create customer's order - Select	the products from your catalogue.",
	"PAYMENT_SECTION5_ITEM1_GRID_ITEM3": "Send the order to your POS	device to be processed. You	can also save it like an Invoice	or take cash instead.",
	"PAYMENT_SECTION5_ITEM1_LIST_ITEM1": "Integrate with multiple checkout options. Save orders as Invoices or send them to your POS device.",
	"PAYMENT_SECTION5_ITEM1_LIST_ITEM2": "Accept cash as payment method and keep record of it.",
	"PAYMENT_SECTION5_ITEM1_LIST_ITEM3": "Customer management: record your customer data for customizing the way you serve them.",
	"PAYMENT_SECTION5_ITEM2_TITLE": "Invoices",
	"PAYMENT_SECTION5_ITEM2_SUBTITLE": "Easily create and send online invoices to your customers.",
	"PAYMENT_SECTION5_ITEM2_GRID_ITEM1": "Create your invoice - Enter a unit price or custom amount, or select items from your catalogue and set the quantity.",
	"PAYMENT_SECTION5_ITEM2_GRID_ITEM2": "Enter the customer's email or phone number.",
	"PAYMENT_SECTION5_ITEM2_GRID_ITEM3": "Hit Send. The customer receives the invoice. They can easily pay online with a debit or credit card through our payment gateway.",
	"PAYMENT_SECTION5_ITEM2_LIST_ITEM1": "Offer to pay invoices through the Payment Gateway (online) or at the POS terminal (in-person.)",
	"PAYMENT_SECTION5_ITEM2_LIST_ITEM2": "Provide clients with an estimate (quote.)",
	"PAYMENT_SECTION5_ITEM2_LIST_ITEM3": "Integrate with online Cashier - Create invoices out of Cashier orders.",

	"PAYMENT_SECTION5_ITEM3_TITLE": "Virtual Terminal",
	"PAYMENT_SECTION5_ITEM3_SUBTITLE": "Turn your computer into a credit card processing terminal.",
	"PAYMENT_SECTION5_ITEM3_GRID_ITEM1": "Sing in to your WaoFlex account > Virtual Terminal.",
	"PAYMENT_SECTION5_ITEM3_GRID_ITEM2": "Fill out with your customer's info.",
	"PAYMENT_SECTION5_ITEM3_GRID_ITEM3": "Get paid.",
	"PAYMENT_SECTION5_ITEM3_LIST_ITEM1": "Send receipts to customer's phone number.",
	"PAYMENT_SECTION5_ITEM3_LIST_ITEM2": "Set Custom Fields to your transactions.",
	"PAYMENT_SECTION5_ITEM3_LIST_ITEM3": "Electronic Checks processing.",

	"PAYMENT_SECTION6_TITLE": "WaoFlex payment API",
	"PAYMENT_SECTION6_SUBTITLE_LINE1": "Integrate secure online payments into your software or commercial applications.",
	"PAYMENT_SECTION6_SUBTITLE_LINE2": "Securely accept a wide-range of credit, debit, and alternative payments.",
	"PAYMENT_SECTION6_ITEM_TITLE": "Create a online payment experience for	your customers.",
	"PAYMENT_SECTION6_ITEM_PARAGRAPH1": "WaoFlex payment API makes it easy for youto take payments, manage refunds, and help customers check out online.",
	"PAYMENT_SECTION6_ITEM_PARAGRAPH2": "Build a secure and PCI-compliant payment gateway for your website; ensure end-to-end encryption to your customers.",

	'ABOUT_CERTIFICATIONS': 'Check our Validated Payment Application >',
	"PAYMENT_SECTION6_START_BUILDING": "Start building now >"


}