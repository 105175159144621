import * as Actions from "./customDialog.actions";

const initialState = {
  open: false,
  title: "",
  content: "",
  buttons: null,
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
  maxWidth: "sm",
  closeOnAcceptClick: true
};

function toaster(state = initialState, { type, payload }) {
  switch (type) {
    case Actions.OPEN_CUSTOM_DIALOG: {
      return {
        ...initialState,
        open: true,
        ...payload
      };
    }
    case Actions.CLOSE_CUSTOM_DIALOG: {
      return {
        ...state,
        open: false
      };
    }
    default: {
      return state;
    }
  }
};

export default toaster;
