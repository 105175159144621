export default {
  "SERVICES.TITLE": "Find all the right tools in one place",
  "SERVICES.SUBTITLE": `Have access to many more services you normally wouldn’t get from a payment processing solution. Customize your device to fit your business.`,
  "SERVICES.HEADINGBUTTONTEXT": "Contact our sale team",
  "SERVICES.SELECT_YOUR_BUSINESS_TYPE": "Start by selecting your business type",
  "SERVICES.RETAIL": "Retail",
  "SERVICES.TAILORED_MADE": "Tailored-made features to sell more",
  "SERVICES.FAQ": "FAQ",
  "SERVICES.HOW_DO_I_GET": "How do I get any of these services?",
  "SERVICES.TO_BENEFIT_FROM": `To benefit from any of the WaoFlex services you must apply to our onboarding program. A specialist will
    review your application and contact you right away with further steps.`,
  "SERVICES.SUBMIT_YOUR_APPLICATION": "Submit your merchant application >",
  "SERVICES.HOW_CAN_I_CONTACT": "How can I contact a WaoFlex sales specialist directly?",
  "SERVICES.STAFF_MANAGEMENT": "Staff Management",
  "SERVICES.YOU_MAY_ALSO": "You may also want to take a look at",
  "SERVICES.VALET_PARKING": "Valet Parking",
  "SERVICES.MORE_SERVICES": "More Services",
  "SERVICES.GET_IN_TOUCH": "Get in touch with our specialists and learn how WaoFlex can help your business.",


  "RETAIL.PRODUCT_CATALOG": "Product catalog",
  "RETAIL.BARCODE_READER": "Barcode reader",
  "RETAIL.INSTORE_PAYMENTS": "In-store payments (POS or Cash)",
  "RETAIL.ONLINE_PAYMENTS": "Online Payments",
  "RETAIL.RETAIL_SERVICES": "Retail Services",
  "RETAIL.TIPS": "Tips",

  "RETAIL.MINI_POS": "MiniPOS",
  "MINIPOS.MAIN_TEXT": `MiniPOS is an all-rounded, self-contained payment solution deployed in
    WaoFlex POS terminals. It combines both Point of sales and POS
    terminals capabilities in one compact device.`,
  "MINIPOS.POINT_LIST1": "Product catalog",
  "MINIPOS.POINT_LIST2": "Barcode reader",
  "MINIPOS.POINT_LIST3": "Payment processing",
  "MINIPOS.POINT_LIST4": "Cash, Debit and Credit acceptance",
  "MINIPOS.POINT_LIST5": "Invoice issuing",
  "MINIPOS.POINT_LIST6": "Receipt issuing",
  "MINIPOS.CHECK_LIST1": `Create a catalog of the products you sell, classify them into categories, and highlight the most wanted items of your store so you can issue invoices faster.`,
  "MINIPOS.CHECK_LIST2": `Carry out fast checkouts with your WaoFlex device. Scan the barcode of your products and
    sell them right away.`,
  "MINIPOS.CHECK_LIST3": `Let customers pay their way. Take cash or electronic payments (credit or debit cards): EMV
    chip, magstripe or NFC technologies.`,
  "MINIPOS.LEARN_ABOUT_PAYMENTS": "Learn more about payments >",
  "MINIPOS.NUMERIC_LIST1": `Load your product catalog to your web account. Create categories to classify
    your products. Select which products you want to have at hand (as
    Favorites). Download your catalog to your WaoFlex device.`,
  "MINIPOS.NUMERIC_LIST2": `Start selling. Use your WaoFlex device to scan the barcodes of your
    products and build invoices.`,
  "MINIPOS.NUMERIC_LIST3": `Get paid. Let your customers pay in person (swipe, insert, or tap debit or credit cards. You can also take cash instead) or online (send the invoices right to your customers inbox).`,

  "RETAIL.CASHIER": "Cashier",
  "CASHIER.MAIN_TEXT": `The WaoFlex Cashier is a web application for conducting
    basic cash register operations. Create customer orders out
    of your product catalog. Integrate with WaoFlex hardware
    to take any type of payment.`,
  "CASHIER.POINT_LIST1": "On-the-go cash register functionalities",
  "CASHIER.POINT_LIST2": "Product catalog",
  "CASHIER.POINT_LIST3": "Integration with POS terminals",
  "CASHIER.POINT_LIST4": "Customer directory",
  "CASHIER.POINT_LIST5": "Cash, Debit and Credit acceptance",
  "CASHIER.POINT_LIST6": "Invoice issuing",
  "CASHIER.CHECK_LIST1": `Take your business with you wherever you go. Run Cashier in any of
    your devices, on any browser.`,
  "CASHIER.CHECK_LIST2": `Use your product catalog to easily create orders. Group your
    products into categories for better organization and display.`,
  "CASHIER.CHECK_LIST3": `Integrate Cashier with your WaoFlex devices. Send orders directly
    to your POS terminals and allow your customers to pay with debit
    or credit cards.`,
  "CASHIER.CHECK_LIST4": `Generate invoices out of Cashier orders. You may save these orders as invoices and benefit from all Invoices features and payment methods.`,
  "CASHIER.CHECK_LIST5": `Save clients name and contact details to create a directory of
    recurrent customers.`,
  "CASHIER.NUMERIC_LIST1": `Log into your WaoFlex web account. Click on Cashier and let it unfold in your
    display.`,
  "CASHIER.NUMERIC_LIST2": `Connect your WaoFlex POS terminals to your Cashier with the
    WirelessPINPad application. It comes pre-loaded in your device.`,
  "CASHIER.NUMERIC_LIST3": `Start creating orders. Select a client from your directory (or create a new
    one). Select the items out of your product catalog and set the quantity.`,
  "CASHIER.NUMERIC_LIST4": `Proceed to checkout. Select how the customer chooses to pay. Hit the POS
  button to send the order right to your WaoFlex POS terminal, save the order
  as an Invoice, or take cash instead.`,

  "RETAIL.INVOICES": "Invoices",
  "INVOICES.MAIN_TEXT": `All-in-one solution for sending professionally designed
    invoices and estimates.`,
  "INVOICES.POINT_LIST1": "Product catalog",
  "INVOICES.POINT_LIST2": "Quotes issuing",
  "INVOICES.POINT_LIST3": "Invoice issuing",
  "INVOICES.POINT_LIST4": "Online Payment processing",
  "INVOICES.POINT_LIST5": "Integration with POS terminals",
  "INVOICES.POINT_LIST6": "Debit and Credit acceptance",
  "INVOICES.CHECK_LIST1": `Create professional invoices and estimates (quotes). Use your
    product catalog to build them, or add new items when creating
    them.`,
  "INVOICES.CHECK_LIST2": `Give customers more convenient and secure ways to pay. From online transactions (through our certified Payment Gateway) to in person payments (POS transactions and cash acceptance.)`,
  "INVOICES.CHECK_LIST3": `Send invoices right to your customer’s email or phone inbox.`,
  "INVOICES.CHECK_LIST4": `Save orders from the WaoFlex Cashier and convert them into
    invoices.`,
  "INVOICES.NUMERIC_LIST1": `Log into your WaoFlex web account. Create Invoices from the ground up or
    edit the ones that have been generated from other sources (like Cashier or
    MiniPOS.)`,
  "INVOICES.NUMERIC_LIST2": `Add or edit Invoice details. Set customer contact data (name and
    email/phone number) and products to buy (from your product catalog.) You
    can also add new items from scratch (name, unit price, and quantity.)`,
  "INVOICES.NUMERIC_LIST3": `Set your payment method. Customer's away? Send the invoice to their inbox.
    They will be able to pay through the WaoFlex Payment Gateway. You can
    also send invoices to your WaoFlex POS terminals, so customers can pay in
    person.`,

  "RETAIL.VIRTUAL_TERMINAL": "Virtual Terminal",
  "VIRTUAL_TERMINAL.MAIN_TEXT": `A web-based interface to accept credit card payments
    from virtually anywhere. Turn your computer into a credit
    card processing terminal and conduct payments right from
    a web browser.`,
  "VIRTUAL_TERMINAL.POINT_LIST1": "Online payment processing",
  "VIRTUAL_TERMINAL.POINT_LIST2": "Debit and Credit acceptance",
  "VIRTUAL_TERMINAL.POINT_LIST3": "Over-the-phone payments",
  "VIRTUAL_TERMINAL.POINT_LIST4": "Card-not-present transactions",
  "VIRTUAL_TERMINAL.POINT_LIST5": "ACH/Electronic check acceptance",
  "VIRTUAL_TERMINAL.POINT_LIST6": "Digital receipts",
  "VIRTUAL_TERMINAL.POINT_LIST7": "Custom fields in digital receipts",
  "VIRTUAL_TERMINAL.CHECK_LIST1": `Turn your computer, tablet, or smartphone into a
    payment-processing device.`,
  "VIRTUAL_TERMINAL.CHECK_LIST2": `Process transactions once you’ve collected customer information
    over the phone, web, by mail or even in person.`,
  "VIRTUAL_TERMINAL.CHECK_LIST3": `Given that you only need to input customer information online,
    customers and their cards don't need to be present for successful
    transactions to take place.`,
  "VIRTUAL_TERMINAL.CHECK_LIST4": `Virtual Terminal is powered by WaoFlex Payment Gateway, which
    is PCI compliant. Transactions are safeguarded with end-to-end
    encryption.`,
  "VIRTUAL_TERMINAL.CHECK_LIST5": `Electronic receipts are sent directly to customers after transactions
    are done.`,
  "VIRTUAL_TERMINAL.NUMERIC_LIST1": `Log into your WaoFlex web account. Access to your Virtual
    Terminal application.`,
  "VIRTUAL_TERMINAL.NUMERIC_LIST2": `Fill in your customer's information. Enter the amount to be charged,
    customer's payment details and custom transaction fields
    (optional). You can also set customer's contact details for them to
    receive their digital receipt.`,
  "VIRTUAL_TERMINAL.NUMERIC_LIST3": `Hit Pay and you're done. Get your money in your bank the next
    business day. Customers receive a copy of transaction receipts
    right to their inbox.`,

  "SERVICES.RESTAURANT": "Restaurant",
  "RESTAURANT.STREAM_LINE_PAYMENTS": "Streamline payments and tipping in your restaurant",
  "RESTAURANT.DIFFERENT_TIPPING": "Different tipping methods",
  "RESTAURANT.ALL_MAJOR_CHIP_CARDS": "Accepting all major chip cards and contactless payments",
  "RESTAURANT.AT_THE_TABLE_PAYMENTS": "At-the-table payments",
  "RESTAURANT.DIGITAL_PAYMENT_RECEIPTS": "Digital payment receipts",
  "RESTAURANT.RESTAURANT_SERVICES": "Restaurant Services",
  "RESTAURANT.TIPS": "Tips",

  "TIPS.MAIN_TEXT": `Provide your customers with multiple ways to tip your employees.`,
  "TIPS.POINT_LIST1": "Tip percentages",
  "TIPS.POINT_LIST2": "Preset tip amounts",
  "TIPS.POINT_LIST3": "Customizable tip amount",
  "TIPS.POINT_LIST4": "Tracking employees tip revenues",
  "TIPS.CHECK_LIST1": `Let customers enter the gratuity themselves. Studies have shown that 90% of clients have claimed they prefer this option`,
  "TIPS.CHECK_LIST2": `Front-facing tip displaying creates a simple transaction process for customers, which makes
    them feel more control.`,
  "TIPS.CHECK_LIST3": `Offer your customers different and easy ways to tip: from total amount percentages, preset
    gratuity options, to customizable amounts.`,
  "TIPS.CHECK_LIST4": `Provide employees with access to their tipping records. Allow them to track their income and
    have better control of their finances.`,

  "VALET_PARKING.REDEFINED": "Valet parkings, redefined.",
  "VALET_PARKING.ROBUST_POS_AND_WEB": `Robust POS and web application ready to back valets with their daily operations.
    Manage your parking while accepting payments with all-in-one application.`,
  "VALET_PARKING.FULLY_SIZED": `Fully-digitized system. End paperwork and hard labor. Take your entire parking 
    management to the cloud.`,
  "VALET_PARKING.CHECKIN_CHECKOUT": `Check-in and Check-out process. Track every time a car enters and leaves your
    establishment. Customize your check-in process with different available parameters.`,
  "VALET_PARKING.ADVANCED_VEHICLE": `Advanced vehicle requesting. Allow your clients to quickly request their vehicle on their
    way out.`,
  "VALET_PARKING.DAMAGE_CLAIM": `Damage claims reports. Take notes of every damage cars may present when checking in.`,
  "VALET_PARKING.PAYMENT_PROCESSING": `Payment processing. Accept all major chip cards and contactless payments, or take
    cash instead. Allow your customer to pay in-person (POS) or online (through the WaoFlex Payment Gateway)`,
  "VALET_PARKING.DIGITAL_PARKING_TICKETS": `Digital parking tickets. Go paperless. Send every parking ticket, PIN, and sale receipt
   digitally.`,
  "VALET_PARKING.STAFF_MANAGEMENT": `Staff management. Easily manage your staff. Track team hours, overtime, and breaks.
    Protect sensitive information by controling access to your point of sales and Dashboard.`,
  "VALET_PARKING.TIPPING": `Tipping. Front-facing tip displaying to streamline the tipping process. Allow drivers to
  enter the gratuity themselves. Offer them different and easy ways to tip: from total amount percentages, preset gratuity
    options, to customizable amounts.`,
  "VALET_PARKING.DASHBOARD_AND_REPORTING": `Dashboard and reporting. Oversee your services behavior at a glance. Check the data you
    need about every transaction.`,

  "STAFF_MANAGEMENT.KEEP_TRACK": `Keep track of all your employees records. Provide them with access to their own
    personal data records.`,
  "STAFF_MANAGEMENT.MANAGED_ACCESS": `Managed access. Protect your sensitive data. Only authorized personnel can access WaoFlex device features.`,
  "STAFF_MANAGEMENT.TIME_CLOCK_REPORT": `Time-clock report. Track your team’s time. Employees can clock in and out right at the
    WaoFlex device.`,
  "STAFF_MANAGEMENT.EMPLOYEES_ACCOUNTS": `Employees accounts. Provide your employees with web accounts so they can keep
    track of their own progress and manage their profile.`,
  "STAFF_MANAGEMENT.TRACK_EPLOYEES_TIP": `Track employees tip revenues. Both you and your employees can access tipping records.`
};
