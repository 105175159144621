import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import login from './Login/store/emailLogin/login.reducers';
import forgotPass from './Login/store/forgotPass/forgotPass.reducers';
import commonReducers from '../common/store/common.reducers';
import cloudInvoice from './CloudInvoices/store/cloudInvoices.reducers';
const reducer = combineReducers({
  form: reduxFormReducer,
  login,
  forgotPass,
  cloudInvoice,
  ...commonReducers,
});

const store = createStore(reducer, applyMiddleware(thunkMiddleware));

export default store;
