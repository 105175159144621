import Main from "../Landing/views/Main/Main";
import AboutUs from "../Landing/views/AboutUs/AboutUs";
import Dashboard from "../Landing/views/Dashboard/Dashboard";
import Services from "../Landing/views/Services/Services";
import Blank from "../Landing/views/Blank/Blank";
import Payments from "../Landing/views/Payments/Payments";
import ThankYouPage from "../Landing/views/ThankYouPage/ThankYouPage";

const routes = [
  {
    path: '/',
    component: Main
  },
  {
    path: '/about-us',
    component: AboutUs
  },
  {
    path: '/dashboard',
    component: Dashboard
  },
  {
    path: '/blank',
    component: Blank
  },
  {
    path: '/services',
    component: Services
  },
  {
    path: '/payments',
    component: Payments
  },
  {
    path: '/thank-you',
    component: ThankYouPage
  }
];

export default routes;