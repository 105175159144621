/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Grid, Hidden, Typography, Grow } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5-es';

import { Parallax } from '../Landing/components/index';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  submitLoginByEmail,
  submitLoginBySMS,
  getVCode,
  submitCheckTwoFA,
  LoginSMSProcess,
} from './store/emailLogin/login.actions';
import {
  forgotPassProcess,
  checkVCode,
  updatePass,
  setnewpass,
  FORGOTPASS_RESTORE,
  SET_SHOWN_FORM,
} from './store/forgotPass/forgotPass.actions';
import {
  EmailLoginForm,
  NewPassForm,
  ForgotPassForm,
  VCodeEntryForm,
  TwoFAForm,
  SelectMethodForm,
  PhoneLoginForm,
  SMSVCode,
} from './forms';

import { conatinerFluid, blueGradientColor, buttons } from '../../common/styles/default.styles';
import logo from '../Landing/components/TopNavBar/img/logo.png';
import store from '../store';
// import { recaptchaSecretKey } from '../../constants.js';
// import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';

// loadReCaptcha(recaptchaSecretKey);

const styles = {
  container: {
    zIndex: '12',
    color: '#FFFFFF',
    ...conatinerFluid,
  },
  card: {
    height: '80vh',
    borderRadius: '13px',
    background: 'white',
    boxShadow: '0px 0px 22px -1px rgba(92,92,92,1)',
    '@media (min-width: 400px) and (max-width: 599px)': {
      width: '365px',
    },
  },
  bgBlueGradient: {
    background: blueGradientColor,
  },
  bgGray: {
    background: '#FCFCFC',
  },
  logo:{
    width:'15rem'
  },
  ...buttons,
};

const useStyles = makeStyles(styles);

function Login({ newUser }) {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { forgotPass, userInfo, login } = useSelector(({ forgotPass, login, userInfo }) => ({
    forgotPass,
    login,
    userInfo,
  }));

  const submitHandlers = {
    emailLogin: handleEmailLogin,
    forgotPass: handleForgotPass,
    checkVCode: handleCheckVCode,
    newPass: handleSetNewPass,
    getVCode: handleGetVCode,
    CheckTwoFA: handleTwoFALogin,
    getSMSVCode: handleGetSMSVCode,
    loginBySMS: handleLoginBySMS,
  };

  function handleEmailLogin(credentials) {
    // updateRecaptchaToken();
    let { recaptchaToken } = login;
    dispatch(
      submitLoginByEmail({
        ...credentials,
        recaptchaToken,
        password: md5.hash(credentials.password),
      })
    );
  }

  function handleForgotPass(credentials) {
    dispatch(forgotPassProcess(credentials));
  }

  function handleGetVCode() {
    dispatch(getVCode({ email: userInfo.Email }, 'email'));
  }

  function handleGetSMSVCode(phone) {
    dispatch(LoginSMSProcess({ phone }));
  }

  function handleCheckVCode({ vcode }) {
    store.dispatch(checkVCode({ email: userInfo.Email, vCode: vcode }));
  }

  function handleSetNewPass({ password }) {
    if (newUser) {
      return dispatch(
        setnewpass({
          email: userInfo.Email,
          password: md5.hash(password),
          id: userInfo._id,
        })
      );
    }
    dispatch(
      updatePass({
        email: userInfo.Email,
        password: md5.hash(password),
      })
    );
  }

  function handleTwoFALogin({ token }) {
    dispatch(
      submitCheckTwoFA({
        userId: userInfo.userId,
        token: token.replace(/_| /g, ''),
      })
    );
  }

  function handleLoginBySMS(code) {
    dispatch(
      submitLoginBySMS({
        phone: userInfo.PrincipalPhone,
        password: code,
      })
    );
  }
  useEffect(() => {
    store.dispatch({ type: FORGOTPASS_RESTORE });
    if (newUser) {
      setShownForm('setNewPass');
    }
  }, []);

  useEffect(() => {
    const { checkVCodeSuccess, updatePassSuccess } = forgotPass;
    const { loginSucess } = login;

    if (loginSucess) {
      window.location = '/app/#/forms/dashboard';
    }

    // //El codigo introducido es correcto
    if (checkVCodeSuccess) {
      store.dispatch({ type: FORGOTPASS_RESTORE });
      setShownForm('setNewPass');
    }

    if (updatePassSuccess) {
      store.dispatch({ type: FORGOTPASS_RESTORE });
      history.push('/login');
    }
  }, [forgotPass, userInfo, history, newUser, login]);

  function setShownForm(shownForm) {
    store.dispatch({
      type: SET_SHOWN_FORM,
      payload: {
        shownForm,
      },
    });
  }

  // function setRecaptchaToken(response) {
  //   store.dispatch({
  //     type: SET_RECAPTCHA_TOKEN,
  //     payload: {
  //       recaptchaToken: response,
  //     },
  //   });
  // }

  // function updateRecaptchaToken() {
  //   // you will get a new token in verifyCallback
  //   recaptcha.execute();
  // }
  function form() {
    return (
      <Box className="flex flex-col justify-center text-center h-full">
        {forgotPass.shownForm === 'selectMethod' && (
          <div>
            <Box className="text-4xl">{translate('LOGIN.LOGIN')}</Box>
            <Box className="text-lg p-b-10 text-gray-500" px={{ md: '15%' }}>
              {translate('LOGIN.SELECT_METHOD')}
            </Box>
            <SelectMethodForm onSelect={(method) => setShownForm(method)} />
          </div>
        )}
        {forgotPass.shownForm === 'sms' && (
          <div>
            <Box className="text-4xl">{translate('LOGIN.LOGIN')}</Box>
            <Box className="text-lg p-b-10 text-gray-500" px={{ md: '15%' }}>
              {translate('LOGIN.ENTERPHONE')}
            </Box>
            <PhoneLoginForm onSubmit={submitHandlers.getSMSVCode} />
          </div>
        )}
        {forgotPass.shownForm === 'email' && (
          <div>
            <Box className="text-4xl">{translate('LOGIN.LOGIN')}</Box>
            <Box className="text-lg p-b-10 text-gray-500" px={{ md: '15%' }}>
              {translate('LOGIN.ENTERDATA')}
            </Box>
            <EmailLoginForm onSubmit={submitHandlers.emailLogin} handleForgotPass={() => setShownForm('forgotPass')} />
            <Box position="relative">
              {/* <ReCaptcha
                ref={(ref) => (recaptcha = ref)}
                sitekey={recaptchaSecretKey}
                action="login"
                verifyCallback={setRecaptchaToken}
              /> */}
            </Box>
          </div>
        )}
        {forgotPass.shownForm === '2FA' && (
          <div>
            <Box className="text-4xl fw-500 p-b-10">{translate('LOGIN.LOGIN')}</Box>
            <TwoFAForm onSubmit={submitHandlers.CheckTwoFA} handleBackToLogin={() => setShownForm('email')} />
          </div>
        )}
        {forgotPass.shownForm === 'forgotPass' && (
          <div>
            <Box className="text-4xl">{translate('LOGIN.LOGIN')}</Box>
            <Box className="text-lg text-gray-500 p-b-10" px={{ md: '15%' }}>
              {translate('LOGIN.RESET_YOUR_PASS')}
            </Box>

            <ForgotPassForm onSubmit={submitHandlers.forgotPass} handleBackToLogin={() => setShownForm('email')} />
          </div>
        )}
        {forgotPass.shownForm === 'setNewPass' && (
          <div>
            <Box className="text-4xl">{translate('LOGIN.LOGIN')}</Box>
            <Box className="text-lg text-gray-500 p-b-10" px={{ md: '15%' }}>
              {translate('LOGIN.SET_NEW_PASS')}
            </Box>
            <NewPassForm newUser onSubmit={submitHandlers.newPass} />
          </div>
        )}

        {forgotPass.shownForm === 'smsVCode' && (
          <div>
            <Box className="text-4xl">{translate('LOGIN.LOGIN')}</Box>
            <Box className="text-lg text-gray-500 p-b-10" px={{ md: '15%' }}>
              {translate('LOGIN.PLEASE_TYPE_VCODE', { phone: userInfo.PrincipalPhone })}
            </Box>
            <SMSVCode onSubmit={submitHandlers.loginBySMS} handleResendCode={submitHandlers.getSMSVCode} />
          </div>
        )}
        {forgotPass.shownForm === 'vcodeEntry' && (
          <div>
            <Box className="text-4xl">{translate('LOGIN.LOGIN')}</Box>
            <Box className="text-lg text-gray-500 p-b-10" px={{ md: '15%' }}>
              {translate('LOGIN.SET_NEW_PASS')}
            </Box>
            <VCodeEntryForm onSubmit={submitHandlers.checkVCode} handleResendCode={submitHandlers.getVCode} />
          </div>
        )}
      </Box>
    );
  }

  return (
    <div>
      <Box fontSize={{ xs: '75%', md: '100%' }} height="100vh" className="flex flex-col justify-center">
        <Parallax filter height="100vh" roundedBorderTopPosition={'45%'}>
          <Grow in timeout={700}>
            <Box className={classes.container}>
              <Box className={classes.card} width={{ sm: '450px', md: '800px', lg: '960px' }}>
                <Grid container direction="row" className="h-full">
                  <Grid item xs={12} md={6}>
                    <Box color="black" position="absolute" m={['6px 0 0 6px', '15px 0 0 15px']}>
                      <Link
                        to="/"
                        color="inherit"
                        onClick={() => {
                          dispatch({ type: SET_SHOWN_FORM, payload: { shownForm: 'email' } });
                        }}>
                        <ArrowBackIcon fontSize="large" className="text-gray-500" />
                      </Link>
                    </Box>
                    <Box color="black" px="7%" className="flex flex-col justify-center h-full">
                      {form()}
                      <Typography variant="body2" className="text-gray-700 m-b-4" align="center">
                        Copyright© {new Date().getFullYear()} WaoFlex
                      </Typography>
                    </Box>
                  </Grid>
                  <Hidden smDown>
                    <Grid item md={6}>
                      <Box
                        px={{ xs: '0', md: '4%' }}
                        borderRadius="0 13px 13px 0"
                        className={classes.bgGray + ' flex-col justify-center text-center h-full'}>
                        <Box className="flex flex-col items-center justify-center text-center h-full">
                          <Box className="text-lg fw-500 text-black m-b-5">
                            <Typography variant="subtitle1">{translate('LOGIN.WELCOMETO')}</Typography>
                          </Box>
                          <Box px="20%" width={{ lg: 'auto' }} className="flex flex-row justify-center">
                            <img src={logo} alt="flexipos" className={classes.logo} />
                          </Box>
                          <Box className="text-gray-500 " p={{ md: '10% 10% 0' }}>
                            {translate('LOGIN.PAYMENTPROCESSING')}
                          </Box>
                          <Box className="text-gray-500 fw-500 p-t-5 p-b-5" px={{ md: '10%' }}>
                            {translate('LOGIN.DOYOUWANT')}
                          </Box>
                          <a href="/app/#/ContactOnboarding" className="no-underline">
                            <Button className={classes.greenGradient + ' p-r-5 p-l-5'} variant="contained">
                              {translate('LOGIN.CONTACT')}
                            </Button>
                          </a>
                        </Box>
                      </Box>
                    </Grid>
                  </Hidden>
                </Grid>
              </Box>
            </Box>
          </Grow>
        </Parallax>
      </Box>
    </div>
  );
}

export default Login;
