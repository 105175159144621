import HTTP from '../services/HTTP.service';

let serverPromisesFactory = (function () {
  function updateOne(body) {
    return HTTP.post('/webapi/v3/promises/updateOne', body)
      .then(({ result }) => result)
      .catch(Promise.reject);
  }

  function updateMany(body) {
    return HTTP.post('/webapi/v3/promises/updateMany', body)
      .then(({ result }) => result)
      .catch(Promise.reject);
  }

  function get(body) {
    return HTTP.post('/webapi/v3/promises/get', body)
      .then(({ result }) => result)
      .catch(Promise.reject);
  }

  function getById(body) {
    return HTTP.post('/webapi/v3/promises/getById', body)
      .then(({ result }) => result)
      .catch(Promise.reject);
  }

  function create(body) {
    return HTTP.post('/webapi/v3/promises/create', body)
      .then(({ result }) => result)
      .catch(Promise.reject);
  }

  function getMultiple(body) {
    return HTTP.post('/webapi/v3/promises/getMultiple', body)
      .then(({ result }) => result)
      .catch(Promise.reject);
  }

  function runAggregates(body) {
    return HTTP.post('/webapi/v3/promises/runAggregates', body)
      .then(({ result }) => result)
      .catch(Promise.reject);
  }
  return {
    updateOne,
    updateMany,
    get,
    getById,
    create,
    getMultiple,
    runAggregates,
  };
})();

export default serverPromisesFactory;
