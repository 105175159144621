import React from 'react';
import { Container, Typography, Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import computer from './img/computer.svg';
import rocket from './img/rocket.svg';
import planet from './img/planet.svg';
import Button from '../../../../common/components/CustomButtons/Button';
import { makeStyles } from '@material-ui/core/styles';
const images = {
  computer,
  rocket,
  planet,
};

const styles = {
  link: {
    position: 'relative',
    padding: '0.8rem 1rem',
    fontWeight: '400',
    fontSize: '20px',
    textTransform: 'unset',
    lineHeight: '20px',
    textDecoration: 'none',
    margin: '1rem',
    display: 'inline-flex',
  },
};
function Heading({ title, subtitle, button, image }) {
  const { t: translate } = useTranslation();
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Box mt={{ xs: '9rem', xl: '17rem' }}>
        <Grid container direction="column" justify="space-between" alignItems="center">
          <Box maxWidth="800px" minWidth={{ xs: '250px', sm: '450px', md: '670px' }}>
            <Typography variant="h3" className="fw-300" gutterBottom align="center">
              {translate(title)}
            </Typography>
          </Box>
          <Box maxWidth="800px">
            {subtitle && (
              <Typography variant="body1" gutterBottom align="center" className="m-b-8 text-gray-500">
                {translate(subtitle)}
              </Typography>
            )}
          </Box>

          {button && (
            <a href={button.href} className="no-underline">
              <Button round color={'primary'} className={classes.link}>
                {button.text}
              </Button>
            </a>
          )}

          <Grid item className="w-full">
            <Box
              height={{ xs: '11rem', sm: '18rem', md: '19rem', xl: '28rem' }}
              className="flex flex-row justify-center">
              <img className="h-64 p-r-5 p-l-5" src={images[image]} alt={image} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Heading;
