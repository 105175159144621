import React from 'react';
import { Box, Button, Link, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm, change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { TextField } from '../../../common/components/ReduxForm/TextField';
import { buttons } from '../../../common/styles/default.styles';

const useStyles = makeStyles(buttons);

// const validate = (values) => {
//   const fields = {
//     vcode: {
//       value: values.vcode,
//       required: true,
//     },
//   };
//   return textFieldValidations({ fields });
// };

function SMSVCodeForm({ onSubmit, valid, handleResendCode }) {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const { userInfo, form } = useSelector(({ userInfo, form }) => ({
    userInfo,
    form,
  }));

  function onInput(e, index) {
    if (!e.target.value) {
      return;
    }
    let _value = e.target.value.toString();
    e.target.value = _value.slice(0, 1).toUpperCase();
    if (index < 6) {
      let nextVal = _value.slice(1, 2);
      document.getElementById(`digit${index + 1}`).value = nextVal;
      dispatch(change('smsvcodeForm', `digit${index + 1}`, nextVal));
    }
  }

  function _focus(e, index) {
    if (e.keyCode === 8 || e.keyCode === 46) document.getElementById(`digit${index > 0 ? index - 1 : index}`).focus();
    else if (e.target.value) document.getElementById(`digit${index < 6 ? index + 1 : index}`).focus();
  }

  function handleSubmit(e) {
    let _code = '';
    Array(7)
      .fill('')
      .forEach((e, index) => (_code = _code.concat(form.smsvcodeForm.values[`digit${index}`])));
    onSubmit(_code);
  }

  return (
    <form>
      <Box display='flex' flexDirection='row'>
        {Array(7)
          .fill('')
          .map((e, index) => (
            <Field
              key={index}
              onKeyUp={(e) => _focus(e, index)}
              id={`digit${index}`}
              name={`digit${index}`}
              component={TextField}
              className='m-r-1'
              variant='outlined'
              inputProps={{ autoFocus: index === 0 }}
              onInput={(e) => onInput(e, index)}
            />
          ))}
      </Box>
      <Box className='flex flex-row justify-between items-center m-t-5'>
        <Link
          className='text-sm fw-400 cursor-pointer text-left'
          onClick={() => handleResendCode(userInfo.PrincipalPhone)}
        >
          {translate('LOGIN.RESEND_CODE')}
        </Link>
        <Button onClick={handleSubmit} variant='contained' className={classes.primary + ' fw-400'}>
          <Box minWidth='100px'>{translate('LOGIN.LOG_IN')}</Box>
        </Button>
      </Box>
    </form>
  );
}

export default reduxForm({ form: 'smsvcodeForm' })(SMSVCodeForm);
