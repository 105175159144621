import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Container, Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import d200T from './img/D200.png';
import D220 from './img/D220.png';
// import Q80 from './img/Q80.png';
import S920 from './img/S920.png';
import greenFrame from './img/greenFrame.svg';
import blueFrame from './img/blueFrame.svg';
// import violetFrame from './img/violetFrame.svg';
import yellowFrame from './img/yellowFrame.svg';

import { customDialogActions } from '../../../../../../common/store/common.actions';

import DeviceOverview from '../../../../modals/DeviceOverview';

function Devices() {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const items = [
    { src: d200T, frame: greenFrame, alt: 'D200T', model: 'D200' },
    { src: D220, frame: blueFrame, alt: 'D220', model: 'D220' },
    // { src: Q80, frame: violetFrame, alt: 'Q80', model: 'Q80' },
    { src: S920, frame: yellowFrame, alt: 'S920', model: 'S920' },
  ];

  function deviceOveriew(model) {
    dispatch({
      type: customDialogActions.OPEN_CUSTOM_DIALOG,
      payload: {
        content: <DeviceOverview model={model} />,
      },
    });
  }

  return (
    <Box component="div" bgcolor="#f9f9f9" padding={{ xs: '3rem 0', sm: '3rem 1rem', md: '8rem', lg: '5rem 7rem' }}>
      <Container maxWidth="lg">
        <Grid container direction="column" justify="center" alignItems="stretch">
          <Grid item>
            <Box marginY="3rem">
              <Typography variant="h3" align="center" gutterBottom className="fw-300">
                {translate('PAYMENT_PROCESSING_TITTLE')}
              </Typography>
              <Typography variant="body1" align="center" className="text-gray-500">
                {translate('PAYMENT_PROCESSING_SUBTITTLE')}
              </Typography>
            </Box>
          </Grid>
          {items.map(({ src, frame, alt, model }, key) => (
            <Grid
              key={key}
              className="p-t-10 m-b-10 "
              item
              container
              direction={key % 2 ? 'row-reverse' : 'row'}
              justify={'flex-start'}
              alignItems="flex-start">
              <Grid item xs={12} sm={4} className={key % 2 ? 'flex' : 'flex justify-end'}>
                <img src={src} alt={alt} width="70%" />
              </Grid>
              <Grid item xs={12} sm className="m-t-10">
                <Box
                  textAlign={{ xs: 'center', sm: key % 2 ? 'right' : 'left' }}
                  className={`${key % 2 ? 'm-r-40' : 'm-l-40'}`}>
                  <Typography variant="h4">{alt}</Typography>
                  <Typography variant="body1" className="p-t-1">
                    {translate(`${alt}_DESCRIPTION`)}
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent={{
                      xs: 'center',
                      sm: key % 2 ? 'flex-end' : 'flex-start',
                    }}>
                    {/* <Typography variant="body1" className="m-r-5 p-t-2 ">
                      <a
                        target="_blank"
                        className="no-underline fw-500"
                        rel="noopener noreferrer"
                        href={`https://vivoflex.us/documents/DataSheet/${model}flexipos.pdf`}>
                        {translate('SPECIFICATION')}
                      </a>
                    </Typography> */}
                    <Typography variant="body1" align="inherit" className="p-t-2">
                      <Link to="" onClick={() => deviceOveriew(model)} className="no-underline fw-500">
                        {translate('3D_OVERVIEW')}
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Devices;
