import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Container } from "@material-ui/core";
import classNames from "classnames";

import TopNavBar from "../../components/TopNavBar/TopNavBar";
import TopNavBarLinks from "../../components/TopNavBar/TopNavBarLinks";
import { Parallax, Heading } from "../../components/index";
import { container } from "../../../../common/styles/default.styles";

const styles = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  }
};

const dashboardRoutes = [];
const theme = "light";
const useStyles = makeStyles(styles);

export default function Main(props) {
  const classes = useStyles();
  const leftList = [
    {
      label: "SERVICES",
      type: "sectionLink",
      section: "seccion1"
    },
    {
      label: "DEVICES",
      type: "sectionLink",
      section: "seccion2"
    },
    {
      label: "INTEGRATIONS",
      type: "sectionLink",
      section: "seccion3"
    },
    {
      label: "CONTACT",
      type: "sectionLink",
      section: "seccion4"
    }
  ];
  return (
    <Box fontSize={{ xs: "75%", md: "100%" }}>
      <TopNavBar
        color="transparentDark"
        theme={theme}
        routes={dashboardRoutes}
        leftLinks={<TopNavBarLinks theme={theme} list={leftList} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white"
        }}
      ></TopNavBar>
      <Parallax filter title>
        <div className={classes.container}>
          <Heading
            title="EL Main"
            subtitle="el subtitulo"
            image="computer"
          ></Heading>
        </div>
      </Parallax>
      <div className={classNames(classes.main)}>
        {/* Seccion 3 */}
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            alignContent="center"
            justify="center"
            className={"text-black"}
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              className="flex flex-col items-center justify-center p-5"
              style={{ border: "2px solid blue" }}
            >
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent={{ xs: "center", sm: "space-around" }}
                alignItems={{ xs: "center" }}
              >
                <Box m={{ xs: "5px" }} border="solid 1px red">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consequatur et aut, labore ex quas in
                </Box>
                <Box m={{ xs: "5px" }} border="solid 1px red">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consequatur et aut, labore ex quas in
                </Box>
                <Box m={{ xs: "5px" }} border="solid 1px red">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consequatur et aut, labore ex quas in
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              className="flex flex-col items-center justify-center p-5"
              style={{ border: "2px solid blue" }}
            >
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent={{ xs: "center", sm: "space-around" }}
                alignItems={{ xs: "center" }}
              >
                <Box m={{ xs: "5px" }} border="solid 1px red">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consequatur et aut, labore ex quas in{" "}
                </Box>
                <Box m={{ xs: "5px" }} border="solid 1px red">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consequatur et aut, labore ex quas in{" "}
                </Box>
                <Box m={{ xs: "5px" }} border="solid 1px red">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consequatur et aut, labore ex quas in{" "}
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              className="flex flex-col items-center justify-center p-5"
              style={{ border: "2px solid blue" }}
            >
              <Box
                display="flex"
                flexDirection={{ xs: "column" }}
                justifyContent={{ xs: "center", sm: "space-around" }}
                alignItems={{ xs: "center" }}
              >
                <Box m={{ xs: "5px" }} border="solid 1px red">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consequatur et aut, labore ex quas in
                </Box>
                <Box m={{ xs: "5px" }} border="solid 1px red">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consequatur et aut, labore ex quas in
                </Box>
                <Box m={{ xs: "5px" }} border="solid 1px red">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consequatur et aut, labore ex quas in
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Box>
  );
}
