import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { common } from './common/translations/common';
import { aboutUs } from './common/translations/landing/aboutUs';
import { dashboard } from './common/translations/landing/dashboard';
import { components } from './common/translations/landing/components';
import { login } from './common/translations/login';
import { services } from './common/translations/landing/services';
import { PADSSValidation } from './common/translations/landing/PADSSValidation';
import { payments } from './common/translations/landing/payments';
import { main } from './common/translations/landing/main';
import { cloudInvoices } from './common/translations/cloudInvoices';

const english = {
  ...common.english,
  ...aboutUs.english,
  ...components.english,
  ...dashboard.english,
  ...PADSSValidation.english,
  ...payments.english,
  ...main.english,
  ...login.english,
  ...services.english,
  ...cloudInvoices.english
};


i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    resources: {
      en: {
        translation: english
      }
    },
    fallbackLng: 'en',
    debug: false,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  })

export default i18n