import Login from "./Login";
import ConfirmUser from "./ConfirmUser";

const routes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/confirmuser/:id',
    component: ConfirmUser
  }
];

export default routes;