import React from 'react';
import { Button, Dialog, Box, Grid, Hidden, colors } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import * as alertDialogActions from './alertDialog.actions';
import store from '../../../modules/store';

import { withStyles } from '@material-ui/core/styles';
import info from './img/info_icon.svg';
import error from './img/error_icon.svg';
import success from './img/success_icon.svg';
import warning from './img/warning_icon.svg';
import { dangerColor, successColor, warningColor, infoColor } from '../../styles/default.styles';

const images = { info, error, success, warning };
const dialogColors = {
  info: infoColor,
  error: dangerColor,
  success: successColor,
  warning: warningColor,
};

const styles = {
  buttonAccept: {
    margin: '0 10px',
    background: '#39b54a',
    '&:hover': {
      background: '#339c42',
    },
  },
  buttonCancel: {
    margin: '0 10px',
    background: '#ed5565',
    '&:hover': {
      background: '#d24d5b',
    },
  },
  dialog: {
    '@media (min-width: 320px) and (max-width: 450px)': {
      width: '256px',
    },
    '@media (min-width: 451px) and (max-width: 599px)': {
      width: '385px',
    },
  },
};
const useStyles = makeStyles(styles);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomDialog() {
  const classes = useStyles();
  const alertDialog = useSelector(({ alertDialog }) => alertDialog);
  const { buttons } = alertDialog;

  function handleClose(cb, reason) {
    console.log(reason);
    store.dispatch({ type: alertDialogActions.CLOSE_ALERT_DIALOG });
    cb && cb();
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog'
        open={alertDialog.open}
        maxWidth='md'
        disableEscapeKeyDown
      >
        <Box
          className={classes.dialog}
          color={colors.grey[700]}
          borderTop={`solid 7px ${dialogColors[alertDialog.type]}`}
        >
          <DialogContent>
            <Box width={{ sm: '510px' }} mb='30px'>
              <Grid container>
                <Hidden xsDown>
                  <Grid item sm={3}>
                    <Box height='130px' className='flex flex-row justify-center'>
                      <img className='h-full' src={images[alertDialog.type]} alt={alertDialog.type} />
                    </Box>
                  </Grid>
                </Hidden>
                <Grid item xs={12} sm={9}>
                  <Box
                    className='fw-400 leading-8'
                    pt={{ sm: '18px' }}
                    maxWidth='380px'
                    fontSize={{ xs: '1.4rem', sm: '1.7rem !important' }}
                  >
                    {alertDialog.title}
                  </Box>
                  <Box className='fw-300 p-t-3' fontSize={['1.1rem', '1.275rem']}>
                    {alertDialog.text}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box color='white' pb='10px'>
              {buttons.cancel ? (
                <Button
                  onClick={() => handleClose(buttons.cancel.onClick)}
                  className={classes.buttonCancel}
                  color='inherit'
                  variant='contained'
                >
                  {buttons.cancel.text}
                </Button>
              ) : (
                ''
              )}
              {buttons.accept ? (
                <Button
                  onClick={() => handleClose(buttons.accept.onClick)}
                  className={classes.buttonAccept}
                  color='inherit'
                  variant='contained'
                >
                  {buttons.accept.text}
                </Button>
              ) : (
                ''
              )}
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

function openAlertDialog(options) {
  store.dispatch({
    type: alertDialogActions.OPEN_ALERT_DIALOG,
    payload: {
      ...options,
    },
  });
}

export { CustomDialog as AlertDialog };
export { openAlertDialog };
