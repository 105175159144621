import React from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { Tabs, Tab, Box, Typography } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#3CD52E',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    '&:hover': {
      color: '#3CD52E',
      opacity: 1,
    },
    '&$selected': {
      color: '#3CD52E',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#3CD52E',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      className="fw-300"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function CustomizedTabs(props) {
  const { tabs, id } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className="m-b-8" id={id}>
      <div className={classes.tabsContainer}>
        <AntTabs value={value} onChange={handleChange} aria-label="custom-tabs" variant="scrollable" scrollButtons="on">
          {tabs.map((tab, i) => (
            <AntTab
              id={tab.label}
              aria-controls={`scrollable-auto-tabpanel-${i}`}
              label={tab.label}
              icon={tab.icon}
              key={i}></AntTab>
          ))}
        </AntTabs>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}>
          {tabs.map((tab, i) => (
            <TabPanel value={value} index={i} dir={theme.direction} key={i}>
              {tab.content}
            </TabPanel>
          ))}
        </SwipeableViews>
      </div>
    </div>
  );
}

export default CustomizedTabs;
