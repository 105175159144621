import { useState, useEffect } from 'react';

export default function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [breakpoints, setBreakpoints] = useState({
    xs: false,
    xsDown: false,
    xsUp: false,
    sm: false,
    smDown: false,
    smUp: false,
    md: false,
    mdDown: false,
    mdUp: false,
    lg: false,
    lgDown: false,
    lgUp: false,
    xl: false,
    xlDown: false,
    xlUp: false,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setBreakpoints({
        xs: window.innerWidth < 600,
        xsUp: window.innerWidth >= 600,
        sm: window.innerWidth >= 600 && window.innerWidth < 960,
        smDown: window.innerWidth < 960,
        smUp: window.innerWidth >= 600,
        md: window.innerWidth >= 960 && window.innerWidth < 1280,
        mdDown: window.innerWidth < 1280,
        mdUp: window.innerWidth >= 960,
        lg: window.innerWidth >= 1280 && window.innerWidth < 1920,
        lgDown: window.innerWidth < 1980,
        lgUp: window.innerWidth >= 1280,
        xl: window.innerWidth >= 1920,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return { ...windowSize, ...breakpoints };
}
