/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../common/store/common.actions';
import Login from './Login';

function ConfirmUser({ match }) {
  const dispatch = useDispatch();
  const userInfo = useSelector(({ userInfo }) =>  userInfo);
  console.log(1111111);
  useEffect(() => {
    if (!Object.keys(userInfo).length) {
      dispatch(userActions.getUser({ query: { _id: match.params.id } }));
    }
  }, [userInfo]);
  return Object.keys(userInfo).length && <Login newUser={userInfo.Status.Code === 0} />;
}

export default ConfirmUser;
