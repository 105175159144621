import React from 'react';
import { TextField, Box } from '@material-ui/core';

function newPassworValidations(password) {
  if (!password.match(/[a-z]/)) {
    return 'Must contain at least 1 lowercase character';
  }

  if (!password.match(/[A-Z]/)) {
    return 'Must contain at least 1 uppercase character';
  }

  if (!password.match(/[0-9]/)) {
    return 'Must contain at least 1 numeric character';
  }

  if (!password.match(/[!@#$%^&*-.]/)) {
    return 'Must contain at least one special character';
  }
}

function textFieldValidations({ fields, removeFormat }) {
  const errors = {};
  Object.keys(fields).forEach((key) => {
    let { value, required, isEmail, minLength, isNumber, isNewPassword, equalTo, isCardNumber, isExpiryDate } = fields[
      key
    ];

    if (removeFormat && value) {
      value = value.replace(/-| /g, '');
    }

    if (required && !value) {
      return (errors[key] = 'Required');
    }

    if (isEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return (errors[key] = 'Invalid email address');
    }

    if (isNumber && isNaN(value)) {
      return (errors[key] = 'Only numbers');
    }

    if (isNewPassword && newPassworValidations(value)) {
      return (errors[key] = newPassworValidations(value));
    }

    if (equalTo && value !== fields[equalTo.field].value) {
      return (errors[key] = equalTo.error);
    }

    // console.log(value, value.length, Number(minLength || 0));
    if (value.length < Number(minLength || 0)) {
      return (errors[key] = `Must be at least ${minLength} ${isNumber ? ' digits' : ' characters'}`);
    }

    if (isCardNumber && value.length < 12) {
      return (errors[key] = `Must be at least 12 digits`);
    }

    if (isExpiryDate) {
      if (!/^(0[1-9]|1[012])[/]\d{2}$/.test(value)) {
        return (errors[key] = `Invalid expiry date`);
      }

      const [month, year] = value.split('/');
      const actualMonth = new Date().getMonth() + 1;
      const actualYear = new Date().getFullYear() - 2000;

      if ((Number(month) < actualMonth && Number(year) === actualYear) || Number(year) < actualYear) {
        return (errors[key] = `Card is expired`);
      }
    }

    if (isExpiryDate && !/^(0[1-9]|1[012])[/]\d{2}$/.test(value)) {
      return (errors[key] = `Invalid expiry date`);
    }
  });

  return errors;
}

function _TextField(props) {
  const {
    input,
    meta: { touched, error, active },
  } = props;
  const helperText = (
    <Box className="text-xs fw-300" height="14px" minWidth="10px">
      {touched && !active && error}
    </Box>
  );

  return (
    <div className="flex flex-col">
      <TextField
        {...props}
        {...input}
        helperText={helperText}
        FormHelperTextProps={{ component: 'div', error: true }}
      />
    </div>
  );
}

export { _TextField as TextField };
export { textFieldValidations };
